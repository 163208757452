import React from 'react';
import CountryDetailsPanel from '../../Sections/ShipAnalytics/DashboardActivityMapSection/DashboardActivityMapCountryComponents/CountryDetailsPanel';
import OperatorDetailsPanel from '../../Sections/ShipAnalytics/DashboardActivityMapSection/DashboardActivityMapOperatorComponents/OperatorDetailsPanel';
import OwnerDetailsPanel from '../../Sections/ShipAnalytics/DashboardActivityMapSection/DashboardActivityMapOwnerComponents/OwnerDetailsPanel';
import PortDetailsPanel from '../../Sections/ShipAnalytics/DashboardActivityMapSection/DashboardActivityMapPortComponents/PortDetailsPanel';
import {
  SIDE_PANEL_STATES,
  SIDE_PANEL_TYPES,
  ACTIVITY_MAP_SEARCH_PARAM_KEYS
} from '../../Sections/ShipAnalytics/DashboardActivityMapSection/constants';
import DashboardActivityMapVesselDetailsWindow from '../../Sections/ShipAnalytics/DashboardActivityMapSection/DashboardActivityMapVesselComponents/DashboardActivityMapVesselDetailsWindow/DashboardActivityMapVesselDetailsWindow';
import DashboardActivityMapInsight from './DashboardActivityMapActionWindow/DashboardActivityMapInsight';
import { useSearchParams } from 'react-router-dom';
import {
  useDynamicLookupQueries,
  useVesselsLatestPositionByTypes,
  useTopPorts,
  useGlobalPortStats,
  useGlobalMonthlyPercentActivityStatus,
  useGlobalEmissionIntensity,
  useGlobalPortCongestion,
  useStaticLookupQueries
} from '@hooks';
import PropTypes from 'prop-types';
import DashboardActivityMapGlobalInsights from '../../Sections/ShipAnalytics/DashboardActivityMapSection/DashboardActivityMapGlobalComponents/DashboardActivityMapGlobalInsights';
import DashboardActivityMapInboundVesselInsight from '../../Sections/ShipAnalytics/DashboardActivityMapSection/DashboardActivityMapInboundVesselComponents/DashboardActivityMapInboundVesselInsight';

const DashboardActivityMapSidePanel = ({ activeFilters, filteredVessels, filteredPorts }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sidePanelState = searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE);
  const sidePanelType = searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE);
  const sidePanelItemId = searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID);

  const {
    lookupLocationsQuery: { data: lookupLocations }
  } = useStaticLookupQueries();

  const { data: top10Ports = [], isFetching: isLoadingTop10Ports } = useTopPorts();

  const {
    lookupPortsQuery: { data: lookupPorts, isFetching: isLoadingLookupPorts },
    lookupOperatorVesselHierarchyQuery: { data: operatorVesselHierarchy },
    lookupOwnerOperatorVesselHierarchyQuery: { data: ownerOperatorVesselHierarchy }
  } = useDynamicLookupQueries();

  const {
    data: globalMonthlyPercentActivityStatus,
    isPending: isLoadingGlobalMonthlyPercentActivityStatus
  } = useGlobalMonthlyPercentActivityStatus();

  const { data: globalPortCongestion, isPending: isLoadingGlobalPortCongestion } =
    useGlobalPortCongestion();

  const { data: globalEmissionIntensity, isPending: isLoadingGlobalEmissionIntensity } =
    useGlobalEmissionIntensity();

  const { data: globalPortStats, isPending: isLoadingGlobalPortStats } = useGlobalPortStats();

  const { data: vesselsLatestPosition } = useVesselsLatestPositionByTypes();

  const handleSidePanelClose = () => {
    setSearchParams((prev) => {
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE);
      return prev;
    });
  };

  const hasInboundVesselsLocations = activeFilters?.selectedLocations?.some(Boolean);

  const renderInsightContent = () => {
    if (activeFilters && hasInboundVesselsLocations) {
      return (
        <DashboardActivityMapInboundVesselInsight
          lookupPorts={filteredPorts}
          locations={activeFilters.selectedLocations}
          vesselsLatestPosition={filteredVessels}
        />
      );
    }

    return (
      <DashboardActivityMapGlobalInsights
        top10Ports={top10Ports}
        lookupPorts={lookupPorts}
        globalPortStats={globalPortStats}
        globalMonthlyPercentActivityStatus={globalMonthlyPercentActivityStatus}
        globalPortCongestion={globalPortCongestion}
        globalEmissionIntensity={globalEmissionIntensity}
      />
    );
  };

  if (sidePanelState !== SIDE_PANEL_STATES.OPEN) return null;

  if (sidePanelType === SIDE_PANEL_TYPES.INSIGHT) {
    return (
      <DashboardActivityMapInsight
        renderInsightContent={renderInsightContent}
        isLoading={
          isLoadingTop10Ports ||
          isLoadingLookupPorts ||
          isLoadingGlobalPortStats ||
          isLoadingGlobalPortCongestion ||
          isLoadingGlobalMonthlyPercentActivityStatus ||
          isLoadingGlobalPortCongestion ||
          isLoadingGlobalEmissionIntensity
        }
        onClose={() => {
          setSearchParams((prev) => {
            prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE, SIDE_PANEL_STATES.CLOSED);
            prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE);
            return prev;
          });
        }}
        title={activeFilters ? 'Insight' : 'Global Insight'}
      />
    );
  }

  if (sidePanelType === SIDE_PANEL_TYPES.VESSEL_DETAILS && sidePanelItemId) {
    return (
      <DashboardActivityMapVesselDetailsWindow
        vesselImo={sidePanelItemId}
        vesselsLatestPosition={vesselsLatestPosition}
        onClose={handleSidePanelClose}
      />
    );
  }

  if (sidePanelType === SIDE_PANEL_TYPES.OPERATOR_DETAILS && sidePanelItemId) {
    return (
      <OperatorDetailsPanel
        operatorId={Number(sidePanelItemId)}
        operatorVesselHierarchy={operatorVesselHierarchy}
        handleClose={handleSidePanelClose}
      />
    );
  }

  if (sidePanelType === SIDE_PANEL_TYPES.OWNER_DETAILS && sidePanelItemId) {
    return (
      <OwnerDetailsPanel
        ownerId={Number(sidePanelItemId)}
        ownerOperatorVesselHierarchy={ownerOperatorVesselHierarchy}
        handleClose={handleSidePanelClose}
      />
    );
  }

  if (sidePanelType === SIDE_PANEL_TYPES.PORT_DETAILS && sidePanelItemId) {
    return (
      <PortDetailsPanel
        portData={lookupPorts.find((port) => port.id === +sidePanelItemId)}
        onClose={handleSidePanelClose}
      />
    );
  }

  if (sidePanelType === SIDE_PANEL_TYPES.COUNTRY_DETAILS && sidePanelItemId) {
    return (
      <CountryDetailsPanel
        country={lookupLocations.find(
          (location) => location.type === 'Country' && location.id === +sidePanelItemId
        )}
        onClose={handleSidePanelClose}
      />
    );
  }

  return null;
};

DashboardActivityMapSidePanel.propTypes = {
  activeFilters: PropTypes.object,
  filteredVessels: PropTypes.array,
  filteredPorts: PropTypes.array
};

export default DashboardActivityMapSidePanel;
