import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import PropTypes from 'prop-types';
import mapboxgl, { Map, NavigationControl } from 'mapbox-gl';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN } from '@constants';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import VesselPositionDetails from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselOverviewSection/VesselPositionDetails/VesselPositionDetails';
import { useMapFullscreen } from '@hooks/useMapFullscreen';
import MapFullscreenButton, {
  mapFullscreenButtonParentStyle
} from '@components/Common/MapFullscreenButton';
import { VesselIcon } from '@components';
import { getVesselColor } from '@helpers';
import { Grid } from '@esgian/esgianui';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

const createMarker = (map, lat, long, heading) => {
  const el = document.createElement('div');
  const markerSpan = document.createElement('span');
  const vesselColor = getVesselColor(10, true); // Use appropriate logic for the speed
  const root = ReactDOM.createRoot(markerSpan);

  // Render VesselIcon
  root.render(<VesselIcon color={vesselColor} size={15} />);

  // Apply styles to markerSpan
  markerSpan.style.cssText = `
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    transform: rotateZ(${heading}deg);
  `;

  // Append markerSpan to the marker element
  el.className = 'ship-marker';
  el.appendChild(markerSpan);

  // Add event listeners for interactions
  el.addEventListener('click', () => {
    map.flyTo({ center: [long, lat], zoom: 2, duration: 2000 });
  });

  // Add the marker to the map
  new mapboxgl.Marker(el).setLngLat([long, lat]).addTo(map);
  map.flyTo({ center: [long, lat], zoom: 2, duration: 2000 });
};

function VesselMap({ vesselLatestPositionData }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const themeMode = useSelector(getThemeMode);
  const { isFullscreen, toggleFullscreen } = useMapFullscreen();
  let heading = vesselLatestPositionData?.heading || 0;
  useEffect(() => {
    map.current = new Map({
      container: mapContainer.current,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [0, 0],
      height: '100%',
      zoom: 0
    });
    map.current.addControl(new NavigationControl());
  }, [isFullscreen]);

  useEffect(() => {
    if (!map.current || !vesselLatestPositionData) return;
    map.current.setStyle(themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE);
    map.current.once('styledata', () => {
      const { lat, long } = vesselLatestPositionData;
      createMarker(map.current, lat, long, heading);
      map.current.flyTo({ center: [long, lat], zoom: 2, duration: 2000 });
    });
  }, [themeMode, vesselLatestPositionData, isFullscreen]);

  useEffect(() => {
    if (!vesselLatestPositionData) return;

    const { lat, long } = vesselLatestPositionData;
    createMarker(map.current, lat, long, heading);
  }, [vesselLatestPositionData, themeMode, isFullscreen]);

  const heightStyle = isFullscreen
    ? {
        height: '100vh',
        width: '100vw'
      }
    : {
        height: '100%',
        width: '100%'
      };

  return (
    <div
      ref={mapContainer}
      style={{
        flex: 1,
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px',
        ...heightStyle
      }}
      className="vessel-profile-map">
      {
        <div
          id="vessel-map-legend"
          className="legend-vessel-map"
          style={{
            background: ({ palette }) =>
              palette.mode === 'light' ? palette.primary.main : palette.primary.dark,
            position: 'absolute',
            left: '10px',
            top: '10px',
            bottom: '10px',
            width: '20vw',
            height: '10vh'
          }}>
          <div>
            <Grid item xs={12}>
              <VesselPositionDetails vesselLatestPositionData={vesselLatestPositionData} />
            </Grid>
          </div>
        </div>
      }
      <div style={mapFullscreenButtonParentStyle}>
        <MapFullscreenButton
          onClick={() => toggleFullscreen(mapContainer.current)}
          isFullScreen={isFullscreen}
        />
      </div>
    </div>
  );
}

VesselMap.propTypes = {
  vesselLatestPositionData: PropTypes.object,
  loading: PropTypes.bool
};

VesselMap.defaultProps = {
  vesselLatestPositionData: null,
  loading: false
};

export default VesselMap;
