import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@esgian/esgianui';
import { useTimezone } from '@hooks/useTimezone';
import { useTheme } from '@hooks/useTheme';
import { ProfileLink } from '@components/Links';
import { formatToDecimalPlaces } from '@helpers';

const InboundVesselInsightVesselTab = ({ vesselsLatestPosition, ports }) => {
  const { customScrollbar } = useTheme();
  const { getTimeZoneDisplay } = useTimezone();

  const groupedByPort = ports.reduce((grouped, singlePort) => {
    const vesselsForPort = vesselsLatestPosition.filter(
      (vessel) => vessel.destinationPortId === singlePort.id
    );

    grouped[singlePort.portName] = vesselsForPort;

    return grouped;
  }, {});

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      {Object.entries(groupedByPort).map(([portName, vessels]) => (
        <Box key={portName} display="flex" flexDirection="column" gap={1.5} sx={{ mb: 2 }}>
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '0.95rem' }}>
              Upcoming vessels to {portName} ({vessels[0]?.destinationCountryName})
            </Typography>
            <Typography variant="caption" sx={{ fontSize: '0.8rem' }}>
              {vessels?.length || 0} vessels
            </Typography>
          </Box>
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden',
              overflowX: 'auto',
              ...customScrollbar
            }}>
            <Box
              sx={{
                minWidth: 'fit-content',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: (theme) => theme.palette.background.paper,
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`
              }}>
              <Box
                display="flex"
                gap={1}
                sx={{
                  p: 0.75,
                  borderRadius: 1
                }}>
                <Typography sx={{ minWidth: '150px', fontWeight: 'bold', fontSize: '0.85rem' }}>
                  Operator
                </Typography>
                <Typography sx={{ minWidth: '150px', fontWeight: 'bold', fontSize: '0.85rem' }}>
                  Vessel name
                </Typography>
                <Typography sx={{ minWidth: '100px', fontWeight: 'bold', fontSize: '0.85rem' }}>
                  ETA
                </Typography>
                <Typography sx={{ minWidth: '120px', fontWeight: 'bold', fontSize: '0.85rem' }}>
                  Draught
                </Typography>
                <Typography sx={{ minWidth: '150px', fontWeight: 'bold', fontSize: '0.85rem' }}>
                  Position received
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                minWidth: 'fit-content',
                overflowY: 'auto',
                ...customScrollbar,
                '& > *:nth-of-type(even)': {
                  backgroundColor: (theme) => theme.palette.action.hover
                }
              }}>
              {vessels.map((vessel) => {
                const draughtToDisplay = `${formatToDecimalPlaces(
                  vessel.draught,
                  1
                )}/${formatToDecimalPlaces(vessel.vesselDraught, 1)}`;
                const percentToDisplay = `${formatToDecimalPlaces(vessel.percentDraught, 1)}%`;

                return (
                  <Box
                    key={vessel.imo}
                    display="flex"
                    gap={1}
                    sx={{
                      p: 0.75,
                      borderRadius: 1
                    }}>
                    <Box minWidth="150px">
                      {vessel?.operatorId ? (
                        <ProfileLink
                          name={vessel?.operatorShortName}
                          profile={'operator'}
                          id={vessel?.operatorId}
                        />
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </Box>
                    <Box sx={{ minWidth: '150px' }}>
                      <ProfileLink name={vessel.vesselName} profile={'vessel'} id={vessel.imo} />
                    </Box>
                    <Typography
                      sx={{
                        minWidth: '100px',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        fontSize: '0.8rem'
                      }}>
                      {getTimeZoneDisplay(vessel.eta)}
                    </Typography>
                    <Typography
                      sx={{
                        minWidth: '120px',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        fontSize: '0.8rem'
                      }}>
                      {draughtToDisplay} ({percentToDisplay})
                    </Typography>
                    <Typography
                      sx={{
                        minWidth: '150px',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        fontSize: '0.8rem'
                      }}>
                      {getTimeZoneDisplay(vessel.timestamp)}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      ))}
    </Paper>
  );
};

InboundVesselInsightVesselTab.propTypes = {
  vesselsLatestPosition: PropTypes.array,
  ports: PropTypes.array
};

export default InboundVesselInsightVesselTab;
