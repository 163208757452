import { FILTER_TYPES } from '../constants';
import { useMapState } from './useMapState';

export const useActivityMapUiState = (activeFilters, selectedFleets) => {
  const { filterType, quickSearchItemType, quickSearchItemId } = useMapState();

  const isQuickSearchFilterApplicable =
    filterType === FILTER_TYPES.QUICK_SEARCH && quickSearchItemType && quickSearchItemId;
  const isInboundFilterApplicable =
    filterType === FILTER_TYPES.INBOUND && activeFilters?.selectedLocations?.length;
  const isAdvancedFilterApplicable = filterType === FILTER_TYPES.ADVANCED && activeFilters;
  const isFleetFilterApplicable = filterType === FILTER_TYPES.FLEET && selectedFleets?.size;

  return {
    isQuickSearchFilterApplicable,
    isInboundFilterApplicable,
    isAdvancedFilterApplicable,
    isFleetFilterApplicable
  };
};
