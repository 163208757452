import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import DashboardActivityMapDrawerHeader from '../Common/DashboardActivityMapDrawerHeader';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  FilterSlider,
  DateRangeMobi,
  IconButton,
  Paper,
  CircularProgress,
  OperatorTreeSelect
} from '@esgian/esgianui';
import { OwnerMultiSelect, LocationSelect } from '@components/Inputs';
import moment from 'moment';
import { useDynamicLookupQueries, useStaticLookupQueries, useSegment, useTheme } from '@hooks';
import {
  COMMERCIAL_CATEGORY_TYPE,
  VESSEL_MAX_CAPACITY,
  VESSEL_MAX_CAPACITY_DWT,
  VESSEL_MAX_LIFTING_CAPACITY,
  VESSEL_MIN_BUILD_YEAR,
  VESSEL_MAX_GROSS_TONNAGE,
  OPERATOR_TYPE,
  VESSEL_MIN_DRAUGHT,
  VESSEL_MAX_DRAUGHT,
  VESSEL_MIN_DRAUGHT_PERCENTAGE,
  VESSEL_MAX_DRAUGHT_PERCENTAGE,
  VESSEL_MIN_NO_OF_CRANES,
  VESSEL_MAX_NO_OF_CRANES,
  VESSEL_MIN_BEAM,
  VESSEL_RORO_MAX_BEAM,
  VESSEL_MPP_MAX_BEAM,
  VESSEL_MIN_LENGTH,
  VESSEL_RORO_MAX_LENGTH,
  VESSEL_MPP_MAX_LENGTH
} from '@constants';
import { formatNumberReadable } from '@helpers';
import { useSelector } from 'react-redux';
import { getDateFormat, getThemeMode } from '@store/features';
import { CloseIcon } from '@esgian/esgianui';
import { getTransparentBackground } from '@helpers';
import { getStartEndDate } from '@helpers/DatePickerHelpers';
import { TextWithTooltipIcon } from '@components';
import {
  COM_CAT_TYPE_ALL_TEXT,
  COM_CAT_TYPE_DEEP_SEA_CORE_TEXT,
  COM_CAT_TYPE_DEEP_SEA_EXT_TEXT,
  COM_CAT_TYPE_OTHER_TEXT,
  COM_CAT_TYPE_SHORT_SEA_EUR_TEXT,
  OPR_TYPE_BOTH_TEXT,
  OPR_TYPE_DEEP_SEA_TEXT,
  OPR_TYPE_SHORT_SEA_TEXT
} from '@constants/tooltips';
import './dashboardActivityMapFilter.css';

const SLIDER_HORIZONTAL_PADDING = 2;

export const getDashboardActivityMapFilterInitialValues = (isRoRo) => {
  return {
    selectedLocations: [null],
    selectedOperators: [],
    selectedOwners: [],
    minCarCapacity: 0,
    maxCarCapacity: VESSEL_MAX_CAPACITY,
    minLiftingCapacity: 0,
    maxLiftingCapacity: VESSEL_MAX_LIFTING_CAPACITY,
    minBuildYear: VESSEL_MIN_BUILD_YEAR,
    maxBuildYear: moment().year(),
    minDeadweight: 0,
    maxDeadweight: VESSEL_MAX_CAPACITY_DWT,
    minGrossTonnage: 0,
    maxGrossTonnage: VESSEL_MAX_GROSS_TONNAGE,
    operatorTypeId: OPERATOR_TYPE.ALL,
    commercialCategoryId: COMMERCIAL_CATEGORY_TYPE.ALL,
    minDraught: VESSEL_MIN_DRAUGHT,
    maxDraught: VESSEL_MAX_DRAUGHT,
    minDraughtPercentage: VESSEL_MIN_DRAUGHT_PERCENTAGE,
    maxDraughtPercentage: VESSEL_MAX_DRAUGHT_PERCENTAGE,
    minNoOfCranes: VESSEL_MIN_NO_OF_CRANES,
    maxNoOfCranes: VESSEL_MAX_NO_OF_CRANES,
    minBeam: VESSEL_MIN_BEAM,
    maxBeam: isRoRo ? VESSEL_RORO_MAX_BEAM : VESSEL_MPP_MAX_BEAM,
    minLength: VESSEL_MIN_LENGTH,
    maxLength: isRoRo ? VESSEL_RORO_MAX_LENGTH : VESSEL_MPP_MAX_LENGTH
  };
};

export const checkDashboardActivityMapFilterApplied = (
  filters,
  isInboundVesselsFiltering,
  isRoRo
) => {
  if (!filters) return false;

  const dashboardActivityMapFilterInitialValues =
    getDashboardActivityMapFilterInitialValues(isRoRo);

  const keysToCheck = [
    'selectedOperators',
    'selectedOwners',
    'minCarCapacity',
    'maxCarCapacity',
    'minLiftingCapacity',
    'maxLiftingCapacity',
    'minBuildYear',
    'maxBuildYear',
    'minDeadweight',
    'maxDeadweight',
    'minGrossTonnage',
    'maxGrossTonnage',
    'operatorTypeId',
    'commercialCategoryId',
    'minDraught',
    'maxDraught',
    'minDraughtPercentage',
    'maxDraughtPercentage',
    'minNoOfCranes',
    'maxNoOfCranes',
    'minBeam',
    'maxBeam',
    'minLength',
    'maxLength'
  ];

  if (isInboundVesselsFiltering) {
    keysToCheck.push('selectedLocations');
  }

  return keysToCheck.some((key) => {
    if (key === 'selectedLocations') {
      return filters.selectedLocations.some((location) => location !== null);
    }

    if (Array.isArray(filters[key])) {
      return filters[key].length !== dashboardActivityMapFilterInitialValues[key].length;
    }
    return filters[key] !== dashboardActivityMapFilterInitialValues[key];
  });
};

const DashboardActivityMapFilter = ({
  onClose,
  onApply,
  defaultFilters,
  disableLocationFilter,
  disableOperatorFilter,
  disableOwnerFilter,
  isLoading,
  isInboundVesselsFiltering
}) => {
  const { customScrollbar } = useTheme();
  const { lookupLocationsQuery, lookupOwnerHierarchyQuery } = useStaticLookupQueries();

  const {
    lookupOwnerOperatorVesselHierarchyQuery,
    lookupOperatorVesselHierarchyQuery,
    lookupVesselsQuery
  } = useDynamicLookupQueries();

  const dateFormat = useSelector(getDateFormat);
  const themeMode = useSelector(getThemeMode);

  const { isRoRo } = useSegment();

  const dashboardActivityMapFilterInitialValues =
    getDashboardActivityMapFilterInitialValues(isRoRo);

  // State management for all filter values with default values from props
  const [filters, setFilters] = useState({
    selectedLocations: defaultFilters?.selectedLocations || [null],
    selectedOperators: defaultFilters?.selectedOperators || [],
    selectedVessels: defaultFilters?.selectedVessels || [],
    selectedOwners: defaultFilters?.selectedOwners || [],
    minCarCapacity: defaultFilters?.minCarCapacity || 0,
    maxCarCapacity: defaultFilters?.maxCarCapacity || VESSEL_MAX_CAPACITY,
    minLiftingCapacity: defaultFilters?.minLiftingCapacity || 0,
    maxLiftingCapacity: defaultFilters?.maxLiftingCapacity || VESSEL_MAX_LIFTING_CAPACITY,
    minBuildYear: defaultFilters?.minBuildYear || VESSEL_MIN_BUILD_YEAR,
    maxBuildYear: defaultFilters?.maxBuildYear || moment().year(),
    minDeadweight: defaultFilters?.minDeadweight || 0,
    maxDeadweight: defaultFilters?.maxDeadweight || VESSEL_MAX_CAPACITY_DWT,
    minGrossTonnage: defaultFilters?.minGrossTonnage || 0,
    maxGrossTonnage: defaultFilters?.maxGrossTonnage || VESSEL_MAX_GROSS_TONNAGE,
    operatorTypeId: defaultFilters?.operatorTypeId || OPERATOR_TYPE.ALL,
    commercialCategoryId: defaultFilters?.commercialCategoryId || COMMERCIAL_CATEGORY_TYPE.ALL,
    startDate: defaultFilters?.startDate || null,
    endDate: defaultFilters?.endDate || null,
    minDraught: defaultFilters?.minDraught || VESSEL_MIN_DRAUGHT,
    maxDraught: defaultFilters?.maxDraught || VESSEL_MAX_DRAUGHT,
    minDraughtPercentage: defaultFilters?.minDraughtPercentage || VESSEL_MIN_DRAUGHT_PERCENTAGE,
    maxDraughtPercentage: defaultFilters?.maxDraughtPercentage || VESSEL_MAX_DRAUGHT_PERCENTAGE,
    minNoOfCranes: defaultFilters?.minNoOfCranes || VESSEL_MIN_NO_OF_CRANES,
    maxNoOfCranes: defaultFilters?.maxNoOfCranes || VESSEL_MAX_NO_OF_CRANES,
    minBeam: defaultFilters?.minBeam || dashboardActivityMapFilterInitialValues.minBeam,
    maxBeam: defaultFilters?.maxBeam || dashboardActivityMapFilterInitialValues.maxBeam,
    minLength: defaultFilters?.minLength || dashboardActivityMapFilterInitialValues.minLength,
    maxLength: defaultFilters?.maxLength || dashboardActivityMapFilterInitialValues.maxLength
  });

  const vesselHierarchyOptions = useMemo(() => {
    if (
      lookupOwnerHierarchyQuery?.data?.length &&
      lookupOperatorVesselHierarchyQuery?.data?.length &&
      lookupVesselsQuery?.data?.length
    ) {
      return {
        ownerHierarchy: lookupOwnerHierarchyQuery.data,
        operatorHierarchy: lookupOperatorVesselHierarchyQuery.data,
        vessels: lookupVesselsQuery.data
      };
    }
    return null;
  }, [
    lookupOwnerHierarchyQuery?.data,
    lookupOperatorVesselHierarchyQuery?.data,
    lookupVesselsQuery?.data
  ]);

  // Handler to update filter values
  const updateFilterValue = useCallback((payload) => {
    setFilters((prev) => ({ ...prev, ...payload }));
  }, []);

  const handleReset = () => {
    setFilters(dashboardActivityMapFilterInitialValues);
    onApply(dashboardActivityMapFilterInitialValues, true);
  };

  const handleApply = () => {
    // For inbound vessel filtering, ensure we include the date range
    if (isInboundVesselsFiltering) {
      onApply({
        ...filters,
        selectedDate: {
          startDate: filters.startDate,
          endDate: filters.endDate
        }
      });
    } else {
      onApply(filters);
    }
  };

  const handleAddLocation = () => {
    if (filters.selectedLocations.length < 4) {
      updateFilterValue({
        selectedLocations: [...filters.selectedLocations, null]
      });
    }
  };

  const handleRemoveLocation = (index) => {
    const newLocations = [...filters.selectedLocations];
    newLocations.splice(index, 1);
    updateFilterValue({ selectedLocations: newLocations });
  };

  const handleLocationChange = (val, index) => {
    const newLocations = [...filters.selectedLocations];
    newLocations[index] = val;
    updateFilterValue({ selectedLocations: newLocations });
  };

  const isAllValidLocation = filters.selectedLocations.every((location) => location !== null);

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        p: 2,
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: getTransparentBackground(themeMode)
      }}>
      <Box>
        <DashboardActivityMapDrawerHeader
          title={isInboundVesselsFiltering ? 'Inbound Vessels Filter' : 'Advanced Filter'}
          onClose={onClose}
        />
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        px={2}
        overflow="auto"
        sx={customScrollbar}
        flex={1}
        pt={1}>
        {isInboundVesselsFiltering &&
          filters.selectedLocations.map((location, index) => (
            <Box key={index} display="flex" alignItems="center" gap={1}>
              <LocationSelect
                label={
                  filters.selectedLocations.length > 1
                    ? `${isInboundVesselsFiltering ? 'Destination' : 'Location'} ${index + 1}*`
                    : isInboundVesselsFiltering
                    ? 'Destination*'
                    : 'Location'
                }
                placeholder="Search by port or country"
                handleChange={(val) => handleLocationChange(val, index)}
                selectedLocation={location}
                locationList={lookupLocationsQuery.data ?? []}
                width="100%"
                disabled={disableLocationFilter}
                disableRegion
                loading={lookupLocationsQuery.isFetching}
              />
              {filters.selectedLocations.length > 1 && (
                <IconButton onClick={() => handleRemoveLocation(index)} size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          ))}

        {isInboundVesselsFiltering && filters.selectedLocations.length < 4 && (
          <Button onClick={handleAddLocation} variant="text" sx={{ alignSelf: 'flex-start' }}>
            + Add Destination
          </Button>
        )}

        {isInboundVesselsFiltering && (
          <DateRangeMobi
            sx={{
              inputProps: {
                autoComplete: 'off',
                label: 'ETA window (Laycan)',
                defaultValue: undefined,
                value:
                  filters?.startDate || filters?.endDate
                    ? `${
                        moment(filters?.startDate).isValid()
                          ? moment(filters?.startDate).format(dateFormat)
                          : ''
                      } - ${
                        moment(filters?.endDate).isValid()
                          ? moment(filters?.endDate).format(dateFormat)
                          : ''
                      }`
                    : ''
              }
            }}
            onClose={({ value }) => {
              const { start: startDate, end: endDate } = getStartEndDate(value, dateFormat);
              updateFilterValue({
                startDate: startDate,
                endDate: endDate
              });
            }}
            dateFormat={dateFormat}
            startDate={filters?.startDate ? moment(filters.startDate).format(dateFormat) : null}
            endDate={filters?.endDate ? moment(filters.endDate).format(dateFormat) : null}
            placeholder="ETA window (Laycan)"
            minStartDate={moment()}
          />
        )}

        {!disableOperatorFilter && (
          <OperatorTreeSelect
            label="Select Operators/Vessels"
            selected={filters.selectedVessels}
            handleClose={({ selected }) => {
              updateFilterValue({ selectedVessels: selected });
            }}
            handleSelectTypeChange={() => {}}
            lookupVesselHierarchy={vesselHierarchyOptions}
            loading={
              lookupOwnerHierarchyQuery?.isPending ||
              lookupOperatorVesselHierarchyQuery?.isPending ||
              lookupVesselsQuery?.isPending
            }
          />
        )}

        {isRoRo && (
          <OwnerMultiSelect
            helperText=""
            error={false}
            placeholder="Select Owners..."
            selectedOwners={filters.selectedOwners}
            ownersList={lookupOwnerOperatorVesselHierarchyQuery?.data ?? []}
            handleChange={(values) => updateFilterValue({ selectedOwners: values })}
            width="100%"
            loading={lookupOwnerOperatorVesselHierarchyQuery.isFetching}
            disabled={disableOwnerFilter}
            label="Owner"
          />
        )}

        <Box>
          <Typography variant="subtitle1" gutterBottom color="text.primary">
            Display {isRoRo ? 'Operators' : 'Commercial category'}
          </Typography>
          {isRoRo ? (
            <RadioGroup
              value={filters.operatorTypeId}
              onChange={({ target }) => {
                updateFilterValue({ operatorTypeId: Number(target.value) });
              }}>
              <FormControlLabel
                value={OPERATOR_TYPE.ALL}
                label={
                  <TextWithTooltipIcon
                    label={<Typography variant="subtitle2">Both</Typography>}
                    tooltipText={OPR_TYPE_BOTH_TEXT}
                  />
                }
                control={<Radio />}
              />
              <FormControlLabel
                value={OPERATOR_TYPE.SHORT_SEA}
                label={
                  <TextWithTooltipIcon
                    label={<Typography variant="subtitle2">Short sea</Typography>}
                    tooltipText={OPR_TYPE_SHORT_SEA_TEXT}
                  />
                }
                control={<Radio />}
              />
              <FormControlLabel
                value={OPERATOR_TYPE.DEEP_SEA}
                label={
                  <TextWithTooltipIcon
                    label={<Typography variant="subtitle2">Deep sea</Typography>}
                    tooltipText={OPR_TYPE_DEEP_SEA_TEXT}
                  />
                }
                control={<Radio />}
              />
            </RadioGroup>
          ) : (
            <RadioGroup
              value={filters.commercialCategoryId}
              onChange={({ target }) => {
                updateFilterValue({ commercialCategoryId: Number(target.value) });
              }}>
              <FormControlLabel
                value={COMMERCIAL_CATEGORY_TYPE.ALL}
                label={
                  <TextWithTooltipIcon
                    label={
                      <Typography component={'span'} variant={'subtitle2'}>
                        All
                      </Typography>
                    }
                    tooltipText={COM_CAT_TYPE_ALL_TEXT}
                  />
                }
                control={<Radio />}
              />
              <FormControlLabel
                value={COMMERCIAL_CATEGORY_TYPE.DEEP_SEA_CORE}
                label={
                  <TextWithTooltipIcon
                    label={<Typography variant="subtitle2">Deep sea core</Typography>}
                    tooltipText={COM_CAT_TYPE_DEEP_SEA_CORE_TEXT}
                  />
                }
                control={<Radio />}
              />
              <FormControlLabel
                value={COMMERCIAL_CATEGORY_TYPE.DEEP_SEA_EXT}
                label={
                  <TextWithTooltipIcon
                    label={<Typography variant="subtitle2">Deep sea extended</Typography>}
                    tooltipText={COM_CAT_TYPE_DEEP_SEA_EXT_TEXT}
                  />
                }
                control={<Radio />}
              />
              <FormControlLabel
                value={COMMERCIAL_CATEGORY_TYPE.SHORT_SEA_EUR}
                label={
                  <TextWithTooltipIcon
                    label={<Typography variant="subtitle2">Short sea Europe</Typography>}
                    tooltipText={COM_CAT_TYPE_SHORT_SEA_EUR_TEXT}
                  />
                }
                control={<Radio />}
              />
              <FormControlLabel
                value={COMMERCIAL_CATEGORY_TYPE.OTHER}
                label={
                  <TextWithTooltipIcon
                    label={<Typography variant="subtitle2">Widedeck / Other</Typography>}
                    tooltipText={COM_CAT_TYPE_OTHER_TEXT}
                  />
                }
                control={<Radio />}
              />
            </RadioGroup>
          )}
        </Box>

        <Box px={SLIDER_HORIZONTAL_PADDING}>
          <FilterSlider
            disableMarkRotation
            marks={[
              {
                value: dashboardActivityMapFilterInitialValues.minDraught,
                label: dashboardActivityMapFilterInitialValues.minDraught
              },
              {
                value: dashboardActivityMapFilterInitialValues.maxDraught,
                label: dashboardActivityMapFilterInitialValues.maxDraught
              }
            ]}
            value={[filters.minDraught, filters.maxDraught]}
            max={dashboardActivityMapFilterInitialValues.maxDraught}
            min={dashboardActivityMapFilterInitialValues.minDraught}
            title="Max Draught (m)"
            onChange={(val) => updateFilterValue({ minDraught: val[0], maxDraught: val[1] })}
          />
        </Box>

        <Box px={SLIDER_HORIZONTAL_PADDING}>
          <FilterSlider
            disableMarkRotation
            marks={[
              {
                value: dashboardActivityMapFilterInitialValues.minDraughtPercentage,
                label: dashboardActivityMapFilterInitialValues.minDraughtPercentage
              },
              {
                value: dashboardActivityMapFilterInitialValues.maxDraughtPercentage,
                label: dashboardActivityMapFilterInitialValues.maxDraughtPercentage
              }
            ]}
            value={[filters.minDraughtPercentage, filters.maxDraughtPercentage]}
            max={dashboardActivityMapFilterInitialValues.maxDraughtPercentage}
            min={dashboardActivityMapFilterInitialValues.minDraughtPercentage}
            title="Percentage loaded (%)"
            onChange={(val) =>
              updateFilterValue({
                minDraughtPercentage: val[0],
                maxDraughtPercentage: val[1]
              })
            }
          />
        </Box>

        <Box px={SLIDER_HORIZONTAL_PADDING}>
          <FilterSlider
            disableMarkRotation
            step={100}
            marks={[
              { value: 0, label: 0 },
              {
                value: isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_LIFTING_CAPACITY,
                label: isRoRo
                  ? formatNumberReadable(VESSEL_MAX_CAPACITY)
                  : formatNumberReadable(VESSEL_MAX_LIFTING_CAPACITY)
              }
            ]}
            value={
              isRoRo
                ? [filters.minCarCapacity, filters.maxCarCapacity]
                : [filters.minLiftingCapacity, filters.maxLiftingCapacity]
            }
            max={isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_LIFTING_CAPACITY}
            min={0}
            title={isRoRo ? 'Car capacity (CEU)' : 'Lifting capacity (MT)'}
            tooltipText={
              !isRoRo ? 'Total lifting capacity of the vessel, not per crane' : undefined
            }
            onChange={(val) =>
              updateFilterValue(
                isRoRo
                  ? {
                      minCarCapacity: val[0],
                      maxCarCapacity: val[1]
                    }
                  : {
                      minLiftingCapacity: val[0],
                      maxLiftingCapacity: val[1]
                    }
              )
            }
            sx={{
              '& .MuiSlider-root': {
                width: '80%'
              }
            }}
          />
        </Box>

        {!isRoRo && (
          <Box px={SLIDER_HORIZONTAL_PADDING}>
            <FilterSlider
              disableMarkRotation
              marks={[
                {
                  value: dashboardActivityMapFilterInitialValues.minNoOfCranes,
                  label: dashboardActivityMapFilterInitialValues.minNoOfCranes
                },
                {
                  value: dashboardActivityMapFilterInitialValues.maxNoOfCranes,
                  label: dashboardActivityMapFilterInitialValues.maxNoOfCranes
                }
              ]}
              value={[filters.minNoOfCranes, filters.maxNoOfCranes]}
              max={dashboardActivityMapFilterInitialValues.maxNoOfCranes}
              min={dashboardActivityMapFilterInitialValues.minNoOfCranes}
              title="No. of Cranes"
              onChange={(val) =>
                updateFilterValue({ minNoOfCranes: val[0], maxNoOfCranes: val[1] })
              }
            />
          </Box>
        )}

        <Box px={SLIDER_HORIZONTAL_PADDING}>
          <FilterSlider
            disableMarkRotation
            step={1000}
            marks={[
              { value: 0, label: 0 },
              {
                value: VESSEL_MAX_CAPACITY_DWT,
                label: formatNumberReadable(VESSEL_MAX_CAPACITY_DWT)
              }
            ]}
            value={[filters.minDeadweight, filters.maxDeadweight]}
            max={VESSEL_MAX_CAPACITY_DWT}
            min={0}
            title="Deadweight (DWT)"
            onChange={(val) => updateFilterValue({ minDeadweight: val[0], maxDeadweight: val[1] })}
          />
        </Box>

        <Box px={SLIDER_HORIZONTAL_PADDING}>
          <FilterSlider
            disableMarkRotation
            step={10}
            marks={[
              { value: 0, label: 0 },
              {
                value: VESSEL_MAX_GROSS_TONNAGE,
                label: formatNumberReadable(VESSEL_MAX_GROSS_TONNAGE)
              }
            ]}
            value={[filters.minGrossTonnage, filters.maxGrossTonnage]}
            max={VESSEL_MAX_GROSS_TONNAGE}
            min={0}
            title="Gross Tonnage (GT)"
            onChange={(val) =>
              updateFilterValue({ minGrossTonnage: val[0], maxGrossTonnage: val[1] })
            }
          />
        </Box>

        <Box px={SLIDER_HORIZONTAL_PADDING}>
          <FilterSlider
            disableMarkRotation
            marks={[
              {
                value: dashboardActivityMapFilterInitialValues.minLength,
                label: dashboardActivityMapFilterInitialValues.minLength
              },
              {
                value: dashboardActivityMapFilterInitialValues.maxLength,
                label: dashboardActivityMapFilterInitialValues.maxLength
              }
            ]}
            value={[filters.minLength, filters.maxLength]}
            max={dashboardActivityMapFilterInitialValues.maxLength}
            min={dashboardActivityMapFilterInitialValues.minLength}
            step={10}
            title="Length Overall (m)"
            onChange={(val) => updateFilterValue({ minLength: val[0], maxLength: val[1] })}
          />
        </Box>

        <Box px={SLIDER_HORIZONTAL_PADDING}>
          <FilterSlider
            disableMarkRotation
            marks={[
              {
                value: dashboardActivityMapFilterInitialValues.minBeam,
                label: dashboardActivityMapFilterInitialValues.minBeam
              },
              {
                value: dashboardActivityMapFilterInitialValues.maxBeam,
                label: dashboardActivityMapFilterInitialValues.maxBeam
              }
            ]}
            value={[filters.minBeam, filters.maxBeam]}
            max={dashboardActivityMapFilterInitialValues.maxBeam}
            min={dashboardActivityMapFilterInitialValues.minBeam}
            title="Beam (m)"
            onChange={(val) => updateFilterValue({ minBeam: val[0], maxBeam: val[1] })}
          />
        </Box>

        <Box px={SLIDER_HORIZONTAL_PADDING}>
          <FilterSlider
            disableMarkRotation
            marks={[
              { value: VESSEL_MIN_BUILD_YEAR, label: VESSEL_MIN_BUILD_YEAR },
              { value: moment().year(), label: moment().year() }
            ]}
            value={[filters.minBuildYear, filters.maxBuildYear]}
            max={moment().year()}
            min={VESSEL_MIN_BUILD_YEAR}
            title="Build year"
            onChange={(val) => updateFilterValue({ minBuildYear: val[0], maxBuildYear: val[1] })}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" justifyContent="space-between" gap={2}>
        <Divider />
        <Box display="flex" justifyContent="space-between">
          <Button variant="text" onClick={handleReset}>
            Reset all
          </Button>
          <Button
            variant="contained"
            onClick={handleApply}
            disabled={isLoading || (isInboundVesselsFiltering && !isAllValidLocation)}>
            {isLoading ? <CircularProgress size={24} /> : 'Apply'}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

DashboardActivityMapFilter.propTypes = {
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  defaultFilters: PropTypes.object,
  disableLocationFilter: PropTypes.bool,
  disableOperatorFilter: PropTypes.bool,
  disableOwnerFilter: PropTypes.bool,
  isLoading: PropTypes.bool,
  isInboundVesselsFiltering: PropTypes.bool
};

export default DashboardActivityMapFilter;
