import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Modal,
  Typography,
  CircularProgress,
  TextField,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Paper,
  IconButton
} from '@esgian/esgianui';
import { debounce } from 'lodash';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTheme } from '../../../../hooks';

const AddVesselsToFleetModal = ({
  open,
  onClose,
  onSubmit,
  isSubmitting,
  fleetName,
  vessels,
  existingFleet
}) => {
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [selectedVessels, setSelectedVessels] = useState([]);
  const { customScrollbar } = useTheme();

  // Initialize selectedVessels with existing fleet vessels
  useEffect(() => {
    setSelectedVessels(
      existingFleet.vessels.map((v) => ({
        label: v.vesselName,
        value: v.vesselImo,
        operator: v.operator
      }))
    );
  }, [existingFleet.vessels]);

  // Create a memoized index for faster searching
  const vesselSearchIndex = useMemo(() => {
    return vessels.map((vessel) => ({
      ...vessel,
      searchString: `${vessel.vesselName} ${vessel.imo} ${vessel.operator}`.toLowerCase()
    }));
  }, [vessels]);

  // Debounced search handler
  const debouncedSetSearch = useCallback(
    debounce((value) => {
      setDebouncedSearchText(value);
    }, 300),
    []
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    debouncedSetSearch(value);
  };

  const filteredVessels = useMemo(() => {
    if (!debouncedSearchText) return [];
    const searchLower = debouncedSearchText.toLowerCase();
    return vesselSearchIndex.filter((vessel) => vessel.searchString.includes(searchLower));
  }, [vesselSearchIndex, debouncedSearchText]);

  const handleToggleVessel = (vessel) => {
    setSelectedVessels((prev) => {
      const exists = prev.some((v) => v.value === vessel.imo);
      if (exists) {
        return prev.filter((v) => v.value !== vessel.imo);
      }
      return [
        ...prev,
        {
          label: vessel.vesselName,
          value: vessel.imo,
          operator: vessel.operator
        }
      ];
    });
  };

  const handleRemoveVessel = (vesselImo) => {
    setSelectedVessels((prev) => prev.filter((v) => v.value !== vesselImo));
  };

  const handleSubmit = () => {
    const currentVesselImos = new Set(selectedVessels.map((v) => v.value));
    const existingVesselImos = new Set(existingFleet.vessels.map((v) => v.vesselImo));

    // Find vessels to add (in current but not in existing)
    const vesselsToAdd = selectedVessels
      .filter((v) => !existingVesselImos.has(v.value))
      .map((v) => ({
        vesselName: v.label,
        vesselImo: v.value,
        operator: vessels.find((vessel) => vessel.imo === v.value)?.operator || ''
      }));

    // Find vessels to remove (in existing but not in current)
    const vesselsToRemove = existingFleet.vessels.filter(
      (v) => !currentVesselImos.has(v.vesselImo)
    );

    onSubmit({
      added: vesselsToAdd,
      removed: vesselsToRemove
    });

    setSelectedVessels([]);
    setSearchText('');
  };

  const hasChanges = () => {
    const currentVesselImos = new Set(selectedVessels.map((v) => v.value));
    const existingVesselImos = new Set(existingFleet.vessels.map((v) => v.vesselImo));

    // Check if sets are different
    if (currentVesselImos.size !== existingVesselImos.size) return true;

    // Check if any vessel is different
    for (const imo of currentVesselImos) {
      if (!existingVesselImos.has(imo)) return true;
    }

    return false;
  };

  React.useEffect(() => {
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [debouncedSetSearch]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100vw',
        height: '100vh',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 1
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1
        }}>
        <Box sx={{ p: 3, pb: 2 }}>
          <Typography variant="h6">Add Vessels to {fleetName}</Typography>
        </Box>

        <Typography variant="subtitle2" sx={{ px: 3, pb: 1 }}>
          Add New Vessels
        </Typography>

        <Box sx={{ px: 3, pb: 2 }}>
          <TextField
            fullWidth
            label="Search vessels"
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search by vessel name, IMO or operator..."
          />
        </Box>

        <Paper
          sx={{
            flex: 1,
            overflow: 'auto',
            mx: 3,
            display: 'block',
            bgcolor: 'background.default',
            maxHeight: '400px',
            marginBottom: 3
          }}>
          <List dense sx={{ maxHeight: 300, overflow: 'auto', ...customScrollbar }}>
            {!searchText && (
              <ListItem>
                <ListItemText
                  primary="Start typing to search vessels"
                  sx={{ textAlign: 'center', color: 'text.secondary' }}
                />
              </ListItem>
            )}

            {/* Add Select All header when there are search results */}
            {filteredVessels.length > 0 && (
              <ListItem
                sx={{
                  bgcolor: 'background.paper',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  borderBottom: '1px solid',
                  borderColor: 'divider'
                }}>
                <Checkbox
                  edge="start"
                  checked={
                    filteredVessels.length > 0 &&
                    filteredVessels.every((vessel) =>
                      selectedVessels.some((v) => v.value === vessel.imo)
                    )
                  }
                  indeterminate={
                    filteredVessels.some((vessel) =>
                      selectedVessels.some((v) => v.value === vessel.imo)
                    ) &&
                    !filteredVessels.every((vessel) =>
                      selectedVessels.some((v) => v.value === vessel.imo)
                    )
                  }
                  onChange={() => {
                    const allSelected = filteredVessels.every((vessel) =>
                      selectedVessels.some((v) => v.value === vessel.imo)
                    );
                    if (allSelected) {
                      setSelectedVessels((prev) =>
                        prev.filter(
                          (selected) =>
                            !filteredVessels.some((vessel) => vessel.imo === selected.value)
                        )
                      );
                    } else {
                      const newVessels = filteredVessels.map((vessel) => ({
                        label: vessel.vesselName,
                        value: vessel.imo,
                        operator: vessel.operator
                      }));
                      setSelectedVessels((prev) => {
                        const existing = prev.filter(
                          (selected) =>
                            !filteredVessels.some((vessel) => vessel.imo === selected.value)
                        );
                        return [...existing, ...newVessels];
                      });
                    }
                  }}
                />
                <ListItemText
                  primary={`Select All (${filteredVessels.length})`}
                  sx={{ fontWeight: 'bold' }}
                />
              </ListItem>
            )}

            {filteredVessels.map((vessel) => (
              <ListItem
                key={vessel.imo}
                onClick={() => handleToggleVessel(vessel)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'action.hover'
                  }
                }}>
                <Checkbox
                  edge="start"
                  checked={selectedVessels.some((v) => v.value === vessel.imo)}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText
                  primary={
                    <Box component="span">
                      {vessel.vesselName}
                      <Typography component="span" color="text.secondary" sx={{ ml: 1 }}>
                        ({vessel.operator || 'No operator'})
                      </Typography>
                    </Box>
                  }
                  // secondary={`IMO: ${vessel.imo}`}
                />
              </ListItem>
            ))}
            {filteredVessels.length === 0 && searchText && (
              <ListItem>
                <ListItemText
                  primary="No vessels found"
                  sx={{ textAlign: 'center', color: 'text.secondary' }}
                />
              </ListItem>
            )}
          </List>
        </Paper>

        {selectedVessels.length > 0 && (
          <Box sx={{ px: 3, pb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Current Fleet Vessels
            </Typography>
            <Paper sx={{ bgcolor: 'background.default', mb: 2 }}>
              <List dense sx={{ maxHeight: 200, overflow: 'auto' }}>
                {selectedVessels.map((vessel) => (
                  <ListItem
                    key={vessel.value}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => handleRemoveVessel(vessel.value)}
                        disabled={isSubmitting}>
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                    }>
                    <ListItemText
                      primary={
                        <Box component="span">
                          {vessel.label}
                          <Typography component="span" color="text.secondary" sx={{ ml: 1 }}>
                            ({vessel.operator || 'No operator'})
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 3,
            pt: 2,
            borderTop: 1,
            borderColor: 'divider'
          }}>
          <Typography variant="caption">{selectedVessels.length} vessels selected</Typography>
          <Box display="flex" gap={1}>
            <Button onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={isSubmitting || !hasChanges()}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

AddVesselsToFleetModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  fleetName: PropTypes.string.isRequired,
  vessels: PropTypes.array.isRequired,
  existingFleet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vessels: PropTypes.arrayOf(
      PropTypes.shape({
        vesselName: PropTypes.string.isRequired,
        vesselImo: PropTypes.number.isRequired,
        operator: PropTypes.string
      })
    ).isRequired
  }).isRequired
};

export default AddVesselsToFleetModal;
