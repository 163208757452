import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableCell, TableRow, Typography } from '@esgian/esgianui';
import { checkUserHasAccess, getTableCellRender } from '@helpers';
import { ProfileLink } from '@components/Links';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { TextWithTooltipIcon } from '@components';
import { tooltipConsistencyContents } from '@tooltipContents/tableTooltipContents/ConsistencyScoreTooltip';

function FrequencyAnalysisTable({ handleCellClick, loading, portServiceData, dateList }) {
  const [data, setData] = useState([]);

  //total,avg,min,max,consScore
  const [lowScores, setLowScores] = useState([0, 0, 0, 0, 0]);
  const [highScores, setHighScores] = useState([0, 0, 0, 0, 0]);
  const [columns, setColumns] = useState([]);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (portServiceData && dateList) {
      let tempData = [];
      //total,avg,min,max,consScore
      const tempLows = [0, 0, 0, 0, 0];
      const tempHighs = [0, 0, 0, 0, 0];

      const { frequencyResults } = portServiceData;
      frequencyResults?.forEach((operator, i) => {
        const {
          frequencyTotal: total,
          frequencyMin: min,
          frequencyMax: max,
          operatorLongName,
          operatorMonthlyFrequencyResults,
          operatorId,
          frequencyStats
        } = operator;
        let { consistencyScore, average } = frequencyStats || {};
        average = parseFloat(average?.toFixed(2)) || 0;
        if (i === 0) {
          tempLows[0] = total;
          tempLows[1] = average;
          tempLows[2] = min;
          tempLows[3] = max;
          tempLows[4] = consistencyScore;
        }
        if (tempHighs[0] < total) {
          tempHighs[0] = total;
        }
        if (tempLows[0] > total) {
          tempLows[0] = total;
        }
        if (tempHighs[1] < average) {
          tempHighs[1] = average;
        }
        if (tempLows[1] > average) {
          tempLows[1] = average;
        }
        if (tempHighs[2] < min) {
          tempHighs[2] = min;
        }
        if (tempLows[2] > min) {
          tempLows[2] = min;
        }

        if (tempHighs[3] < max) {
          tempHighs[3] = max;
        }
        if (tempLows[3] > max) {
          tempLows[3] = max;
        }

        if (tempHighs[4] < consistencyScore) {
          tempHighs[4] = consistencyScore;
        }
        if (tempLows[4] > consistencyScore) {
          tempLows[4] = consistencyScore;
        }

        let obj = {
          consistencyScore: consistencyScore,
          operatorMonthlyFrequencyResults: operatorMonthlyFrequencyResults,
          average: average,
          total: total,
          min: min,
          max: max,
          operatorName: operatorLongName,
          operatorId: operatorId
        };
        dateList?.forEach((monthDate, i) => {
          let monthObj = {};
          monthObj[monthDate.split(' ').join('_')] = operatorMonthlyFrequencyResults[i]?.portCalls;
          obj = { ...obj, ...monthObj };
        });
        tempData.push(obj);
      });
      setLowScores(tempLows);
      setHighScores(tempHighs);
      setData(tempData);
    }
  }, [portServiceData, dateList]);

  const [tableBoxShadow, setTableBoxShadow] = useState({});

  useEffect(() => {
    if (!portServiceData) {
      return;
    }

    const {
      palette: {
        tables: {
          boxShadow: { insetRight, insetLeft }
        }
      }
    } = theme;
    setTableBoxShadow({ insetRight, insetLeft });

    let columnDef = [
      {
        name: 'operatorName',
        label: 'Operator',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: insetRight
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink name={dataIndex} profile={'operator'} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: { display: false, viewColumns: false, filter: false }
      }
    ];

    const tableEnd = [
      {
        name: 'total',
        label: 'Total',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: '-3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const operatorId = rowIndex.rowData[1];
            return (
              <div
                style={{ cursor: dataIndex > 0 ? 'pointer' : 'default' }}
                onClick={() => handleCellClick(operatorId, 12)}>
                {getTableCellRender(highScores[0], lowScores[0], dataIndex)}
              </div>
            );
          }
        }
      },
      {
        name: 'average',
        label: 'Avg',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return getTableCellRender(
              parseFloat(highScores[1]),
              parseFloat(lowScores[1]),
              parseFloat(value)
            );
          }
        }
      },
      {
        name: 'min',
        label: 'Min',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[2], lowScores[2], dataIndex);
          }
        }
      },
      {
        name: 'max',
        label: 'Max',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[3], lowScores[3], dataIndex);
          }
        }
      },
      {
        name: 'consistencyScore',
        label: 'Consistency Score',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customHeadLabelRender: () => {
            return (
              <TextWithTooltipIcon
                tooltipText={tooltipConsistencyContents}
                label={'Consistency Score'}
                labelVariant={'body2'}
                variant={'body2'}
              />
            );
          },
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[4], lowScores[4], dataIndex);
          }
        }
      }
    ];
    dateList?.map((value, i) => {
      columnDef.push({
        name: value.split(' ').join('_'),
        label: value,
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellHeaderProps: () => {
            let shadow = {};
            if (i === 0) {
              shadow = 'inset 3px 0px 2px -1px rgb(0 0 0 / 20%)';
            } else if (i === dateList.length - 1) {
              shadow = 'inset -3px 0px 2px -1px rgb(0 0 0 / 20%)';
            }
            return {
              style: {
                boxShadow: `${shadow}`
              }
            };
          },

          customBodyRender: (dataIndex, rowIndex) => {
            if (!data || !portServiceData) {
              return '--';
            }
            const { columnIndex } = rowIndex;
            const { monthlyFrequencyTotals } = portServiceData;
            const monthlyDetails = monthlyFrequencyTotals[columnIndex - 2];
            let oprId = rowIndex.rowData[1];
            let period = rowIndex.columnIndex - 2;

            return (
              <div
                style={{
                  cursor: dataIndex > 0 ? 'pointer' : 'default'
                }}
                onClick={() => handleCellClick(oprId, period)}>
                {getTableCellRender(monthlyDetails?.max, monthlyDetails?.min, dataIndex)}
              </div>
            );
          }
        }
      });
    });
    setColumns([...columnDef, ...tableEnd]);
  }, [dateList, data]);

  const options = {
    customTableBodyFooterRender: () => {
      if (!portServiceData) {
        return;
      }
      let { monthlyFrequencyTotals } = portServiceData;
      let total = 0;
      return (
        <tbody id={'custom-table-footer'}>
          <TableRow
            key={`total-row-footer`}
            sx={{ backgroundColor: ({ palette }) => palette.background.default }}>
            <TableCell style={{ boxShadow: tableBoxShadow.insetRight }}>
              <Typography variant={'caption'} bold>
                Total
              </Typography>
            </TableCell>
            {monthlyFrequencyTotals.map(({ portCallTotal }, i) => {
              total += portCallTotal || 0;
              return (
                <TableCell key={`total-${i}`}>
                  <Typography variant={'caption'} bold sx={{ p: 1, m: '4px' }}>
                    {portCallTotal || '0'}
                  </Typography>
                </TableCell>
              );
            })}
            <TableCell style={{ boxShadow: '-3px 0px 2px -1px rgb(0 0 0 / 20%)' }}>
              <Typography bold sx={{ p: 1, m: '4px' }} variant={'caption'}>
                {total}
              </Typography>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </tbody>
      );
    },
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: 'frequency-analysis'
    },
    print: false,
    viewColumns: false,
    pagination: false,
    // toolbar: !!user,
    toolbar: checkUserHasAccess(user, true),
    elevation: 0,
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: 'total',
      direction: 'desc'
    }
  };

  return (
    <Table
      customStyle={{
        MUIDataTableFilterList: { display: 'none' },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              minHeight: 0
            }
          }
        }
      }}
      loading={loading}
      mode={theme.mode}
      title={''}
      data={data.length ? data : []}
      columns={columns}
      options={options}
    />
  );
}

FrequencyAnalysisTable.propTypes = {
  loading: PropTypes.bool,
  portServiceData: PropTypes.object,
  handleCellClick: PropTypes.func.isRequired,
  dateList: PropTypes.arrayOf(PropTypes.string)
};

FrequencyAnalysisTable.defaultProps = {
  loading: false,
  portServiceData: null,
  dateList: []
};

export default FrequencyAnalysisTable;
