import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getSavedFleets, updateFleet, SAVED_FLEETS_KEY } from '@api/Fleet';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features/auth/AuthSlice.js';
import { toast } from 'react-toastify';
import { FLEET_COLOR_PALETTE, getNextFleetColor } from '../helpers/dashboardActivityMapHelpers';

export const useFleets = () => {
  const user = useSelector(getUser);
  const email = user?.email;
  const queryClient = useQueryClient();
  const controller = new AbortController();
  const { signal } = controller;

  const {
    data: savedFleets = [],
    isLoading: isLoadingFleets,
    isFetching: isFetchingFleets
  } = useQuery({
    queryKey: [SAVED_FLEETS_KEY],
    queryFn: async () => {
      const data = await getSavedFleets(email, signal);
      const fleets = JSON.parse(data.userInformation) || [];
      // Ensure all fleets have colors
      const updatedFleets = fleets.map((fleet, index) => ({
        ...fleet,
        color: fleet.color || FLEET_COLOR_PALETTE[index % FLEET_COLOR_PALETTE.length]
      }));
      return updatedFleets;
    },
    enabled: !!email
  });

  const { mutateAsync: createFleet, isPending: isCreatingFleet } = useMutation({
    mutationFn: async ({ name, segmentId }) => {
      let existingFleets = [];
      try {
        existingFleets = await getSavedFleets(email, signal);
        existingFleets = JSON.parse(existingFleets.userInformation) || [];
      } catch (err) {
        console.error('Error fetching existing fleets:', err);
        existingFleets = savedFleets;
      }

      const isFleetExists = existingFleets.some((f) => f.name === name);
      if (isFleetExists) {
        throw new Error('Fleet with the same name already exists');
      }

      const nextColor = getNextFleetColor(existingFleets);
      const newFleet = {
        id: `fleet-${Date.now()}`,
        name,
        vessels: [],
        color: nextColor,
        segmentId
      };

      const updated = [...existingFleets, newFleet];
      await updateFleet(email, updated, signal);
      return newFleet;
    },
    onSuccess: (_data, { name }) => {
      toast.success(`Fleet "${name}" created successfully.`);
      queryClient.invalidateQueries({ queryKey: [SAVED_FLEETS_KEY] });
    },
    onError: (err) => {
      toast.error(`Could not create the fleet: ${err.message}`);
    }
  });

  const { mutateAsync: addVesselsToFleet, isPending: isAddingVessels } = useMutation({
    mutationFn: async ({ fleetId, vessels }) => {
      const updated = savedFleets.map((fleet) => {
        if (fleet.id === fleetId) {
          return {
            ...fleet,
            vessels: [...fleet.vessels, ...vessels]
          };
        }
        return fleet;
      });
      await updateFleet(email, updated, signal);
    },
    onSuccess: () => {
      toast.success('Vessels added to fleet successfully.');
      queryClient.invalidateQueries({ queryKey: [SAVED_FLEETS_KEY] });
    }
  });

  const { mutateAsync: removeVesselFromFleet, isPending: isRemovingVessel } = useMutation({
    mutationFn: async ({ fleetId, vesselImo }) => {
      console.log({ fleetId, vesselImo });
      const updated = savedFleets.map((fleet) => {
        if (fleet.id === fleetId) {
          return {
            ...fleet,
            vessels: fleet.vessels.filter((v) => v.vesselImo !== vesselImo)
          };
        }
        return fleet;
      });
      await updateFleet(email, updated, signal);
    },
    onSuccess: () => {
      toast.success('Vessel removed from fleet successfully.');
      queryClient.invalidateQueries({ queryKey: [SAVED_FLEETS_KEY] });
    }
  });

  const { mutateAsync: deleteFleet, isPending: isDeletingFleet } = useMutation({
    mutationFn: async (fleetId) => {
      const updated = savedFleets.filter((fleet) => fleet.id !== fleetId);
      await updateFleet(email, updated, signal);
    },
    onSuccess: () => {
      toast.success('Fleet deleted successfully.');
      queryClient.invalidateQueries({ queryKey: [SAVED_FLEETS_KEY] });
    }
  });

  const { mutateAsync: editFleet, isPending: isEditingFleet } = useMutation({
    mutationFn: async ({ fleetId, name, color }) => {
      const updated = savedFleets.map((fleet) => {
        if (fleet.id === fleetId) {
          return {
            ...fleet,
            name,
            color
          };
        }
        return fleet;
      });
      await updateFleet(email, updated, signal);
    },
    onSuccess: () => {
      toast.success('Fleet updated successfully.');
      queryClient.invalidateQueries({ queryKey: [SAVED_FLEETS_KEY] });
    },
    onError: (err) => {
      toast.error(`Could not update the fleet: ${err.message}`);
    }
  });

  const { mutateAsync: updateVesselNote, isPending: isUpdatingNote } = useMutation({
    mutationFn: async ({ fleetId, vesselImo, note }) => {
      const updated = savedFleets.map((fleet) => {
        if (fleet.id === fleetId) {
          return {
            ...fleet,
            vessels: fleet.vessels.map((vessel) => {
              if (vessel.vesselImo === vesselImo) {
                return {
                  ...vessel,
                  note
                };
              }
              return vessel;
            })
          };
        }
        return fleet;
      });
      await updateFleet(email, updated, signal);
    },
    onSuccess: () => {
      toast.success('Note updated successfully.');
      queryClient.invalidateQueries({ queryKey: [SAVED_FLEETS_KEY] });
    }
  });

  const { mutateAsync: updateFleetVessels, isPending: isUpdatingFleetVessels } = useMutation({
    mutationFn: async ({ fleetId, added, removed }) => {
      const updated = savedFleets.map((fleet) => {
        if (fleet.id === fleetId) {
          // Remove vessels that should be removed
          const remainingVessels = fleet.vessels.filter(
            (vessel) => !removed.some((v) => v.vesselImo === vessel.vesselImo)
          );
          // Add new vessels
          return {
            ...fleet,
            vessels: [...remainingVessels, ...added]
          };
        }
        return fleet;
      });
      await updateFleet(email, updated, signal);
    },
    onSuccess: () => {
      toast.success('Fleet vessels updated successfully.');
      queryClient.invalidateQueries({ queryKey: [SAVED_FLEETS_KEY] });
    }
  });

  return {
    savedFleets,
    isLoadingFleets,
    isFetchingFleets,
    createFleet,
    isCreatingFleet,
    addVesselsToFleet,
    isAddingVessels,
    removeVesselFromFleet,
    isRemovingVessel,
    deleteFleet,
    isDeletingFleet,
    editFleet,
    isEditingFleet,
    updateVesselNote,
    isUpdatingNote,
    updateFleetVessels,
    isUpdatingFleetVessels
  };
};
