import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  SearchIcon,
  Skeleton,
  Typography
} from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { useTheme } from '@hooks/useTheme';

function sortCanal(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function CanalListDisplay({ loading = false, map = null, setHoverCanal, lookupCanals = null }) {
  const [query, setQuery] = useState('');
  const [canalsList, setCanalsList] = useState([]);
  const { customScrollbar } = useTheme();

  useEffect(() => {
    if (query !== '') {
      setCanalsList(
        [...lookupCanals]
          .filter(({ name }) => {
            return name.toLowerCase().includes(query.toLowerCase());
          })
          ?.sort(sortCanal)
      );
    } else {
      setCanalsList([...lookupCanals]?.sort(sortCanal));
    }
  }, [lookupCanals, query]);

  const handleCanalHover = (id, name) => {
    map.current.getCanvas().style.cursor = 'pointer';
    setHoverCanal(name);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
      <div
        style={{
          display: 'flex',
          padding: '0px'
        }}>
        {!loading && lookupCanals && (
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                padding: '16px 16px 16px 16px',
                zIndex: 10
              }}>
              <OutlinedInput
                fullWidth
                value={query}
                onChange={({ target }) => setQuery(target.value)}
                inputProps={{ style: { padding: '8px' } }}
                endAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                placeholder={'Search canal...'}
              />
            </Grid>

            {(!!canalsList?.length || loading) && (
              <Grid item xs={12} sx={{ pt: '0px !important' }} id={'canal-lists'}>
                <List
                  sx={{
                    p: 0,
                    width: '100%',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: `calc(80vh - 72px)`,
                    '& ul': { padding: 0 },
                    ...customScrollbar
                  }}>
                  {loading &&
                    Array.apply(null, { length: 12 }).map((e, i) => (
                      <React.Fragment key={`skeleton-${i}`}>
                        <ListItem
                          sx={{
                            backgroundColor: ({ palette }) =>
                              i % 2 === 0 ? palette.background.paper : palette.background.default
                          }}>
                          <ListItemText>
                            <Skeleton width={'100%'} />
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  {!loading &&
                    canalsList?.map(({ name, regionId }, i) => (
                      <React.Fragment key={`${regionId}-${i}`}>
                        <ListItem
                          onMouseEnter={() => handleCanalHover(regionId, name)}
                          onMouseLeave={() => handleCanalHover('', null)}
                          sx={{
                            backgroundColor: ({ palette }) =>
                              i % 2 === 0 ? palette.background.paper : palette.background.default
                          }}>
                          <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                            <ProfileLink profile={'canal'} name={name} id={regionId} />
                          </ListItemText>
                        </ListItem>
                      </React.Fragment>
                    ))}
                </List>
              </Grid>
            )}
            {!canalsList?.length && !loading && (
              <Grid item xs={12} sx={{ p: 2 }}>
                <Typography>No matching search results</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </div>
  );
}

CanalListDisplay.propTypes = {
  loading: PropTypes.bool,
  map: PropTypes.object,
  setHoverCanal: PropTypes.func.isRequired,
  lookupCanals: PropTypes.arrayOf(PropTypes.object)
};

export default CanalListDisplay;
