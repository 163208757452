import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel, Paper, Switch, Typography } from '@esgian/esgianui';
import DashboardActivityMapDrawerHeader from '../Common/DashboardActivityMapDrawerHeader';
import { getTransparentBackground } from '@helpers';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { TextWithTooltipIcon } from '@components';
import { useMapState } from '../../../Sections/ShipAnalytics/DashboardActivityMapSection/hooks/useMapState';
import { ACTIVITY_MAP_SEARCH_PARAM_KEYS } from '../../../Sections/ShipAnalytics/DashboardActivityMapSection/constants';

export const MAX_VESSELS_TO_SHOW_NAME = 150;

const DashboardMapSettings = ({ onClose, disableShowVesselsStatus, isLoadingLookupPorts }) => {
  const themeMode = useSelector(getThemeMode);

  const { handleActionSettingsChange, mapSettings } = useMapState();
  const { showPorts, showVesselsNames, showVesselsStatus, showVessels, showPortNames } =
    mapSettings;

  const settingsSections = [
    {
      sectionTitle: 'Port',
      settings: [
        {
          label: 'Show port',
          name: 'showPort',
          value: showPorts,
          onChange: (e) =>
            handleActionSettingsChange(
              ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS,
              e.target.checked
            ),
          disabled: isLoadingLookupPorts
        }
      ]
    },
    {
      sectionTitle: 'Vessel',
      settings: [
        {
          label: 'Show vessels',
          name: 'showVessels',
          value: showVessels,
          onChange: (e) =>
            handleActionSettingsChange(
              ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS,
              e.target.checked
            )
        },
        {
          label: 'Show vessel name',
          name: 'showVesselName',
          value: showVesselsNames,
          onChange: (e) =>
            handleActionSettingsChange(
              ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_NAMES,
              e.target.checked
            )
        },
        {
          label: 'Show vessel status',
          name: 'showVesselStatus',
          value: showVesselsStatus,
          onChange: (e) =>
            handleActionSettingsChange(
              ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_STATUS,
              e.target.checked
            ),
          disabled: disableShowVesselsStatus
        }
      ]
    }
  ];

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 2,
        backgroundColor: getTransparentBackground(themeMode)
      }}>
      <DashboardActivityMapDrawerHeader title="Map setting" onClose={onClose} />
      {settingsSections.map((section) => (
        <Box key={section.sectionTitle}>
          <Typography>{section.sectionTitle}: </Typography>
          <ul style={{ margin: 0, paddingLeft: '16px' }}>
            {section.settings.map((setting) => (
              <li key={setting.name}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  {setting.tooltip ? (
                    <TextWithTooltipIcon
                      label={<FormLabel>{setting.label}</FormLabel>}
                      tooltipText={setting.tooltip}
                    />
                  ) : (
                    <FormLabel>{setting.label}</FormLabel>
                  )}
                  <Switch
                    disabled={!setting?.onChange || setting?.disabled}
                    checked={setting.value}
                    onChange={setting?.onChange}
                  />
                </Box>
              </li>
            ))}
          </ul>
        </Box>
      ))}
    </Paper>
  );
};

DashboardMapSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  isLoadingLookupPorts: PropTypes.bool,
  disableShowVesselsStatus: PropTypes.bool
};

export default DashboardMapSettings;
