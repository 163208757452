import React from 'react';
import { DashboardActivityMapActionWindow } from '@components/Maps/DashboardActivityMap';
import { Box, Paper } from '@esgian/esgianui';
import { getVesselInfoIcons, getInboundVesselsInfoIcons, getFleetInfoIcons } from '@helpers';

import PropTypes from 'prop-types';
import DashboardActivityMapVesselSearchBox from '../../Sections/ShipAnalytics/DashboardActivityMapSection/DashboardActivityMapVesselComponents/DashboardActivityMapVesselSearchBox';
import { MAX_VESSELS_TO_SHOW_NAME } from './DashboardActivityMapActionWindow/DashboardActivityMapSettings';
import { DashboardActivityMapInfoWindow } from './DashboardActivityMapFloatingComponents';
import {
  useDynamicLookupQueries,
  useVesselsLatestPositionByTypes,
  useStaticLookupQueries
} from '@hooks';
import { useMapState } from '../../Sections/ShipAnalytics/DashboardActivityMapSection/hooks/useMapState';
import { FILTER_TYPES } from '../../Sections/ShipAnalytics/DashboardActivityMapSection/constants';

const MAP_FULLSCREEN_BUTTON_Z_INDEX = 20;
const MAP_CONTAINER_Z_INDEX = 10;

const DashboardActivityMapFloatingPanel = ({
  quickSearchItemId,
  quickSearchItemType,
  activeFilters,
  setActiveFilters,
  filteredVessels,
  vesselsToFilter,
  selectedFleets,
  setSelectedFleets
}) => {
  const {
    mapSettings,
    selectedMapAction,
    setQuickSearch,
    resetQuickSearch,
    setInboundSearch,
    resetInboundSearch,
    handleActionClose,
    setAdvancedFilter,
    filterType
  } = useMapState();

  const {
    showPorts,
    showVessels,
    showVesselsStatus,
    showPortNames,
    showInboundVesselsColoredPorts
  } = mapSettings;

  const isInboundSearchApplied =
    filterType === FILTER_TYPES.INBOUND && activeFilters?.selectedLocations?.some(Boolean);

  const isFleetFilterApplied = filterType === FILTER_TYPES.FLEET && selectedFleets?.size > 0;

  const { data: vesselsLatestPosition, isPending: isLoadingVesselsLatestPosition } =
    useVesselsLatestPositionByTypes();

  const {
    lookupPortsQuery: { data: lookupPorts, isFetching: isLoadingLookupPorts },
    lookupOperatorVesselHierarchyQuery: {
      data: operatorVesselHierarchy,
      isFetching: isLoadingOperatorVesselHierarchy
    },
    lookupOwnerOperatorVesselHierarchyQuery: {
      data: ownerOperatorVesselHierarchy,
      isFetching: isLoadingOwnerOperatorVesselHierarchy
    }
  } = useDynamicLookupQueries();

  const {
    lookupLocationsQuery: { data: lookupLocations, isPending: isLoadingLookupLocations }
  } = useStaticLookupQueries();

  const infoItems =
    filterType === FILTER_TYPES.FLEET && selectedFleets?.size > 0
      ? getFleetInfoIcons(selectedFleets)
      : filterType === FILTER_TYPES.INBOUND
      ? getInboundVesselsInfoIcons({
          selectedLocations: activeFilters?.selectedLocations ?? [],
          showPorts,
          showVessels
        })
      : getVesselInfoIcons({
          showVesselsStatus,
          showPorts,
          showVessels
        });

  return (
    <>
      <Box
        position="absolute"
        top="16px"
        left="16px"
        zIndex={MAP_CONTAINER_Z_INDEX + 1}
        display="flex"
        flexDirection="column"
        gap={2}
        height="100%"
        sx={{
          pointerEvents: 'none',
          '& > *': {
            pointerEvents: 'auto'
          }
        }}>
        <Box width="25vw">
          <DashboardActivityMapVesselSearchBox
            vesselsLatestPosition={vesselsLatestPosition}
            selectedSearchOptionId={quickSearchItemId}
            selectedSearchOptionType={quickSearchItemType}
            onSelectSearchOption={(newSelectedSearchOption) => {
              const { id, type } = newSelectedSearchOption;
              setQuickSearch(id, type);
            }}
            onClearSearchValue={resetQuickSearch}
            operatorVesselHierarchy={operatorVesselHierarchy}
            ownerOperatorVesselHierarchy={ownerOperatorVesselHierarchy}
            lookupLocations={lookupLocations}
            lookupPorts={lookupPorts}
            isLoading={
              isLoadingOperatorVesselHierarchy ||
              isLoadingOwnerOperatorVesselHierarchy ||
              isLoadingVesselsLatestPosition ||
              isLoadingLookupPorts
            }
            disableCountrySearch={false}
            disablePortSearch={false}
          />
        </Box>
        <Box width="max-content">
          <DashboardActivityMapInfoWindow infoItems={infoItems} />
        </Box>
      </Box>

      {selectedMapAction && (
        <Box
          position="absolute"
          top="16px"
          right="72px"
          zIndex={MAP_FULLSCREEN_BUTTON_Z_INDEX + 1}
          display="flex"
          flexDirection="column"
          gap={2}
          height="100%"
          sx={{
            pointerEvents: 'none',
            '& > *': {
              pointerEvents: 'auto'
            }
          }}>
          <Paper sx={{ width: '25rem', maxHeight: '98%' }}>
            <DashboardActivityMapActionWindow
              selectedAction={selectedMapAction}
              vesselsLatestPosition={vesselsToFilter}
              selectedFleets={selectedFleets}
              setSelectedFleets={setSelectedFleets}
              onClose={handleActionClose}
              filterProps={{
                onApply: (params) => {
                  setActiveFilters(params);
                  setAdvancedFilter();
                },
                defaultFilters: activeFilters,
                isDisableApply: isLoadingVesselsLatestPosition,
                isLoading: isLoadingVesselsLatestPosition || isLoadingLookupPorts
              }}
              mapSettingsProps={{
                showPortNames,
                showInboundVesselsColoredPorts,
                disableShowVesselsStatus: isInboundSearchApplied || isFleetFilterApplied,
                isLoadingLookupPorts,
                disableShowVesselsNames: filteredVessels?.length > MAX_VESSELS_TO_SHOW_NAME
              }}
              inboundVesselsFilterProps={{
                onApply: (filters, isResetClick) => {
                  setActiveFilters(filters);
                  if (isResetClick) {
                    resetInboundSearch();
                  } else {
                    setInboundSearch();
                  }
                },
                defaultFilters: activeFilters,
                isInboundVesselsFiltering: true,
                isLoading:
                  isLoadingVesselsLatestPosition || isLoadingLookupPorts || isLoadingLookupLocations
              }}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

DashboardActivityMapFloatingPanel.propTypes = {
  quickSearchItemId: PropTypes.string,
  quickSearchItemType: PropTypes.string,
  activeFilters: PropTypes.object,
  setQuickSearchItemId: PropTypes.func,
  setQuickSearchItemType: PropTypes.func,
  setActiveFilters: PropTypes.func,
  filteredVessels: PropTypes.array,
  vesselsToFilter: PropTypes.array,
  selectedFleets: PropTypes.instanceOf(Set),
  handleFleetFilterChange: PropTypes.func,
  setSelectedFleets: PropTypes.func
};

export default DashboardActivityMapFloatingPanel;
