import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Button, Box, ArrowForwardIcon, Link, Popover } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { tableTitlePaddingLeft } from '@helpers';
import { getFilterInputValidationOption, getFilterInputValidationList } from '../helpers';
import { useSegment, useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import AddToVoyageGroupPopup from '@components/Sections/VoyageAnalytics/VoyageGroupSection/AddToVoyageGroupPopup';
import { useVoyageGroups } from '@pages/VoyageAnalytics/hooks/useVoyageGroups';
import CreateVoyageGroupPopup from '@components/Sections/VoyageAnalytics/VoyageGroupSection/CreateVoyageGroupPopup';
import { VoyageType } from '../../Sections/VoyageAnalytics/VoyageUtils';
import AddDestinationModal from '../../Sections/VoyageAnalytics/VoyageGroupSection/AddDestinationModal';
import { useGetVoyageType } from '@pages/VoyageAnalytics/hooks/useGetVoyageType';
import { getOngoingVoyagesFilters } from '@store/features/filters/VoyageAnalyticsPage/OngoingVoyage/OngoingVoyagesSlice';
import { formatToDecimalPlaces } from '@helpers';
import { useTimezone } from '@hooks/useTimezone';

function VoyagesTable({
  title,
  loading,
  hideColumns,
  fileName,
  voyageSearchResults,
  columnLabels,
  initialSortColumn
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { theme } = useTheme();
  const [showCreateVoyageGroupPopup, setShowCreateVoyageGroupPopup] = useState(false);
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const { uriExt } = useSegment();
  const [selectedVessels, setSelectedVessels] = useState([]);
  const [vesselsToAddDestination, setVesselsToAddDestination] = useState([]);
  const voyageGroupType = useGetVoyageType();
  const [sortedColumn, setSortedColumn] = useState({
    name: initialSortColumn,
    direction: voyageGroupType === VoyageType.COMPLETED ? 'asc' : 'desc'
  });
  const voyageGroupIdToAddDestinationRef = useRef();
  const reduxFilters = useSelector(getOngoingVoyagesFilters);
  const startDateSelectedByUser = reduxFilters.startDate || moment().format('YYYY-MM-DD HH:mm:ss');
  const { getTimeZoneDisplay } = useTimezone();
  const dayStartOfUserSelectedStartDate = moment(startDateSelectedByUser)
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss');

  const {
    completedVoyagesGroups,
    ongoingVoyagesGroups,
    isLoadingVoyageGroups,
    createVoyageGroup,
    isCreatingVoyageGroup,
    addVoyagesToGroup,
    isAddingVoyagesToGroup,
    updateVoyages,
    isUpdatingVoyages
  } = useVoyageGroups();

  const columns = useMemo(() => {
    return [
      {
        name: 'vesselName',
        label: 'Vessel Name',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'multiselect',
          setCellProps: () => {
            return {
              style: {
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'vessel'} id={rowData[1]} name={dataIndex} />
            ) : (
              '-'
            );
          }
        }
      },
      {
        name: 'imo',
        label: 'IMO',
        options: {
          display: !hideColumns.includes('imo'),
          download: !hideColumns.includes('imo'),
          filter: !hideColumns.includes('imo'),
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('IMO Number')
        }
      },
      {
        name: 'operatorShortName',
        label: 'Operator Name',
        options: {
          sort: true,
          filterType: 'textField',
          sortThirdClickReset: true,
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'operator'} id={rowData[8]} name={dataIndex} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'nextPortName',
        label: 'Next Port Call',
        options: {
          display: !hideColumns.includes('nextPortName'),
          download: !hideColumns.includes('nextPortName'),
          filter: !hideColumns.includes('nextPortName'),
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('Next Port Call'),
          customBodyRender: (data) => {
            if (data) return data;
            return '-';
          }
        }
      },
      {
        name: 'nextPortCountryName',
        label: 'Country of Next Port Call',
        options: {
          display: !hideColumns.includes('nextPortCountryName'),
          download: !hideColumns.includes('nextPortCountryName'),
          filter: !hideColumns.includes('nextPortCountryName'),
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('Country of Next Port Call'),
          customBodyRender: (data) => {
            if (data) return data;
            return '-';
          }
        }
      },
      {
        name: 'nextPortRegionName',
        label: 'Region of Next Port Call',
        options: {
          display: !hideColumns.includes('nextPortRegionName'),
          download: !hideColumns.includes('nextPortRegionName'),
          filter: !hideColumns.includes('nextPortRegionName'),
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('Region of Next Port Call'),
          customBodyRender: (data) => {
            if (data) return data;
            return '-';
          }
        }
      },
      {
        name: 'nextPortEta',
        label: 'ETA',
        options: {
          display: !hideColumns.includes('nextPortEta'),
          download: !hideColumns.includes('nextPortEta'),
          filter: !hideColumns.includes('nextPortEta'),
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('ETA'),
          customBodyRender: (dataIndex) =>
            dataIndex !== undefined && dataIndex !== null && moment(dataIndex).isValid()
              ? getTimeZoneDisplay(dataIndex)
              : '-'
        }
      },
      {
        name: 'portName',
        label: 'Latest Port Call',
        options: {
          display: !hideColumns.includes('portName'),
          download: !hideColumns.includes('portName'),
          filter: !hideColumns.includes('portName'),
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('Latest Port Call'),
          customBodyRender: (data) => {
            if (data) return data;
            return '-';
          }
        }
      },

      {
        name: 'operatorId',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      },
      {
        name: 'fromPortName',
        label: 'Departure Port',
        options: {
          display: !hideColumns.includes('fromPortName'),
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'port'} id={rowData[15]} name={dataIndex} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'toPortId',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      },
      {
        name: 'startDate',
        label: 'Departure Time',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          customBodyRender: (dataIndex) =>
            dataIndex !== undefined && dataIndex !== null && moment(dataIndex).isValid()
              ? getTimeZoneDisplay(dataIndex)
              : '-'
        }
      },
      {
        name: 'countryName',
        label: 'Country of Latest Port Call',
        options: {
          display: !hideColumns.includes('countryName'),
          download: !hideColumns.includes('countryName'),
          filter: !hideColumns.includes('countryName'),
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('Country of Latest Port Call'),
          customBodyRender: (data) => {
            if (data) return data;
            return '-';
          }
        }
      },
      {
        name: 'regionName',
        label: 'Region of Latest Port Call',
        options: {
          display: !hideColumns.includes('regionName'),
          download: !hideColumns.includes('regionName'),
          filter: !hideColumns.includes('regionName'),
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('Region of Latest Port Call'),
          customBodyRender: (data) => {
            if (data) return data;
            return '-';
          }
        }
      },
      {
        name: 'toPortName',
        label: columnLabels.toPortName || 'Arrival Port',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          display: !hideColumns.includes('toPortName'),
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'port'} id={rowData[10]} name={dataIndex} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'fromPortId',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      },
      {
        name: 'endDate',
        label: 'Arrival Time',
        options: {
          display: !hideColumns.includes('endDate'),
          download: !hideColumns.includes('endDate'),
          filter: !hideColumns.includes('endDate'),
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          customBodyRender: (dataIndex) => {
            if (moment(dataIndex).isValid()) {
              return getTimeZoneDisplay(dataIndex);
            }
            return '-';
          }
        }
      },
      {
        name: 'transitTime',
        label: 'Transit Time',
        options: {
          display: !hideColumns.includes('transitTime'),
          download: !hideColumns.includes('transitTime'),
          filter: !hideColumns.includes('transitTime'),
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          customBodyRender: (value) => {
            return `${formatToDecimalPlaces(value, 2)} days`;
          }
        }
      },
      {
        name: 'voyageProfile',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      },
      {
        name: 'voyageProfileId',
        label: 'Voyage Profile',
        options: {
          sort: false,
          filter: false,
          download: false,
          filterType: 'textField',
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            let imo = rowData[1];

            let params = {
              departureDate:
                voyageGroupType === VoyageType.COMPLETED ? rowData[11] : startDateSelectedByUser,
              arrivalDate: voyageGroupType == VoyageType.COMPLETED ? rowData[16] : null,
              fromPortId: voyageGroupType === VoyageType.COMPLETED ? rowData[15] : null,
              toPortId: voyageGroupType == VoyageType.COMPLETED ? rowData[10] : null
            };
            let paramsEncoded = '';
            Object.keys(params).forEach((key) => {
              if (paramsEncoded !== '') {
                paramsEncoded += '&';
              }
              paramsEncoded += key + '=' + encodeURIComponent(params[key]);
            });

            const urlToNavigate = `${uriExt}/${
              voyageGroupType == VoyageType.COMPLETED ? 'completed-voyages' : 'ongoing-voyages'
            }/${imo}?${paramsEncoded}`;

            return (
              <Link
                underline="hover"
                variant={'caption'}
                href={`${uriExt}/${
                  voyageGroupType == VoyageType.COMPLETED ? 'completed-voyages' : 'ongoing-voyages'
                }/${imo}?${paramsEncoded}`}
                onClick={(e) => {
                  navigate(urlToNavigate);
                  e.preventDefault();
                }}
                sx={{ textAlign: 'left', cursor: 'pointer' }}>
                View
                <ArrowForwardIcon fontSize="small" sx={{ ml: 1 }} />
              </Link>
            );
          }
        }
      }
    ];
  }, [hideColumns]);

  const options = useMemo(() => {
    return {
      onColumnSortChange: (changedColumn) => {
        let direction = null;
        if (changedColumn === sortedColumn.name) {
          if (sortedColumn.direction === 'asc') {
            direction = 'desc';
          } else {
            if (sortedColumn.direction == null) direction = 'asc';
          }
        } else {
          direction = 'asc';
        }
        setSortedColumn({ name: changedColumn, direction: direction });
      },
      onRowSelectionChange: (_currentRowsSelected, _allRowsSelected, rowsSelected) => {
        setSelectedVessels(
          rowsSelected.map((dataIndex) => {
            const voyage = voyageSearchResults[dataIndex];
            return {
              index: dataIndex,
              imo: voyage.imo.toString(),
              fromPortId: voyage.fromPortId,
              toPortId: voyage.toPortId,
              departureDate: voyage.startDate,
              arrivalDate: voyage.endDate,
              vesselName: voyage.vesselName
            };
          })
        );
      },
      rowsSelected: selectedVessels?.map(({ index }) => index),
      customToolbarSelect: () => {},
      responsive: 'standard',
      filter: false,
      search: true,
      download: true,
      setTableProps: () => {
        return {
          id: 'vessel-table'
        };
      },
      elevation: 0,
      print: false,
      viewColumns: false,
      pagination: true,
      toolbar: false,
      tableBodyMaxHeight: '80vh',
      sortOrder: sortedColumn,
      downloadOptions: {
        filename: fileName
      },
      textLabels: {
        body: {
          noMatch: 'Sorry, no voyages found'
        }
      },
      selectToolbarPlacement: 'none'
    };
  }, [user, fileName, voyageSearchResults, selectedVessels, sortedColumn]);

  const handleAddVoyagesToGroup = async (groupId) => {
    if (!selectedVessels.length) {
      return;
    }
    try {
      await addVoyagesToGroup({
        voyageGroupId: groupId,
        voyagesToAdd: selectedVessels.map((singleVessel) => ({
          imo: singleVessel.imo,
          fromPortId: singleVessel.fromPortId,
          departureDate:
            voyageGroupType === VoyageType.COMPLETED
              ? singleVessel.departureDate
              : dayStartOfUserSelectedStartDate,
          toPortId: voyageGroupType === VoyageType.ONGOING ? null : singleVessel.toPortId,
          arrivalDate: voyageGroupType === VoyageType.ONGOING ? null : singleVessel.arrivalDate
        }))
      });
      setAnchorEl(null);
      setSelectedVessels([]);
    } catch (error) {
      console.error('failed to add');
    }
  };

  let hasSelectedVessels = selectedVessels.length > 0;

  const filteredGroups = useMemo(
    () => (voyageGroupType === VoyageType.ONGOING ? ongoingVoyagesGroups : completedVoyagesGroups),
    [voyageGroupType, ongoingVoyagesGroups, completedVoyagesGroups]
  );

  return (
    <>
      <Box position="relative">
        <Table
          customStyle={tableTitlePaddingLeft}
          loading={loading}
          mode={theme.mode}
          data={voyageSearchResults?.length ? voyageSearchResults : []}
          columns={columns}
          options={options}
          title={
            <Box>
              <Typography>{title}</Typography>
              <Typography fontSize="12px">
                Display voyage: {voyageSearchResults?.length ?? 0}
              </Typography>
            </Box>
          }
        />
        {hasSelectedVessels && (
          <Box
            sx={{
              p: 2,
              background: ({ palette }) =>
                hasSelectedVessels ? palette.primary.main : palette.background.paper,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%'
            }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button
                sx={{
                  color: ({ palette }) => palette.neutral.primary,
                  borderColor: ({ palette }) => palette.neutral.primary,
                  '&:hover': {
                    borderColor: ({ palette }) => palette.neutral.primary
                  }
                }}
                onClick={({ currentTarget }) => {
                  setAnchorEl(currentTarget);
                }}
                variant="outlined"
                disabled={isAddingVoyagesToGroup}
                size="small">
                + add to my voyage group
              </Button>
              <Typography color={({ palette }) => palette.neutral.primary} variant="body2">
                {selectedVessels.length} selected
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      <AddDestinationModal
        open={vesselsToAddDestination.length > 0}
        onClose={() => setVesselsToAddDestination([])}
        vesselsToAddDestination={vesselsToAddDestination}
        isAddingDestination={isUpdatingVoyages}
        onUpdate={async (updatedVoyages) => {
          await updateVoyages({
            targetGroupId: voyageGroupIdToAddDestinationRef.current,
            updatedVoyages
          });
          voyageGroupIdToAddDestinationRef.current = undefined;
          setVesselsToAddDestination([]);
        }}
      />
      {showCreateVoyageGroupPopup && (
        <CreateVoyageGroupPopup
          open
          setModalOpen={setShowCreateVoyageGroupPopup}
          existingGroups={filteredGroups}
          isSubmitDisabled={isCreatingVoyageGroup}
          onSubmit={async (newVoyageGroupName, clearInput) => {
            const newGroup = await createVoyageGroup({
              newVoyageGroupName,
              type: voyageGroupType
            });
            clearInput?.();
            setShowCreateVoyageGroupPopup(false);
            handleAddVoyagesToGroup(newGroup.id);
          }}
        />
      )}
      <Popover
        onClose={() => {
          setAnchorEl(undefined);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}>
        <AddToVoyageGroupPopup
          savedGroups={filteredGroups}
          isSavingVoyageToGroup={isAddingVoyagesToGroup}
          isLoadingVoyageGroups={isLoadingVoyageGroups}
          onAddVoyageToGroup={handleAddVoyagesToGroup}
          onCreateANewGroup={() => {
            setShowCreateVoyageGroupPopup(true);
          }}
        />
      </Popover>
    </>
  );
}

VoyagesTable.propTypes = {
  title: PropTypes.string,
  vessels: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string.isRequired,
  voyageSearchResults: PropTypes.arrayOf(PropTypes.object),
  columnLabels: PropTypes.object,
  initialSortColumn: PropTypes.string
};

VoyagesTable.defaultProps = {
  title: '',
  vessel: [],
  loading: true,
  hideColumns: [],
  voyageSearchResults: [],
  columnLabels: {},
  initialSortColumn: ''
};

export default VoyagesTable;
