import React, { useState, useEffect } from 'react';
import { DateRangeMobi, Box, Grid, Stack } from '@esgian/esgianui';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';
import moment from 'moment';
import { OperatorMultiSelect } from '@components/Inputs';
import CanalTransitsTable from '@components/Sections/ShipAnalytics/CanalProfileSection/SubSections/CanalStatisticsSection/CanalTransitsTable';
import CanalTransitsTableOperator from './CanalTransitsTableOperator/CanalTransitTableOperator';
import CanalTransitsChart from '@components/Sections/ShipAnalytics/CanalProfileSection/SubSections/CanalStatisticsSection/CanalTransitsChart';
import CanalTransitsChartOperator from './CanalTransitsChartOperator/CanalTransitsChartOperator';
import GroupSelect from '@components/Inputs/GroupSelect';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getDateFormat, getOperatorType } from '@store/features';
import { useDynamicLookupQueries, useSegment } from '@hooks';
import { useQuery } from '@tanstack/react-query';
import { getCanalDepartures } from '@api';
import { useParams } from 'react-router-dom';
import { UTC_TIMESTAMP } from '@constants';
import CanalTransitsChartDirection from './CanalTransitsChartDirection/CanalTransitsChartDirection';
import CanalTransitsChartOpDir from './CanalTransitsChartOpDir/CanalTransitsChartOpDir';
import CanalTransitsTableDirection from './CanalTransitsTableDirection/CanalTransitsTableDirection';
import CanalTransitsTableOpDir from './CanalTransitsTableOpDir';
const groups = [
  { value: 'direction', label: 'Direction' },
  { value: 'operator', label: 'Operator' }
];
function CanalStatisticsSection() {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment());
  const [selectedOperators, setSelectedOperators] = useState(null);
  const [init, setInit] = useState(false);
  const { segment } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const params = useParams();

  const { segmentMinDate } = useSegment();
  const dateFormat = useSelector(getDateFormat);
  const {
    lookupOperatorsQuery: { data: lookupOperators }
  } = useDynamicLookupQueries();

  const updateFilterValue = (payload) => {
    setStartDate(payload.startDate);
    setEndDate(payload.endDate);
  };

  const handleFilterChange = (payload) => {
    if (
      payload.selectedOperators === null ||
      payload.selectedOperators.length === lookupOperators?.length
    ) {
      // All operators selected, set to null to get vessels without operators
      setSelectedOperators(lookupOperators);
    } else {
      setSelectedOperators(payload.selectedOperators);
    }
  };

  const canalDeparturesQuery = useQuery({
    queryKey: [
      'canal',
      'departures',
      segment.id,
      operatorType,
      commercialCategoryType,
      startDate,
      endDate,
      selectedOperators
    ],
    enabled: !!startDate && !!endDate,
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      const body = {
        start: moment(queryKey[5]).format(UTC_TIMESTAMP),
        end: moment(queryKey[6]).add(1, 'day').format(UTC_TIMESTAMP),
        operatorTypeId: queryKey[3],
        segmentTypeId: queryKey[2],
        commercialCategoryId: queryKey[4],
        canalIds: [params.canalId],
        operatorIds:
          selectedOperators?.length === lookupOperators?.length
            ? null
            : selectedOperators?.map(({ id }) => id),
        includePortCalls: false
      };
      return getCanalDepartures(body, signal)
        .then((results) => results)
        .catch(() => []);
    }
  });

  useEffect(() => {
    if (lookupOperators?.length && selectedOperators === null) {
      setSelectedOperators(lookupOperators);
    }
  }, [lookupOperators]);

  return (
    <Grid container spacing={2}>
      <Grid item className={'section-filter'}>
        <Stack direction={'row'} spacing={4} justifyContent={'space-between'}>
          <Box sx={{ width: '8em', flex: 1 }}>
            <DateRangeMobi
              sx={getMonthDateRangePickerSx(startDate, endDate, 'Period*')}
              onClose={({ value }) => {
                const { start, end } = getStartEndMonth(value, dateFormat);
                updateFilterValue({
                  startDate: start,
                  endDate: end
                });
              }}
              dateFormat={dateFormat}
              minStartDate={segmentMinDate}
              disableFuture
              startDate={moment(startDate, dateFormat)}
              endDate={moment(endDate, dateFormat)}
              placeholder={'Period'}
              dateWheels={'MMM YYYY'}
            />
          </Box>
          <Box sx={{ width: '15em', flex: 1 }}>
            <OperatorMultiSelect
              helperText={
                !selectedOperators || selectedOperators.length === 0 ? 'No operators selected' : ''
              }
              error={!selectedOperators || selectedOperators.length === 0}
              placeholder={'Select Operators...'}
              selectedOperators={selectedOperators || []}
              operatorsList={lookupOperators || []}
              sx={{ width: '100%' }}
              handleChange={(values) => {
                handleFilterChange({ selectedOperators: values });
              }}
            />
          </Box>
          <Box sx={{ width: '15em', flex: 1 }}>
            <GroupSelect
              groupList={groups}
              handleChange={(groups) => {
                setSelectedGroups(groups);
              }}
              selectedGroup={init ? selectedGroups : []}
              isRequired={true}
              sx={{ width: '100%' }}
            />
          </Box>
        </Stack>
      </Grid>
      {selectedGroups.length === 0 && (
        <Grid item xs={12}>
          <CanalTransitsChart
            canalTransits={canalDeparturesQuery.data}
            loading={canalDeparturesQuery.isFetching}
            selectedGroups={selectedGroups}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Weekly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 1 && selectedGroups.includes('Operator') && (
        <Grid item xs={12}>
          <CanalTransitsChartOperator
            canalTransits={canalDeparturesQuery.data}
            loading={canalDeparturesQuery.isFetching}
            selectedGroups={selectedGroups}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Weekly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 1 && selectedGroups.includes('Direction') && (
        <Grid item xs={12}>
          <CanalTransitsChartDirection
            canalTransits={canalDeparturesQuery.data}
            loading={canalDeparturesQuery.isFetching}
            selectedGroups={selectedGroups}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Weekly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 2 && (
        <Grid item xs={12}>
          <CanalTransitsChartOpDir
            canalTransits={canalDeparturesQuery.data}
            loading={canalDeparturesQuery.isFetching}
            selectedGroups={selectedGroups}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Weekly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 0 && (
        <Grid item xs={12} id={'weekly-canal-transits-table'}>
          <CanalTransitsTable
            canalTransits={canalDeparturesQuery.data || []}
            loading={canalDeparturesQuery.isFetching}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Weekly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 1 && selectedGroups.includes('Operator') && (
        <Grid item xs={12} id={'weekly-canal-transits-table-opr'}>
          <CanalTransitsTableOperator
            canalTransits={canalDeparturesQuery.data || []}
            loading={canalDeparturesQuery.isFetching}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Weekly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 1 && selectedGroups.includes('Direction') && (
        <Grid item xs={12} id={'weekly-canal-transits-table-dir'}>
          <CanalTransitsTableDirection
            canalTransits={canalDeparturesQuery.data || []}
            loading={canalDeparturesQuery.isFetching}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Weekly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 2 && (
        <Grid item xs={12} id={'weekly-canal-transits-table-opdir'}>
          <CanalTransitsTableOpDir
            canalTransits={canalDeparturesQuery.data || []}
            loading={canalDeparturesQuery.isFetching}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Weekly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 0 && (
        <Grid item xs={12}>
          <CanalTransitsChart
            canalTransits={canalDeparturesQuery.data}
            loading={canalDeparturesQuery.isFetching}
            selectedGroups={selectedGroups}
            timeFrame={'monthly'}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Monthly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 1 && selectedGroups.includes('Operator') && (
        <Grid item xs={12}>
          <CanalTransitsChartOperator
            canalTransits={canalDeparturesQuery.data}
            loading={canalDeparturesQuery.isFetching}
            selectedGroups={selectedGroups}
            timeFrame={'monthly'}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Monthly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 1 && selectedGroups.includes('Direction') && (
        <Grid item xs={12}>
          <CanalTransitsChartDirection
            canalTransits={canalDeparturesQuery.data}
            loading={canalDeparturesQuery.isFetching}
            selectedGroups={selectedGroups}
            timeFrame={'monthly'}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Monthly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 2 && (
        <Grid item xs={12}>
          <CanalTransitsChartOpDir
            canalTransits={canalDeparturesQuery.data}
            loading={canalDeparturesQuery.isFetching}
            selectedGroups={selectedGroups}
            timeFrame={'monthly'}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            fileName={`Monthly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}

      {selectedGroups.length === 0 && (
        <Grid item xs={12} id={'monthly-canal-transits-table'}>
          <CanalTransitsTable
            canalTransits={canalDeparturesQuery.data || []}
            loading={canalDeparturesQuery.isFetching}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            timeFrame={'monthly'}
            fileName={`Monthly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 1 && selectedGroups.includes('Operator') && (
        <Grid item xs={12} id={'monthly-canal-transits-table-opr'}>
          <CanalTransitsTableOperator
            canalTransits={canalDeparturesQuery.data || []}
            loading={canalDeparturesQuery.isFetching}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            timeFrame={'monthly'}
            fileName={`Monthly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 1 && selectedGroups.includes('Direction') && (
        <Grid item xs={12} id={'monthly-canal-transits-table-dir'}>
          <CanalTransitsTableDirection
            canalTransits={canalDeparturesQuery.data || []}
            loading={canalDeparturesQuery.isFetching}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            timeFrame={'monthly'}
            fileName={`Monthly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
      {selectedGroups.length === 2 && (
        <Grid item xs={12} id={'monthly-canal-transits-table-opdir'}>
          <CanalTransitsTableOpDir
            canalTransits={canalDeparturesQuery.data || []}
            loading={canalDeparturesQuery.isFetching}
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            timeFrame={'monthly'}
            fileName={`Monthly-Canal-Transits-${moment(startDate).format(dateFormat)}-${moment(
              endDate
            ).format(dateFormat)}`}
          />
        </Grid>
      )}
    </Grid>
  );
}

CanalStatisticsSection.propTypes = {};

export default CanalStatisticsSection;
