import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, ToolTip } from '@esgian/esgianui';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

const DashboardActivityMapZoomControls = ({ onZoomIn, onZoomOut }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: '16px',
        bottom: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        zIndex: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: 1,
        border: '0.5px solid var(--dark-mode-border-divider-default, #7B7B7B)',
        p: 1
      }}>
      <ToolTip title="Zoom In">
        <IconButton
          d
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '4px',
            border: `0.5px solid var(--dark-mode-border-divider-default, #7B7B7B)`,
            height: '32px',
            color: 'white',
            width: '32px'
          }}
          onClick={onZoomIn}>
          <AddIcon />
        </IconButton>
      </ToolTip>
      <ToolTip title="Zoom Out">
        <IconButton
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '4px',
            border: `0.5px solid var(--dark-mode-border-divider-default, #7B7B7B)`,
            height: '32px',
            color: 'white',
            width: '32px'
          }}
          onClick={onZoomOut}>
          <RemoveIcon />
        </IconButton>
      </ToolTip>
    </Box>
  );
};

DashboardActivityMapZoomControls.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired
};

export default DashboardActivityMapZoomControls;
