import moment from 'moment';
import { SIDE_PANEL_TYPES } from './constants';
import { SEGMENT, OPERATOR_TYPE, COMMERCIAL_CATEGORY_TYPE } from '@constants';
import { getDashboardActivityMapFilterInitialValues } from '../../../Maps/DashboardActivityMap/DashboardActivityMapActionWindow/DashboardActivityMapFilter';

export const QUICK_SEARCH_ITEM_TYPE = {
  OPERATOR: 'operator',
  OWNER: 'owner',
  VESSEL: 'vessel',
  COUNTRY: 'country',
  PORT: 'port'
};

const ZOOM_LEVELS = {
  SINGLE_LOCATION: 8, // For ports and vessels
  COUNTRY: 6 // For countries
};

export const applyQuickSearchFilter = (
  vesselsLatestPosition = [],
  lookupPorts = [],
  quickSearchItemType,
  quickSearchItemId
) => {
  const defaultResult = {
    quickSearchFilteredVessels: vesselsLatestPosition,
    quickSearchFilteredPorts: lookupPorts,
    zoomLocation: null
  };

  if (!quickSearchItemType || !quickSearchItemId) {
    return defaultResult;
  }

  const searchId = +quickSearchItemId;

  const vesselFilters = {
    [QUICK_SEARCH_ITEM_TYPE.VESSEL]: (vessel) => vessel.imo === searchId,
    [QUICK_SEARCH_ITEM_TYPE.OPERATOR]: (vessel) => vessel.operatorId === searchId,
    [QUICK_SEARCH_ITEM_TYPE.OWNER]: (vessel) => vessel.ownerId === searchId
  };

  const portFilters = {
    [QUICK_SEARCH_ITEM_TYPE.COUNTRY]: (port) => port.country.id === searchId,
    [QUICK_SEARCH_ITEM_TYPE.PORT]: (port) => port.id === searchId
  };

  let filteredVessels = vesselsLatestPosition;
  let filteredPorts = lookupPorts;
  let zoomLocation = null;

  // Handle vessel search
  if (quickSearchItemType === QUICK_SEARCH_ITEM_TYPE.VESSEL) {
    filteredVessels = vesselsLatestPosition.filter(vesselFilters[quickSearchItemType]);
    const targetVessel = filteredVessels[0];
    if (targetVessel) {
      zoomLocation = {
        lat: targetVessel.lat,
        lng: targetVessel.long,
        zoom: ZOOM_LEVELS.SINGLE_LOCATION
      };
    }
  }
  // Handle port search
  else if (quickSearchItemType === QUICK_SEARCH_ITEM_TYPE.PORT) {
    filteredPorts = lookupPorts.filter(portFilters[quickSearchItemType]);
    const targetPort = filteredPorts[0];
    if (targetPort) {
      zoomLocation = {
        lat: targetPort.cpointlat,
        lng: targetPort.cpointlon,
        zoom: ZOOM_LEVELS.SINGLE_LOCATION
      };
    }
    filteredVessels = vesselsLatestPosition; // Show all vessels
  }
  // Handle country search
  else if (quickSearchItemType === QUICK_SEARCH_ITEM_TYPE.COUNTRY) {
    filteredPorts = lookupPorts.filter(portFilters[quickSearchItemType]);
    if (filteredPorts.length) {
      // Calculate country bounds from ports
      const lats = filteredPorts.map((p) => p.cpointlat);
      const lngs = filteredPorts.map((p) => p.cpointlon);
      zoomLocation = {
        lat: (Math.min(...lats) + Math.max(...lats)) / 2,
        lng: (Math.min(...lngs) + Math.max(...lngs)) / 2,
        zoom: ZOOM_LEVELS.COUNTRY
      };
    }
    filteredVessels = vesselsLatestPosition; // Show all vessels
  }
  // Handle operator/owner search
  else if (vesselFilters[quickSearchItemType]) {
    filteredVessels = vesselsLatestPosition.filter(vesselFilters[quickSearchItemType]);
  }

  return {
    quickSearchFilteredVessels: filteredVessels,
    quickSearchFilteredPorts: filteredPorts,
    zoomLocation
  };
};

export const applyAdvancedFilter = ({
  vesselsLatestPosition = [],
  lookupPorts,
  activeFilters,
  isInboundVesselsFiltering = false,
  segment = null
}) => {
  if (!activeFilters) {
    return {
      advancedSearchFilteredVessels: vesselsLatestPosition,
      advancedSearchFilteredPorts: lookupPorts,
      zoomLocation: null
    };
  }

  const isRoRo = segment?.id === SEGMENT.RoRo.id;

  const dashboardActivityMapFilterInitialValues =
    getDashboardActivityMapFilterInitialValues(isRoRo);

  const locations = (activeFilters.selectedLocations || []).filter(Boolean);

  let filteredPorts = lookupPorts;
  let zoomLocation = null; // We'll keep this null for inbound vessel filtering

  let filteredVessels = vesselsLatestPosition;

  // Apply vessel filters
  filteredVessels = filteredVessels.filter((singleVessel) => {
    if (!singleVessel) return false;

    // Selected vessels filter
    if (activeFilters.selectedVessels?.length) {
      if (!activeFilters.selectedVessels.map((item) => item.id).includes(singleVessel.imo)) {
        return false;
      }
    }

    // Selected owners filter
    if (activeFilters.selectedOwners?.length) {
      if (
        !activeFilters.selectedOwners
          .map((item) => item.ownerShortName)
          .includes(singleVessel.ownerShortName)
      ) {
        return false;
      }
    }

    // Operator Type or Commercial Category filter
    if (
      isRoRo &&
      typeof activeFilters?.operatorTypeId === 'number' &&
      typeof singleVessel?.operatorTypeId === 'number' &&
      activeFilters?.operatorTypeId !== OPERATOR_TYPE.ALL
    ) {
      if (activeFilters?.operatorTypeId !== singleVessel?.operatorTypeId) {
        return false;
      }
    }
    if (
      !isRoRo &&
      typeof activeFilters?.commercialCategoryId === 'number' &&
      typeof singleVessel?.commercialCategoryId === 'number' &&
      activeFilters?.commercialCategoryId !== COMMERCIAL_CATEGORY_TYPE.ALL
    ) {
      if (activeFilters?.commercialCategoryId !== singleVessel?.commercialCategoryId) {
        return false;
      }
    }

    // Capacity filters
    if (isRoRo) {
      const carCapacity = singleVessel?.carCapacity;
      const carCapacityFilterActive =
        activeFilters.minCarCapacity !== dashboardActivityMapFilterInitialValues.minCarCapacity ||
        activeFilters.maxCarCapacity !== dashboardActivityMapFilterInitialValues.maxCarCapacity;
      if (
        carCapacityFilterActive &&
        (typeof carCapacity !== 'number' ||
          carCapacity < activeFilters.minCarCapacity ||
          carCapacity > activeFilters.maxCarCapacity)
      ) {
        return false;
      }
    } else {
      const liftingCapacity = singleVessel?.liftingCapacity;
      const liftingCapacityFilterActive =
        activeFilters.minLiftingCapacity !==
          dashboardActivityMapFilterInitialValues.minLiftingCapacity ||
        activeFilters.maxLiftingCapacity !==
          dashboardActivityMapFilterInitialValues.maxLiftingCapacity;
      if (
        liftingCapacityFilterActive &&
        (typeof liftingCapacity !== 'number' ||
          liftingCapacity < activeFilters.minLiftingCapacity ||
          liftingCapacity > activeFilters.maxLiftingCapacity)
      ) {
        return false;
      }
    }

    // Build year filter
    const buildYear = singleVessel?.buildYear;
    const buildYearFilterActive =
      activeFilters.minBuildYear !== dashboardActivityMapFilterInitialValues.minBuildYear ||
      activeFilters.maxBuildYear !== dashboardActivityMapFilterInitialValues.maxBuildYear;
    if (
      buildYearFilterActive &&
      (typeof buildYear !== 'number' ||
        buildYear < activeFilters.minBuildYear ||
        buildYear > activeFilters.maxBuildYear)
    ) {
      return false;
    }

    // Deadweight filter
    const deadweight = singleVessel?.deadweight;
    const deadweightFilterActive =
      activeFilters.minDeadweight !== dashboardActivityMapFilterInitialValues.minDeadweight ||
      activeFilters.maxDeadweight !== dashboardActivityMapFilterInitialValues.maxDeadweight;
    if (
      deadweightFilterActive &&
      (typeof deadweight !== 'number' ||
        deadweight < activeFilters.minDeadweight ||
        deadweight > activeFilters.maxDeadweight)
    ) {
      return false;
    }

    // Gross tonnage filter
    const grossTonnage = singleVessel?.grossTonnage;
    const grossTonnageFilterActive =
      activeFilters.minGrossTonnage !== dashboardActivityMapFilterInitialValues.minGrossTonnage ||
      activeFilters.maxGrossTonnage !== dashboardActivityMapFilterInitialValues.maxGrossTonnage;
    if (
      grossTonnageFilterActive &&
      (typeof grossTonnage !== 'number' ||
        grossTonnage < activeFilters.minGrossTonnage ||
        grossTonnage > activeFilters.maxGrossTonnage)
    ) {
      return false;
    }

    // Max Draught filter
    const vesselMaxDraught = singleVessel?.vesselDraught;
    const draughtFilterActive =
      activeFilters.minDraught !== dashboardActivityMapFilterInitialValues.minDraught ||
      activeFilters.maxDraught !== dashboardActivityMapFilterInitialValues.maxDraught;
    if (
      draughtFilterActive &&
      (typeof vesselMaxDraught !== 'number' ||
        vesselMaxDraught < activeFilters.minDraught ||
        vesselMaxDraught > activeFilters.maxDraught)
    ) {
      return false;
    }

    // Draught Percentage filter
    const vesselMaxDraughtPercentage = singleVessel?.percentDraught;
    const draughtPercentageFilterActive =
      activeFilters.minDraughtPercentage !==
        dashboardActivityMapFilterInitialValues.minDraughtPercentage ||
      activeFilters.maxDraughtPercentage !==
        dashboardActivityMapFilterInitialValues.maxDraughtPercentage;
    if (
      draughtPercentageFilterActive &&
      (typeof vesselMaxDraughtPercentage !== 'number' ||
        vesselMaxDraughtPercentage < activeFilters.minDraughtPercentage ||
        vesselMaxDraughtPercentage > activeFilters.maxDraughtPercentage)
    ) {
      return false;
    }

    // No. of Cranes filter
    const noOfCranesFilterActive =
      activeFilters.minNoOfCranes !== dashboardActivityMapFilterInitialValues.minNoOfCranes ||
      activeFilters.maxNoOfCranes !== dashboardActivityMapFilterInitialValues.maxNoOfCranes;
    const vesselNoOfCranes = singleVessel?.numCranes;
    if (
      noOfCranesFilterActive &&
      (typeof vesselNoOfCranes !== 'number' ||
        vesselNoOfCranes < activeFilters.minNoOfCranes ||
        vesselNoOfCranes > activeFilters.maxNoOfCranes)
    ) {
      return false;
    }

    // Beam filter
    const vesselBeam = singleVessel?.mouldedBreadth;
    const beamFilterActive =
      activeFilters.minBeam !== dashboardActivityMapFilterInitialValues.minBeam ||
      activeFilters.maxBeam !== dashboardActivityMapFilterInitialValues.maxBeam;
    if (
      beamFilterActive &&
      (typeof vesselBeam !== 'number' ||
        vesselBeam < activeFilters.minBeam ||
        vesselBeam > activeFilters.maxBeam)
    ) {
      return false;
    }

    // Length filter
    const vesselLength = singleVessel?.loa;
    const lengthFilterActive =
      activeFilters.minLength !== dashboardActivityMapFilterInitialValues.minLength ||
      activeFilters.maxLength !== dashboardActivityMapFilterInitialValues.maxLength;
    if (
      lengthFilterActive &&
      (typeof vesselLength !== 'number' ||
        vesselLength < activeFilters.minLength ||
        vesselLength > activeFilters.maxLength)
    ) {
      return false;
    }

    return true;
  });

  if (isInboundVesselsFiltering) {
    // Filter vessels by ETA date range if specified
    if (activeFilters.selectedDate?.startDate && activeFilters.selectedDate?.endDate) {
      filteredVessels = filteredVessels.filter((vessel) => {
        const vesselEta = moment.utc(vessel.eta);
        const startDate = moment.utc(activeFilters.selectedDate.startDate);
        const endDate = moment.utc(activeFilters.selectedDate.endDate);

        return vesselEta.isBetween(startDate, endDate, 'day', '[]');
      });
    }

    // Filter ports based on selected locations and vessels with matching ETAs
    filteredPorts = lookupPorts.filter((port) => {
      const isMatchLocation = locations.some((location) => {
        if (location.type === 'Country') {
          return port.country.id === location.id;
        }
        return port.id === location.id;
      });

      const hasInboundVessel = filteredVessels.some(
        (vessel) => vessel.destinationPortId === port.id
      );

      return isMatchLocation && hasInboundVessel;
    });

    const filteredPortIds = new Set(filteredPorts.map((port) => port.id));
    filteredVessels = filteredVessels.filter((vessel) =>
      filteredPortIds.has(vessel.destinationPortId)
    );
  }

  return {
    advancedSearchFilteredVessels: filteredVessels,
    advancedSearchFilteredPorts: filteredPorts,
    zoomLocation
  };
};

export const searchItemTypeToSidePanelType = (itemType) => {
  switch (itemType) {
    case QUICK_SEARCH_ITEM_TYPE.OPERATOR:
      return SIDE_PANEL_TYPES.OPERATOR_DETAILS;
    case QUICK_SEARCH_ITEM_TYPE.OWNER:
      return SIDE_PANEL_TYPES.OWNER_DETAILS;
    case QUICK_SEARCH_ITEM_TYPE.VESSEL:
      return SIDE_PANEL_TYPES.VESSEL_DETAILS;
    case QUICK_SEARCH_ITEM_TYPE.COUNTRY:
      return SIDE_PANEL_TYPES.COUNTRY_DETAILS;
    case QUICK_SEARCH_ITEM_TYPE.PORT:
      return SIDE_PANEL_TYPES.PORT_DETAILS;
    default:
      return SIDE_PANEL_TYPES.INSIGHT;
  }
};
