import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  fetchLookupOperators,
  fetchLookupPorts,
  fetchOperatorVesselHierarchy,
  fetchVessels,
  fetchOwnerOperatorVesselHierarchy,
  getVesselDetailsByFilters
} from '@api';
import moment from 'moment/moment';
import { getGeneralSlice } from '@store/features';
import { DATE_FORMAT } from '@constants';

function compareOperator(a, b) {
  if (a.operatorLongName < b.operatorLongName) {
    return -1;
  }
  if (a.operatorLongName > b.operatorLongName) {
    return 1;
  }
  return 0;
}
function comparePort(a, b) {
  if (a.portName < b.portName) {
    return -1;
  }
  if (a.portName > b.portName) {
    return 1;
  }
  return 0;
}
function sortOperatorVesselHierarchy(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
export const useDynamicLookupQueries = () => {
  const queryClient = useQueryClient(); // Access queryClient instance
  const { segment, operatorType, commercialCategoryType } = useSelector(getGeneralSlice);

  const lookupPortsQuery = useQuery({
    queryKey: ['lookupPorts', { SegmentTypeId: segment.id }],
    enabled: !!segment,
    placeholderData: [],
    queryFn: ({ signal }) => {
      return fetchLookupPorts({ SegmentTypeId: segment.id }, signal)
        .then((resp) => resp.sort(comparePort))
        .catch(() => []);
    }
  });

  const lookupOperatorVesselHierarchyQuery = useQuery({
    queryKey: ['OperatorVesselHierarchy', segment.id],
    enabled: !!segment,
    placeholderData: [],
    queryFn: ({ signal }) => {
      return fetchOperatorVesselHierarchy(signal, segment.id)
        .then((resp) => {
          const { operatorAssetDetailsList = [] } = resp;
          return operatorAssetDetailsList.sort(sortOperatorVesselHierarchy);
        })
        .catch(() => {
          return [];
        });
    }
  });

  const lookupVesselsQuery = useQuery({
    queryKey: ['lookupVessels', segment.id],
    enabled: !!segment,
    placeholderData: [],
    queryFn: ({ signal }) => {
      return fetchVessels(signal, segment.id)
        .then((resp) => {
          const { assetDetailsList = [] } = resp;
          return assetDetailsList;
        })
        .catch(() => []);
    }
  });

  const lookupOperatorsQuery = useQuery({
    queryKey: [
      'lookupOperators',
      {
        segmentTypeIds: [segment.id],
        operatorTypeIds: [operatorType],
        commercialCategoryIds: [commercialCategoryType]
      }
    ],
    enabled: !!segment,
    placeholderData: [],
    queryFn: ({ queryKey, signal }) => {
      let body = {
        ...queryKey[1],
        operatorEndDate: moment().format(DATE_FORMAT),
        activeVessels: true
      };
      return fetchLookupOperators(body, signal)
        .then((response) => {
          let result = [];
          response.forEach((val) => {
            const { companyVesselAssets, company } = val;
            let validOpr;
            if (companyVesselAssets.length === 0) {
              validOpr = true;
            } else {
              validOpr = companyVesselAssets?.some((asset) => {
                const operatorInfo = asset?.operatorInformation;
                const endDate = operatorInfo?.endDate;
                return !endDate;
              });
            }
            if (validOpr) {
              result.push({
                id: company.id,
                operatorShortName: company.shortName,
                operatorLongName: company.longName
              });
            }
          });
          return result.sort(compareOperator) ?? [];
        })
        .catch(() => []);
    }
  });

  const lookupOwnerOperatorVesselHierarchyQuery = useQuery({
    queryKey: ['OwnerOperatorVesselHierarchy'],
    enabled: !!segment,
    placeholderData: [],
    queryFn: ({ signal }) => {
      return fetchOwnerOperatorVesselHierarchy(signal)
        .then((resp) => {
          const { ownerOperatorAssetDetailsList = [] } = resp;
          return ownerOperatorAssetDetailsList.sort(sortOperatorVesselHierarchy);
        })
        .catch(() => {
          return [];
        });
    }
  });

  const vesselsDetailsQuery = useQuery({
    queryKey: ['vesselsDetails', segment.id],
    queryFn: async ({ signal }) => {
      const response = await getVesselDetailsByFilters(
        { segmentTypeIds: [segment.id], activeVessels: true },
        signal
      );
      return response?.vesselInformation;
    },
    refetchOnMount: false
  });

  const resetDynamicLookupQueries = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['lookupOperators'] });
    await queryClient.invalidateQueries({ queryKey: ['lookupPorts'] });
    await queryClient.invalidateQueries({ queryKey: ['OperatorVesselHierarchy'] });
    await queryClient.invalidateQueries({ queryKey: ['lookupVessels'] });
    await queryClient.invalidateQueries({ queryKey: ['OwnerOperatorVesselHierarchy'] });
  }, [queryClient]);

  return {
    lookupPortsQuery,
    lookupOperatorVesselHierarchyQuery,
    lookupVesselsQuery,
    lookupOperatorsQuery,
    lookupOwnerOperatorVesselHierarchyQuery,
    resetDynamicLookupQueries,
    vesselsDetailsQuery
  };
};
