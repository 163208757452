import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Grid, Link, PageHeader, Typography } from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import { fetchVesselStats } from '@api';
import OperatorsOverviewSection from '@components/Sections/ShipAnalytics/OperatorsOverviewSection';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@constants';
import { useSegment } from '@hooks';
import { CommercialCategoryTypeSelect, OperatorTypeSelect } from '@components/Inputs';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment/moment';

function OperatorsOverviewPage() {
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);
  const { segment, isRoRo, uriExt } = useSegment();
  const navigate = useNavigate();
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [init, setInit] = useState(false);
  const routeChange = (path) => {
    navigate(path);
  };
  const operatorsOverviewQuery = useQuery({
    queryKey: [
      'kpiStats',
      {
        operatorType: operatorType,
        commercialCategoryType: commercialCategoryType,
        segment: segment
      }
    ],
    enabled: true,
    placeholderData: null,
    queryFn: ({ signal }) => {
      let start = moment().subtract(1, 'year').startOf('month');
      let end = moment();
      return fetchVesselStats(
        signal,
        [segment.id],
        [],
        [],
        isRoRo ? [operatorType] : [],
        isRoRo ? [] : [commercialCategoryType],
        [],
        start.clone().format(DATE_FORMAT),
        end.clone().format(DATE_FORMAT)
      )
        .then((response) => {
          if (!response.length) return null;
          const aggregatedData = aggregateKpiData(response);
          const tableData = aggregatedTableData(response);
          return {
            aggregatedData: aggregatedData,
            tableData: tableData
          };
        })
        .catch(() => {
          return null;
        });
    }
  });
  const tableAggregatedData = operatorsOverviewQuery.data?.tableData;
  useEffect(() => {
    if (tableAggregatedData?.length) {
      setSelectedOperators(tableAggregatedData);
      setInit(true);
    }
  }, [tableAggregatedData, init]);
  function aggregateKpiData(response) {
    const vesselData = response[0]?.vesselTimeSeries?.[12] || {};
    const capacity = vesselData.capacity || {};
    let ageInfo = vesselData.ageInformation || {};

    return {
      fleetSize: vesselData.numVessels || 0,
      averageAge: ageInfo.averageAge || 0,
      averageCapacity: isRoRo ? capacity.averageCEU : capacity.averageDeadweightTonnage || 0,
      totalCapacity: isRoRo ? capacity.totalCEU : capacity.totalDeadweightTonnage || 0
    };
  }
  function aggregatedTableData(response) {
    let operatorsData = response.slice(1);

    return operatorsData.map((operator) => ({
      operatorLongName: operator?.operatorLongName,
      operatorShortName: operator?.operatorShortName,
      countryName: operator?.countryName,
      countryId: operator?.countryId,
      countryCode: operator?.isoAlpha2,
      id: operator?.operatorId,
      avgAge: operator?.vesselTimeSeries?.[12]?.ageInformation?.averageAge,
      avgCapacity:
        operator?.vesselTimeSeries?.[12]?.capacity?.[
          isRoRo ? 'averageCEU' : 'averageDeadweightTonnage'
        ],
      fleetSize: operator?.vesselTimeSeries?.[12]?.numVessels,
      totalCapacity:
        operator?.vesselTimeSeries?.[12]?.capacity?.[isRoRo ? 'totalCEU' : 'totalDeadweightTonnage']
    }));
  }
  const aggregatedData = operatorsOverviewQuery.data?.aggregatedData;
  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <PageHeader
          id={'lbl-operator-overview'}
          title={'Operators Overview'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                id="home-breadcrumb"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange(`${uriExt}/commercial-analytics`)}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                id="ship-analysis-breadcrumb"
                onClick={() => routeChange(`${uriExt}/commercial-analytics`)}>
                Commercial Analytics
              </Link>
              <Typography color="primary" id="operator-overview">
                Operators Overview
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>

      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>
      <Grid item xs={12}>
        <OperatorsOverviewSection
          operators={tableAggregatedData}
          operatorsKpi={aggregatedData}
          loading={operatorsOverviewQuery.isFetching}
        />
      </Grid>
    </Grid>
  );
}

OperatorsOverviewPage.propTypes = {};

OperatorsOverviewPage.defaultProps = {};

export default OperatorsOverviewPage;
