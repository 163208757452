import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Map, { Source, Layer, Popup } from 'react-map-gl';
import {
  createVesselIcons,
  createVesselGeoJson,
  createPortGeoJson,
  createPopupContent,
  createPortIcons
} from './utils/map';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useMapState } from './hooks/useMapState';
import { FILTER_TYPES, SIDE_PANEL_TYPES, VESSEL_DEFAULT_ICON_COLOR } from './constants';
import { useTimezone } from '@hooks/useTimezone';
import DashboardActivityMapZoomControls from '../../../Maps/DashboardActivityMap/DashboardActivityMapZoomControls';

const DashboardActivityMapComponent = ({
  themeMode,
  onMapCreated,
  filteredVessels,
  filteredPorts,
  zoomLocation,
  isFleetFilterApplicable,
  isInboundFilterApplicable
}) => {
  const {
    handlePortSelect,
    handleVesselSelect,
    mapSettings,
    filterType,
    sidePanelType,
    sidePanelItemId
  } = useMapState();
  const { getTimeZoneDisplay } = useTimezone();
  const [selectedVessel, setSelectedVessel] = React.useState(null);
  const [mapInstance, setMapInstance] = React.useState(null);

  const handleMapLoad = useCallback(
    (event) => {
      const mapInstance = event.target;
      createVesselIcons(mapInstance);
      createPortIcons(mapInstance);
      onMapCreated?.(mapInstance);
      setMapInstance(mapInstance);
    },
    [onMapCreated]
  );

  const flyToLocation = useCallback(
    (longitude, latitude, zoom = 8) => {
      mapInstance?.flyTo({
        center: [longitude, latitude],
        zoom,
        transitionDuration: 2000,
        curve: 1.4
      });
    },
    [mapInstance]
  );

  useEffect(() => {
    if (zoomLocation) {
      flyToLocation(zoomLocation.lng, zoomLocation.lat, zoomLocation.zoom);
    } else {
      flyToLocation(0, 0, 1);
    }
  }, [zoomLocation?.lng, zoomLocation?.lat, zoomLocation?.zoom, flyToLocation]);

  useEffect(() => {
    if (sidePanelType === SIDE_PANEL_TYPES.VESSEL_DETAILS && filterType === FILTER_TYPES.FLEET) {
      const vesselPosition = filteredVessels.find((vessel) => vessel.imo === +sidePanelItemId);

      if (vesselPosition) {
        flyToLocation(vesselPosition.long, vesselPosition.lat, 4);
      } else {
        flyToLocation(0, 0, 1);
      }
    }
  }, [sidePanelType, sidePanelItemId, filteredVessels, flyToLocation]);

  const vesselGeoJson = React.useMemo(() => {
    const isFilterApplied = isFleetFilterApplicable || isInboundFilterApplicable;

    const data = createVesselGeoJson(
      filteredVessels,
      isFilterApplied,
      mapSettings.showVesselsStatus
    );
    return data;
  }, [
    filteredVessels,
    mapSettings.showVesselsStatus,
    isFleetFilterApplicable,
    isInboundFilterApplicable
  ]);

  const portGeoJson = React.useMemo(() => {
    const data = createPortGeoJson(filteredPorts, filterType);
    return data;
  }, [filteredPorts, filterType]);

  const onClick = (event) => {
    const features = mapInstance.queryRenderedFeatures(event.point, {
      layers: ['vessels-layer', 'ports-layer']
    });
    const feature = features?.[0];

    if (feature && feature.layer.id === 'vessels-layer') {
      const vessel = feature.properties;
      setSelectedVessel(vessel);
      handleVesselSelect(vessel.imo);
    } else if (feature && feature.layer.id === 'ports-layer') {
      const port = feature.properties;
      handlePortSelect(port.id);
      setSelectedVessel(null);
    } else {
      setSelectedVessel(null);
    }
  };

  const shouldShowVesselColor =
    isFleetFilterApplicable || isInboundFilterApplicable || mapSettings.showVesselsStatus;

  const handleZoomIn = useCallback(() => {
    if (mapInstance) {
      const currentZoom = mapInstance.getZoom();
      mapInstance.easeTo({
        zoom: currentZoom + 1,
        duration: 300
      });
    }
  }, [mapInstance]);

  const handleZoomOut = useCallback(() => {
    if (mapInstance) {
      const currentZoom = mapInstance.getZoom();
      mapInstance.easeTo({
        zoom: currentZoom - 1,
        duration: 300
      });
    }
  }, [mapInstance]);

  return (
    <>
      <Map
        onLoad={handleMapLoad}
        mapStyle={`mapbox://styles/mapbox/${themeMode ? 'dark' : 'light'}-v11`}
        interactiveLayerIds={['vessels-layer', 'ports-layer']}
        cursor="pointer"
        projection="mercator"
        onClick={onClick}
        initialViewState={{
          longitude: -100,
          latitude: 40,
          zoom: 1.5
        }}>
        <Source
          id="vessels"
          type="geojson"
          data={
            mapSettings.showVessels ? vesselGeoJson : { type: 'FeatureCollection', features: [] }
          }
          generateId={true}>
          <Layer
            id="vessels-layer"
            type="symbol"
            interactive={true}
            layout={{
              'icon-image': ['get', 'iconImage'],
              'icon-size': 0.5,
              'icon-rotate': ['get', 'rotation'],
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
              'text-field': mapSettings.showVesselsNames ? ['get', 'vesselName'] : '',
              'text-anchor': 'top',
              'text-offset': [0, 1],
              'text-size': 10
            }}
            paint={{
              'icon-color': [
                'case',
                ['has', 'color'],
                ['get', 'color'],
                shouldShowVesselColor ? ['get', 'color'] : VESSEL_DEFAULT_ICON_COLOR
              ],
              'text-color': 'white',
              'text-halo-color': 'black',
              'text-halo-width': 1
            }}
          />
        </Source>

        <Source
          id="ports"
          type="geojson"
          data={
            mapSettings.showPorts
              ? portGeoJson
              : {
                  type: 'FeatureCollection',
                  features: []
                }
          }
          generateId={true}>
          <Layer
            id="ports-layer"
            type="symbol"
            layout={{
              'icon-image': ['get', 'iconImage'],
              'icon-size': 1,
              'icon-allow-overlap': true,
              'text-field': mapSettings.showPortNames ? ['get', 'portName'] : '',
              'text-anchor': 'top',
              'text-offset': [0, 1],
              'text-size': 12
            }}
            paint={{
              'text-color': 'white',
              'text-halo-color': 'black',
              'text-halo-width': 1,
              'icon-color': ['get', 'color']
            }}
          />
        </Source>

        {selectedVessel && (
          <Popup
            longitude={selectedVessel.long}
            latitude={selectedVessel.lat}
            anchor="top"
            className="popup-details"
            onClose={() => handleVesselSelect(null)}
            closeButton={false}
            closeOnClick={false}>
            {createPopupContent(selectedVessel, themeMode, getTimeZoneDisplay)}
          </Popup>
        )}
      </Map>
      <DashboardActivityMapZoomControls onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
    </>
  );
};

DashboardActivityMapComponent.propTypes = {
  zoomLocation: PropTypes.object,
  themeMode: PropTypes.bool,
  onMapCreated: PropTypes.func,
  filteredVessels: PropTypes.array,
  filteredPorts: PropTypes.array,
  isFleetFilterApplicable: PropTypes.bool,
  isInboundFilterApplicable: PropTypes.bool
};

export default DashboardActivityMapComponent;
