import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Table,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableCell,
  TableRow
} from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess, formatNumberReadable } from '@helpers';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType, getUser } from '@store/features';
import { useSegment, useTheme } from '@hooks';
import { useQuery } from '@tanstack/react-query';
import { GEOGRAPHICAL_TYPES } from '@constants';
import { getCongestionDetails } from '@api';
import { useParams } from 'react-router-dom';
import UnderlyingCanalStatsModal from '@components/Sections/ShipAnalytics/CanalProfileSection/UnderlyingCanalStatsModal';

function retrieveModalData(
  selectedYear,
  selectedCell,
  selectedOper,
  queriedData,
  isTotalRow = false
) {
  if (!queriedData) {
    return {};
  }

  let tempModalData = {};
  let monthStart, monthEnd;
  if (selectedCell === 14) {
    monthStart = moment().utc().year(selectedYear).month(0).startOf('month');
    monthEnd = moment().utc().year(selectedYear).month(11).endOf('month');
  } else {
    monthStart = moment()
      .utc()
      .year(selectedYear)
      .month(selectedCell - 2)
      .startOf('month');
    monthEnd = moment()
      .utc()
      .year(selectedYear)
      .month(selectedCell - 2)
      .endOf('month');
  }
  queriedData.forEach(
    ({
      startDate,
      operatorId,
      vesselName,
      imo,
      operatorLongName,
      endDate,
      anchorageWaitingTimeId
    }) => {
      const startMoment = moment.utc(startDate);
      const endMoment = moment(endDate);
      if (
        endMoment.isBefore(monthStart) ||
        startMoment.isAfter(monthEnd) ||
        (!isTotalRow && operatorId !== selectedOper && selectedOper !== 0)
      ) {
        return;
      }
      let actualStart = startMoment.isBefore(monthStart) ? monthStart : startMoment;
      let actualEnd = endMoment.isAfter(monthEnd) ? monthEnd : endMoment;
      let waitTime = parseFloat(moment.duration(actualEnd.diff(actualStart)).asHours().toFixed(2));
      tempModalData[`${imo}_${actualStart.format()}`] = {
        vesselName: vesselName,
        imo: imo,
        operatorLongName: operatorLongName,
        startDate: actualStart.format(),
        endDate: actualEnd.format(),
        waitTime: waitTime,
        year: selectedYear,
        period: (selectedOper !== 0 ? operatorLongName + ' ' : '') + actualStart.format('MMM'),
        operatorId: operatorId,
        anchorageId: anchorageWaitingTimeId,
        selectedCell: selectedCell
      };
    }
  );
  return tempModalData;
}

function MonthlyPortWaitingTable({
  loading,
  selectedMeasure,
  portCongestionData,
  yearOptions,
  setSelectedYear,
  selectedYear
}) {
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const { segment, segmentMinDate } = useSegment();
  const params = useParams();
  const [modalData, setModalData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  let operatorId = 0;
  let clickedCellInd = 0;

  const tableData = useMemo(() => {
    if (!portCongestionData) return [];
    const { operatorCongestions } = portCongestionData;
    let tempData = [];
    operatorCongestions?.map((operator) => {
      const { operatorId, years } = operator;
      if (operatorId === null) {
        return;
      }
      let tempOpr = { ...operator };
      years?.forEach(
        ({
          year,
          months,
          averageWaitingTimeDays,
          totalWaitingTimeDays,
          ceuCapacityInWaiting,
          dwtInWaiting,
          numWaitingEvents
        }) => {
          months?.forEach(
            ({
              start,
              totalWaitingTimeDays,
              averageWaitingTimeDays,
              ceuCapacityInWaiting,
              dwtInWaiting,
              numWaitingEvents
            }) => {
              tempOpr[`${moment(start).format('MMM_YYYY')}_days`] = totalWaitingTimeDays;
              tempOpr[`${moment(start).format('MMM_YYYY')}_hours`] = averageWaitingTimeDays;
              tempOpr[`${moment(start).format('MMM_YYYY')}_ceu`] = ceuCapacityInWaiting;
              tempOpr[`${moment(start).format('MMM_YYYY')}_dwt`] = dwtInWaiting;
              tempOpr[`${moment(start).format('MMM_YYYY')}_anchor_events`] = numWaitingEvents;
            }
          );
          tempOpr[`${year}hours`] = averageWaitingTimeDays;
          tempOpr[`${year}days`] = totalWaitingTimeDays;
          tempOpr[`${year}ceu`] = ceuCapacityInWaiting;
          tempOpr[`${year}dwt`] = dwtInWaiting;
          tempOpr[`${year}anchor_events`] = numWaitingEvents;
        }
      );
      tempData.push(tempOpr);
    });
    return tempData;
  }, [portCongestionData]);

  const tableColumns = useMemo(() => {
    if (!tableData?.length) return [];

    let months = [];
    for (let i = 0; i <= 11; i++) {
      months.push(moment(`01/01/${selectedYear}`).add(i, 'months').format('MMM YYYY'));
    }

    let tempColumns = [
      {
        name: 'operator',
        label: 'Operator',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'multiselect',
          setCellProps: () => {
            return {
              id: 'operator-table'
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'operator'} id={rowData[1]} name={dataIndex} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: {
          display: false,
          viewColumns: false,
          filter: false,
          download: false
        }
      }
    ];

    months.forEach((date) => {
      tempColumns.push({
        name: `${date.split(' ').join('_')}_${selectedMeasure?.unit
          .toLowerCase()
          .split(' ')
          .join('_')}`,
        label: date,
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            if (!dataIndex) return '-';
            let numDecimals = 0;
            let val = dataIndex;
            if (![3, 4].includes(selectedMeasure?.id)) {
              numDecimals = 2;
              if (selectedMeasure?.id === 1) {
                val = val !== null ? val * 24 : null;
              }
            }
            return parseFloat(val) ? formatNumberReadable(val?.toFixed(numDecimals)) : '-';
          }
        }
      });
    });

    const isAverage = selectedMeasure?.id === 1;

    tempColumns.push({
      name: `${selectedYear}${selectedMeasure?.unit.toLowerCase().split(' ').join('_')}`,
      label: isAverage ? 'Average' : 'Total',
      options: {
        sort: true,
        sortThirdClickReset: true,

        customBodyRender: (dataIndex) => {
          let numDecimals = 0;
          let val = dataIndex;
          if (![3, 4].includes(selectedMeasure?.id)) {
            numDecimals = 2;
            if (selectedMeasure?.id === 1) {
              val = val !== null ? val * 24 : null;
            }
          }
          return parseFloat(val) ? formatNumberReadable(val?.toFixed(numDecimals)) : '-';
        }
      }
    });
    return [...tempColumns];
  }, [selectedYear, selectedMeasure]);

  const sortOrder = useMemo(() => {
    const findColumnNameByLabel = (columns, label) => {
      const column = columns.find((col) => col.label === label);
      return column ? column.name : null;
    };

    const isAverage = selectedMeasure?.id === 1;

    return {
      name: findColumnNameByLabel(tableColumns, isAverage ? 'Average' : 'Total'),
      direction: 'desc'
    };
  }, [tableColumns, selectedMeasure]);
  const [currentSortOrder, setCurrentSortOrder] = useState(sortOrder);
  let geoId;
  let geographicalType;
  let congestionType;
  if (params.portId) {
    geoId = params.portId;
    geographicalType = GEOGRAPHICAL_TYPES.PORT;
  } else if (params.countryId) {
    geoId = params.countryId;
    geographicalType = GEOGRAPHICAL_TYPES.COUNTRY;
  } else if (params.regionId) {
    geoId = params.regionId;
    geographicalType = GEOGRAPHICAL_TYPES.REGION;
  } else {
    geoId = undefined;
    geographicalType = undefined;
  }

  const modalQuery = useQuery({
    queryKey: [
      'pwt',
      'cell',
      operatorType,
      segment.id,
      commercialCategoryType,
      geoId,
      geographicalType,
      congestionType
    ],
    enabled: true,
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      let body = {
        start: moment(segmentMinDate).toJSON(),
        end: moment().toJSON(),
        operatorTypeId: queryKey[2],
        segmentTypeId: queryKey[3],
        commercialCategoryId: queryKey[4],
        geoId: queryKey[5],
        geoType: queryKey[6],
        congestionType: 1
      };
      return getCongestionDetails(body, signal)
        .then((result) => result)
        .catch(() => []);
    }
  });

  useEffect(() => {
    if (
      currentSortOrder.name !== sortOrder.name ||
      currentSortOrder.direction !== sortOrder.direction
    ) {
      setCurrentSortOrder(sortOrder);
    }
  }, [selectedYear, sortOrder]);

  const options = {
    onRowClick: (rowData) => {
      operatorId = rowData[1];
      if (modalQuery.isFetched) {
        setModalData(retrieveModalData(selectedYear, clickedCellInd, operatorId, modalQuery.data));
      }
    },

    onCellClick: (cellIndex, cellData) => {
      if (cellData.colIndex !== 0 && cellData.colIndex !== 1) {
        clickedCellInd = cellData.colIndex;
        setModalOpen(true);
      }
    },

    customTableBodyFooterRender: () => {
      if (!portCongestionData) {
        return;
      }
      const { years } = portCongestionData;
      const year = years.find(({ year }) => year === selectedYear);
      let isAverage = selectedMeasure?.unit === 'Hours';
      let isCEU = selectedMeasure?.unit === 'CEU';
      let isEvent = selectedMeasure?.unit === 'Anchor events';
      return (
        <tbody>
          <TableRow
            key={`total-row-footer`}
            onClick={(event) => {
              let clickedCell = event.target.closest('td');
              const cellIndex = Array.from(event.currentTarget.children).indexOf(clickedCell);

              if (cellIndex !== 0 && cellIndex !== 13 && modalQuery.isFetched) {
                setModalData(
                  retrieveModalData(selectedYear, cellIndex + 1, 0, modalQuery.data, true),
                  setModalOpen(true)
                );
              }
            }}>
            <TableCell>
              <Typography variant={'caption'} bold>
                {isAverage ? 'Average' : 'Total'}
              </Typography>
            </TableCell>
            {year?.months?.map(
              (
                {
                  averageWaitingTimeDays,
                  totalWaitingTimeDays,
                  ceuCapacityInWaiting,
                  dwtInWaiting,
                  numWaitingEvents
                },
                i
              ) => {
                let value = averageWaitingTimeDays * 24;
                if (selectedMeasure?.id === 2) {
                  value = totalWaitingTimeDays;
                }
                if (selectedMeasure?.id === 3) {
                  value = ceuCapacityInWaiting || dwtInWaiting;
                }
                if (selectedMeasure?.id === 4) {
                  value = numWaitingEvents;
                }

                return (
                  <TableCell
                    key={`total-${i}`}
                    sx={{
                      textWrap: 'nowrap'
                    }}>
                    <Typography variant={'caption'} bold>
                      {selectedMeasure.id === 3 || selectedMeasure.id === 4
                        ? formatNumberReadable(parseFloat(value).toFixed(0)) || '0'
                        : formatNumberReadable(parseFloat(value).toFixed(2)) || '0'}
                    </Typography>
                  </TableCell>
                );
              }
            )}
            <TableCell>
              <Typography variant={'caption'} bold sx={{ p: 0, m: '2px' }}></Typography>
            </TableCell>
          </TableRow>
        </tbody>
      );
    },
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return (
        '\uFEFF' +
        buildHead(columns) +
        buildBody(
          data.map((item) => {
            item.data = item.data?.map((val) => {
              if (val === '-') {
                return null;
              }
              if (typeof val === 'string') {
                const noSpaces = val.replaceAll(' ', '');
                if (!isNaN(Number(noSpaces))) {
                  return noSpaces;
                }
              }
              return val;
            });
            return item;
          })
        )
      );
    },
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: `Monthly ${selectedMeasure?.type} per operator.csv`
    },
    setTableProps: () => {
      return {
        id: 'Port-waiting-time-table'
      };
    },
    tableBodyMaxHeight: '80vh',
    elevation: 0,
    print: false,
    viewColumns: false,
    pagination: true,
    toolbar: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: currentSortOrder,
    onColumnSortChange: (changedColumn, direction) => {
      setCurrentSortOrder({ name: changedColumn, direction });
    }
  };
  return (
    <>
      <Table
        loading={loading}
        skeletonRows={6}
        mode={theme.mode}
        title={
          <Stack sx={{ pt: 2 }}>
            <Typography id={'port-waiting-table-title'} variant={'h6'}>
              Monthly {selectedMeasure?.type} per operator
              {selectedMeasure?.id !== 3 && selectedMeasure?.id !== 4
                ? ` (${selectedMeasure?.unit.toLowerCase()})`
                : ''}
            </Typography>
            <Stack sx={{ pt: 2 }}>
              <FormControl variant="outlined" sx={{ maxWidth: 100 }} size="small">
                <InputLabel id="port-waiting-table-year-label">Year</InputLabel>
                <Select
                  id="port-waiting-table-year-select"
                  value={selectedYear + ''}
                  variant="outlined"
                  label="Year"
                  labelId={'Port-waiting-table-year-label'}
                  sx={{ minWidth: 20, maxWidth: '120px' }}
                  onChange={({ target }) => setSelectedYear(parseInt(target.value))}>
                  {yearOptions?.map((year) => (
                    <MenuItem key={`select-${year}`} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        }
        data={tableData}
        columns={tableColumns}
        options={options}
      />
      {modalOpen && Object.keys(modalData).length > 0 && (
        <UnderlyingCanalStatsModal
          modalOpen={modalOpen}
          modalData={modalData}
          setModalOpen={setModalOpen}
          setModalData={setModalData}
          isWT
        />
      )}
    </>
  );
}
MonthlyPortWaitingTable.propTypes = {
  portCongestionData: PropTypes.object,
  loading: PropTypes.bool,
  selectedMeasure: PropTypes.object,
  selectedYear: PropTypes.number,
  yearOptions: PropTypes.arrayOf(PropTypes.number),
  setSelectedYear: PropTypes.func
};

MonthlyPortWaitingTable.defaultProps = {
  portCongestionData: {},
  yearOptions: [],
  loading: false,
  selectedMeasure: {},
  selectedYear: moment().year(),
  setSelectedYear: undefined
};

export default MonthlyPortWaitingTable;
