import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { Box, ChevronRightIcon, IconButton, ToolTip } from '@esgian/esgianui';
import { MapFullscreenButton } from '../../Common';
import { mapFullscreenButtonParentStyle } from '../../Common/MapFullscreenButton';
import { useTheme, useLocalStorage } from '@hooks';
import { DashboardActivityMapActions } from './DashboardActivityMapFloatingComponents';
import DashboardActivityMapSidePanel from './DashboardActivityMapSidePanel';
import DashboardActivityMapFloatingPanel from './DashboardActivityMapFloatingPanel';
import { useMapState } from '../../Sections/ShipAnalytics/DashboardActivityMapSection/hooks/useMapState';
import {
  FILTER_TYPES,
  SIDE_PANEL_STATES
} from '../../Sections/ShipAnalytics/DashboardActivityMapSection/constants';
import { checkDashboardActivityMapFilterApplied } from './DashboardActivityMapActionWindow/DashboardActivityMapFilter';
import { useSegment } from '@hooks';
import DashboardActivityMapComponent from '../../Sections/ShipAnalytics/DashboardActivityMapSection/DashboardActivityMapComponent';

export const FLOATING_WINDOW_WIDTH = '25rem';
const MIN_SIDE_PANEL_WIDTH = 480;
const MAX_SIDE_PANEL_WIDTH = 640;

function DashboardActivityMap({
  isCompactView,
  onClickFullscreen,
  isDisableMapActions,
  activeFilters,
  filteredVessels,
  filteredPorts,
  vesselsToFilter,
  setActiveFilters,
  selectedFleets,
  zoomLocation,
  setSelectedFleets
}) {
  const themeMode = useSelector(getThemeMode);
  const mapContainerRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const { isRoRo } = useSegment();

  const { customScrollbar } = useTheme();
  const [sidePanelWidth, setSidePanelWidth] = useLocalStorage(
    'DashboardActivityMapDetailsPanelSize',
    MIN_SIDE_PANEL_WIDTH
  );
  const [isResizing, setIsResizing] = useState(false);

  const { sidePanelState, handleSidePanelClose, filterType } = useMapState();
  const isSidePanelOpen = sidePanelState === SIDE_PANEL_STATES.OPEN;

  const isAdvancedFilterApplied =
    activeFilters && checkDashboardActivityMapFilterApplied(activeFilters, false, isRoRo);
  const isInboundVesselFilterApplied =
    activeFilters && checkDashboardActivityMapFilterApplied(activeFilters, true, isRoRo);
  const hasInboundVesselsLocations = activeFilters?.selectedLocations?.some(Boolean);

  const handleMapCreated = useCallback((map) => {
    mapInstanceRef.current = map;
    if (mapInstanceRef.current) {
      mapInstanceRef.current.resize();
    }
  }, []);

  useEffect(() => {
    let timeoutId;
    if (mapInstanceRef.current) {
      if (isResizing) {
        timeoutId = setTimeout(() => {
          mapInstanceRef.current.resize();
        }, 100);
      } else {
        mapInstanceRef.current.resize();
      }
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isSidePanelOpen, sidePanelWidth, isResizing]);

  const handleResizeStart = useCallback(
    (e) => {
      e.preventDefault();
      setIsResizing(true);
      const startX = e.clientX;
      const startWidth = sidePanelWidth;

      document.body.style.userSelect = 'none';
      document.body.style.cursor = 'col-resize';

      let lastRAF;
      let lastWidth = startWidth;

      const handleMouseMove = (e) => {
        if (lastRAF) {
          cancelAnimationFrame(lastRAF);
        }

        lastRAF = requestAnimationFrame(() => {
          const delta = startX - e.clientX;
          const newWidth = Math.min(
            Math.max(startWidth + delta, MIN_SIDE_PANEL_WIDTH),
            MAX_SIDE_PANEL_WIDTH
          );

          if (newWidth !== lastWidth) {
            setSidePanelWidth(newWidth);
            lastWidth = newWidth;
          }
        });
      };

      const handleMouseUp = () => {
        setIsResizing(false);
        document.body.style.userSelect = '';
        document.body.style.cursor = '';
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        if (lastRAF) {
          cancelAnimationFrame(lastRAF);
        }
        if (mapInstanceRef.current) {
          mapInstanceRef.current.resize();
        }
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [sidePanelWidth]
  );

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        height: '100%',
        width: '100%'
      }}>
      <Box
        ref={mapContainerRef}
        sx={{
          position: 'relative',
          height: '100%',
          flex: 1,
          transition: 'width 0.3s ease'
        }}>
        <DashboardActivityMapComponent
          themeMode={themeMode}
          onMapCreated={handleMapCreated}
          filteredVessels={filteredVessels}
          filteredPorts={filteredPorts}
          zoomLocation={zoomLocation}
          isFleetFilterApplicable={selectedFleets?.size > 0 && filterType === FILTER_TYPES.FLEET}
          isInboundFilterApplicable={
            activeFilters?.selectedLocations?.length > 0 && filterType === FILTER_TYPES.INBOUND
          }
        />

        <DashboardActivityMapFloatingPanel
          filteredVessels={filteredVessels}
          hasInboundVesselsLocations={hasInboundVesselsLocations}
          vesselsToFilter={vesselsToFilter}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          selectedFleets={selectedFleets}
          setSelectedFleets={setSelectedFleets}
        />
        <Box position="absolute" top="16px" right="16px" zIndex={2}>
          <DashboardActivityMapActions
            isAdvancedFilterApplied={isAdvancedFilterApplied}
            isInboundVesselFilterApplied={isInboundVesselFilterApplied}
            isSavedApplied={false}
            isDisableMapActions={isDisableMapActions}
            isMyFleetApplied={selectedFleets?.size > 0 && filterType === FILTER_TYPES.FLEET}
          />
        </Box>
        <Box sx={mapFullscreenButtonParentStyle}>
          <MapFullscreenButton isFullScreen={!isCompactView} onClick={onClickFullscreen} />
        </Box>
      </Box>

      {isSidePanelOpen && (
        <Box
          sx={{
            height: '100%',
            width: `${sidePanelWidth}px`,
            backgroundColor: 'background.paper',
            borderLeft: 1,
            borderColor: 'divider',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <ToolTip title="Resize Drag" placement="right">
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: '-4px',
                width: '8px',
                height: '100%',
                cursor: 'col-resize',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: '3px',
                  width: '2px',
                  height: '100%',
                  backgroundColor: themeMode ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
                  transition: 'background-color 0.15s ease'
                },
                '&:hover::after': {
                  backgroundColor: themeMode ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)'
                }
              }}
              onMouseDown={handleResizeStart}
            />
          </ToolTip>
          <IconButton
            onClick={handleSidePanelClose}
            sx={{
              position: 'absolute',
              top: `50%`,
              left: 0,
              width: '32px',
              height: '72px',
              zIndex: 4,
              transform: 'translateY(-50%) translateX(-100%)',
              backgroundColor: themeMode ? '#000' : '#fff',
              transition: 'background-color 0.15s ease',
              '&:hover': {
                backgroundColor: themeMode ? '#333' : '#f0f0f0'
              },
              borderRadius: 0
            }}>
            <ChevronRightIcon />
          </IconButton>
          <Box
            height="100%"
            width={`${sidePanelWidth}px`}
            overflow="auto"
            sx={{
              ...customScrollbar,
              userSelect: isResizing ? 'none' : 'auto'
            }}>
            <DashboardActivityMapSidePanel
              activeFilters={activeFilters}
              filteredVessels={filteredVessels}
              filteredPorts={filteredPorts}
            />
          </Box>
        </Box>
      )}
    </div>
  );
}

DashboardActivityMap.propTypes = {
  onMapLoad: PropTypes.func,
  isCompactView: PropTypes.bool,
  onClickFullscreen: PropTypes.func,
  renderFloatingComponents: PropTypes.func,
  onMapUpdate: PropTypes.func,
  mapSettingsProps: PropTypes.object,
  mapRefreshKey: PropTypes.string,
  isInboundVesselFilterApplied: PropTypes.bool,
  isAdvancedFilterApplied: PropTypes.bool,
  isDisableMapActions: PropTypes.bool,
  activeFilters: PropTypes.object,
  filteredVessels: PropTypes.array,
  filteredPorts: PropTypes.array,
  hasInboundVesselsLocations: PropTypes.bool,
  vesselsToFilter: PropTypes.array,
  handleFleetFilterChange: PropTypes.func,
  setActiveFilters: PropTypes.func,
  selectedFleets: PropTypes.instanceOf(Set),
  zoomLocation: PropTypes.object,
  setSelectedFleets: PropTypes.func
};

export default DashboardActivityMap;
