import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import esgianLogo from '@assets/images/esgianLogo.png';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '@constants';
import sfcAccreditation from '@assets/images/sfc-accreditation.jpg';

const MARGIN = 20;
const LINE_GAP = 5;

const FONT_SIZES = {
  SMALL: 10,
  NORMAL: 12,
  LARGE: 14
};

const COLORS = {
  BLACK: [0, 0, 0],
  WHITE: [255, 255, 255]
};

const REPORT_CONFIG = {
  fileName: 'GHG_Emission_for_Scope_3_Emission_Report.pdf',
  title: 'GHG Emission for Scope 3 Emission Report',
  standardText: 'These calculation results have been established in accordance with ISO 14083:2023'
};

const setTextStyle = (doc, { font = 'helvetica', style = 'normal', size = FONT_SIZES.NORMAL }) => {
  doc.setFont(font, style);
  doc.setFontSize(size);
};

const formatNumber = (value, decimalPlaces) => {
  if (value === null || value === undefined) return 0;
  const numValue = parseFloat(value);
  if (decimalPlaces) {
    return numValue.toFixed(decimalPlaces);
  }
  return numValue >= 1 ? Math.floor(numValue) : numValue.toFixed(4);
};

const createTable = ({ doc, title, subtitle, headers, data, yPos, tableWidthInPercent = 100 }) => {
  const xPos = MARGIN;
  const pageWidth = doc.internal.pageSize.width;
  const tableWidth = ((pageWidth - MARGIN * 2) * tableWidthInPercent) / 100;

  if (title) {
    setTextStyle(doc, { style: 'bold', size: FONT_SIZES.NORMAL });
    doc.text(title, xPos, yPos);
    yPos += 1;

    const titleWidth = doc.getTextWidth(title) + 1;
    doc.setLineWidth(0.5);
    doc.line(xPos, yPos, xPos + titleWidth, yPos);
    yPos += LINE_GAP + 1;
  }

  if (subtitle) {
    const [subtitleLabel, subtitleText] = subtitle.split(':');
    setTextStyle(doc, { style: 'bold', size: FONT_SIZES.NORMAL });
    doc.text(`${subtitleLabel}:`, xPos, yPos);
    const labelWidth = doc.getTextWidth(`${subtitleLabel}:`);
    setTextStyle(doc, { style: 'normal' });
    doc.text(subtitleText, xPos + labelWidth, yPos);
    yPos += LINE_GAP;
  }

  doc.autoTable({
    startY: yPos,
    head: headers,
    body: data,
    theme: 'grid',
    styles: {
      fontSize: FONT_SIZES.SMALL,
      cellPadding: 2,
      lineWidth: 0.1,
      lineColor: COLORS.BLACK,
      textColor: COLORS.BLACK
    },
    headStyles: {
      fontStyle: 'bold',
      fillColor: COLORS.WHITE
    },
    columnStyles: { 0: { cellWidth: 'auto' } },
    margin: { left: MARGIN, right: MARGIN },
    tableWidth,
    willDrawCell: ({ row, table, cell }) => {
      if (row.index === table.body.length - 1 && cell.raw === 'Total') {
        doc.setFont(undefined, 'bold');
      }
      if (cell.styles && cell.styles.fontStyle === 'bold') {
        doc.setFont(undefined, 'bold');
      }
    }
  });

  return doc.lastAutoTable.finalY + LINE_GAP;
};

const createPortsTable = (doc, { voyagePortCalls, voyageEmissionData }, yPos) => {
  const headers = [
    [
      'Departure Port',
      'Time',
      'Arrival Port',
      'Time',
      `Actual Distance (km)`,
      'Estimated Cargo weight (tonnes)'
    ]
  ];

  const data = voyageEmissionData.overallVoyageGhgEmission.voyageLegEmissionIntensities.map(
    ({ fromPortId, toPortId, fromPortName, toPortName, distanceKm, cargoWeightTn }) => {
      const fromPort = voyagePortCalls.portCalls.find((portCall) => portCall.portId === fromPortId);
      const toPort = voyagePortCalls.portCalls.find((portCall) => portCall.portId === toPortId);
      return [
        fromPortName,
        moment(fromPort.departureDate).format(DATE_TIME_FORMAT),
        toPortName,
        moment(toPort.arrivalDate).format(DATE_TIME_FORMAT),
        formatNumber(distanceKm),
        formatNumber(cargoWeightTn)
      ];
    }
  );

  return createTable({
    doc,
    headers,
    data,
    yPos,
    tableWidthInPercent: 100
  });
};

const generateOverallVoyageEmissionTable = (doc, voyageEmissionData, yPos) => {
  const primaryTableHeaders = [
    [
      null,
      null,
      { content: 'CO2e Emission (GHG Emission) [kg]', colSpan: 3, styles: { halign: 'center' } }
    ],
    ['Origin', 'Destination', 'Energy Production', 'Operational', 'Overall']
  ];

  const secondaryTableHeaders = [
    [
      { content: 'Origin', rowSpan: 2, styles: { valign: 'center' } },
      { content: 'Destination', rowSpan: 2, styles: { valign: 'center' } },
      { content: 'Estimated Cargo weight (t)', rowSpan: 2, styles: { valign: 'center' } },
      { content: 'Distance (km)', rowSpan: 2, styles: { valign: 'center' } },
      { content: 'Transport Activity (t-km)', rowSpan: 2, styles: { valign: 'center' } },
      { content: 'Emission intensity (kg CO2e/(t.km))', colSpan: 3, styles: { halign: 'center' } }
    ],
    ['Energy Production', 'Operational', 'Overall']
  ];

  const primaryTableData = [];
  const secondaryTableData = [];

  voyageEmissionData.overallVoyageGhgEmission.voyageLegEmissionIntensities.forEach(
    ({
      fromPortName,
      toPortName,
      ghgEmissionEneryProductionKg,
      ghgEmissionOperationalKg,
      ghgEmissionOverallKg,
      cargoWeightTn,
      distanceKm,
      transportActivity,
      emissionIntensityEneryProduction,
      emissionIntensityOperational,
      emissionIntensityOverall
    }) => {
      primaryTableData.push([
        fromPortName,
        toPortName,
        formatNumber(ghgEmissionEneryProductionKg),
        formatNumber(ghgEmissionOperationalKg),
        formatNumber(ghgEmissionOverallKg)
      ]);
      secondaryTableData.push([
        fromPortName,
        toPortName,
        formatNumber(cargoWeightTn),
        formatNumber(distanceKm),
        formatNumber(transportActivity),
        formatNumber(emissionIntensityEneryProduction),
        formatNumber(emissionIntensityOperational),
        formatNumber(emissionIntensityOverall)
      ]);
    }
  );

  let lastYPos = createTable({
    doc,
    title: 'Overall Voyage Emission',
    headers: primaryTableHeaders,
    data: primaryTableData,
    yPos,
    tableWidthInPercent: 100
  });

  lastYPos = createTable({
    doc,
    headers: secondaryTableHeaders,
    data: secondaryTableData,
    yPos: lastYPos,
    tableWidthInPercent: 100
  });

  return lastYPos;
};

const createGhgEmissionForMyCargoTable = (doc, voyageEmissionData, yPos) => {
  const headers = [];
  const data = [
    [
      { content: 'My Cargo [t]', styles: { fontStyle: 'bold' } },
      formatNumber(voyageEmissionData.myCargoGhgEmissionSummary.myCargoTn)
    ],
    [
      'TOC GHG Emission for my cargo [kg]',
      formatNumber(voyageEmissionData.myCargoGhgEmissionSummary.tocGhgEmissionKg)
    ],
    [
      'HOC GHG Emission for my cargo [kg]',
      formatNumber(voyageEmissionData.myCargoGhgEmissionSummary.hocGhgEmissionKg)
    ],
    [
      'GHG Emission for Ballast voyage for my cargo [kg]',
      formatNumber(voyageEmissionData.myCargoTocEmission.ballast)
    ],

    [
      { content: 'Total GHG Emission for my cargo [kg]', styles: { fontStyle: 'bold' } },
      formatNumber(voyageEmissionData.myCargoTocEmission.totalGhgEmissionOverallPlusBallastKg)
    ]
  ];
  return createTable({
    doc,
    title: 'GHG Emission for my Cargo',
    headers,
    data,
    yPos,
    tableWidthInPercent: 50
  });
};

const createMyCargoTocEmissionTable = (doc, { voyageEmissionData, isRoRo }, yPos) => {
  const headers = [
    [
      null,
      null,
      null,
      null,
      {
        content: 'GHG Emission (kg CO2e)',
        colSpan: 3,
        styles: { halign: 'center' }
      }
    ],
    [
      'Origin',
      'Destination',
      `${isRoRo ? 'Shortest' : 'Actual'} Distance (km)`,
      'My TOC activity',
      'Energy Production',
      'Operational',
      'Overall'
    ]
  ];

  const data = voyageEmissionData.myCargoTocEmission.voyageLegMyCargoEmissionIntensities.map(
    (leg) => [
      leg.fromPortName,
      leg.toPortName,
      formatNumber(leg.distanceKm),
      formatNumber(leg.myTocActivity),
      formatNumber(leg.ghgEmissionEneryProductionKg),
      formatNumber(leg.ghgEmissionOperationalKg),
      formatNumber(leg.ghgEmissionOverallKg)
    ]
  );

  data.push([
    'Total',
    '',
    null,
    null,
    formatNumber(voyageEmissionData.myCargoTocEmission.totalGhgEmissionEneryProductionKg),
    formatNumber(voyageEmissionData.myCargoTocEmission.totalGhgEmissionOperationalKg),
    formatNumber(voyageEmissionData.myCargoTocEmission.totalGhgEmissionOverallKg)
  ]);

  return createTable({
    doc,
    title: 'My Cargo TOC (Transport Operation Category) Emission',
    subtitle: 'Data Type: Secondary Data - Modelled  (100%)',
    headers,
    data,
    yPos,
    tableWidthInPercent: 100
  });
};

const createMyCargoHocEmissionTable = (doc, voyageEmissionData, yPos) => {
  const headers = [
    [
      'Port name',
      'Operational HOC Emission intensity (kg CO2e/t)',
      'HOC activity (t)',
      'GHG Emission (kg CO2e)'
    ]
  ];
  const data = voyageEmissionData.myCargoHocEmission.voyageLegMyCargoHocEmissions.map((leg) => [
    leg.portName,
    leg.operationalHocEmissionIntensity,
    formatNumber(leg.hocActivity, 1),
    formatNumber(leg.hocGhgEmission, 1)
  ]);

  return createTable({
    doc,
    title: 'My Cargo HOC (Hub Operations Category) Emission',
    subtitle: 'Data Type: Secondary Data – Default  (100%)',
    headers,
    data,
    yPos,
    tableWidthInPercent: 100
  });
};

const createFuelEmissionFactorTable = (doc, voyageEmissionData, yPos) => {
  const headers = [['Fuel type', 'CO2e Emission factor', 'Source']];

  const allFuelInfoById = [
    ...voyageEmissionData.fuelDetails.mainEngineFuelDurations,
    ...voyageEmissionData.fuelDetails.mainGeneratorFuelDurations
  ].reduce(
    (fuelInfoByFuelTypeId, curr) => ({ ...fuelInfoByFuelTypeId, [curr.fuelTypeId]: curr }),
    {}
  );

  const data = Object.values(allFuelInfoById).map(
    ({ fuelTypeId, co2eEmissionFactor, co2eEfSource }) => [
      voyageEmissionData.fuelDetails.fuelTypeIdsFuelNames[fuelTypeId],
      co2eEmissionFactor,
      co2eEfSource
    ]
  );

  return createTable({
    doc,
    title: 'Fuel Emission Factor',
    headers,
    data,
    yPos,
    tableWidthInPercent: 100
  });
};

const addLogo = (doc, yPos) => {
  const logoHeight = 10;
  const logoWidth = 36;
  const pageWidth = doc.internal.pageSize.width;
  doc.addImage(
    esgianLogo,
    'PNG',
    pageWidth - MARGIN - logoWidth,
    yPos - logoHeight,
    logoWidth,
    logoHeight
  );
};

const addSfcAccreditation = (doc) => {
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const imageWidth = pageWidth - 2 * MARGIN;
  const imageHeight = pageHeight / 2;
  doc.addImage(sfcAccreditation, 'PNG', MARGIN, 0, imageWidth, imageHeight);
};

const addStandardText = (doc, yPos) => {
  setTextStyle(doc, { font: 'Aptos', style: 'bolditalic', size: FONT_SIZES.NORMAL });
  const pageWidth = doc.internal.pageSize.width;
  const xOffset = pageWidth / 2;
  doc.text(REPORT_CONFIG.standardText, xOffset, yPos, {
    align: 'center',
    maxWidth: pageWidth - 2 * MARGIN
  });
  return yPos + 2 * LINE_GAP;
};

const createTextSection = (doc, { mainTitle, description, sections }, yPos) => {
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;

  // Function to check and add new page if needed
  const checkAndAddPage = (requiredSpace) => {
    if (yPos + requiredSpace > pageHeight - MARGIN) {
      doc.addPage();
      yPos = MARGIN;
    }
  };

  // Main Title
  checkAndAddPage(FONT_SIZES.LARGE + LINE_GAP + 2);
  setTextStyle(doc, { style: 'bold', size: FONT_SIZES.LARGE });
  doc.text(mainTitle, MARGIN, yPos);
  yPos += 1;
  const titleWidth = doc.getTextWidth(mainTitle) + 1;
  doc.setLineWidth(0.5);
  doc.line(MARGIN, yPos, MARGIN + titleWidth, yPos);
  yPos += LINE_GAP + 1;

  // Description
  setTextStyle(doc, { style: 'normal', size: FONT_SIZES.SMALL });
  const descriptionLines = doc.splitTextToSize(description, pageWidth - 2 * MARGIN);
  checkAndAddPage(descriptionLines.length * LINE_GAP);
  doc.text(descriptionLines, MARGIN, yPos);
  yPos += descriptionLines.length * LINE_GAP;

  // Sections
  sections.forEach(({ subtitle, content }) => {
    yPos += LINE_GAP;

    // Subtitle
    checkAndAddPage(FONT_SIZES.SMALL + LINE_GAP + 2);
    setTextStyle(doc, { style: 'bold', size: FONT_SIZES.SMALL });
    doc.text(subtitle, MARGIN, yPos);
    yPos += 1;
    const subtitleWidth = doc.getTextWidth(subtitle) + 1;
    doc.setLineWidth(0.5);
    doc.line(MARGIN, yPos, MARGIN + subtitleWidth, yPos);
    yPos += LINE_GAP + 1;

    // Content
    if (Array.isArray(content)) {
      // Bullet points
      content.forEach(({ title, text }) => {
        checkAndAddPage(2 * LINE_GAP + FONT_SIZES.SMALL);
        setTextStyle(doc, { style: 'bold', size: FONT_SIZES.SMALL });
        const bulletPoint = `\u2022 ${title}:`;
        doc.text(bulletPoint, MARGIN + 8, yPos);
        setTextStyle(doc, { style: 'normal', size: FONT_SIZES.SMALL });
        const textLines = doc.splitTextToSize(text, pageWidth - 3 * MARGIN - 8);
        yPos += LINE_GAP;
        checkAndAddPage(textLines.length * LINE_GAP);
        doc.text(textLines, MARGIN + 8, yPos);
        yPos += textLines.length * LINE_GAP;
      });
    } else {
      // Normal text
      setTextStyle(doc, { style: 'normal', size: FONT_SIZES.SMALL });
      const contentLines = doc.splitTextToSize(content, pageWidth - 2 * MARGIN);
      checkAndAddPage(contentLines.length * LINE_GAP);
      doc.text(contentLines, MARGIN, yPos);
      yPos += contentLines.length * LINE_GAP;
    }
  });

  return yPos;
};

export const generateGhcEmissionReport = (voyageEmissionData, voyagePortCalls, isRoRo) => {
  const doc = new jsPDF();
  let yPos = MARGIN;
  const xPos = MARGIN;

  addLogo(doc, yPos);
  yPos = addStandardText(doc, yPos + 3 * LINE_GAP);

  // Mode of Transport
  setTextStyle(doc, { style: 'bold' });
  const modeOfTransportText = 'Mode of Transport:';
  doc.text(modeOfTransportText, xPos, yPos);
  setTextStyle(doc, { style: 'normal' });
  doc.text('Ocean', xPos + doc.getTextWidth(modeOfTransportText) + 4, yPos);

  // Create tables
  yPos += LINE_GAP;
  yPos = createPortsTable(doc, { voyagePortCalls, voyageEmissionData, isRoRo }, yPos);
  yPos += 2 * LINE_GAP;
  yPos = generateOverallVoyageEmissionTable(doc, voyageEmissionData, yPos);
  yPos += 2 * LINE_GAP;
  yPos = createGhgEmissionForMyCargoTable(doc, voyageEmissionData, yPos);
  yPos += 2 * LINE_GAP;
  yPos = createMyCargoTocEmissionTable(doc, { voyageEmissionData, isRoRo }, yPos);
  yPos += 2 * LINE_GAP;
  yPos = createMyCargoHocEmissionTable(doc, voyageEmissionData, yPos);
  yPos += 2 * LINE_GAP;
  yPos = createFuelEmissionFactorTable(doc, voyageEmissionData, yPos);

  // Add text section
  doc.addPage();
  yPos = createTextSection(
    doc,
    {
      mainTitle: 'Esgian Emission Estimation Methodology',
      description:
        "The modelled Emission data from Esgian Shipping stems from hourly emissions from vessels based on their real-time operation. Esgian creates a virtual twin of all vessels' operations at an hourly frequency.",
      sections: [
        {
          subtitle: 'Data Collection and Inputs',
          content: [
            {
              title: 'Vessel Parameters',
              text: 'Esgian collects detailed information about each vessel, including its dimensions, propulsion systems, and specifications of both the main and auxiliary engines. These parameters are essential for accurately modelling energy and fuel consumption.'
            },
            {
              title: 'AIS (Automatic Identification System) Data',
              text: "This data provides real-time operational information about the vessel, such as speed, draught, heading, and other navigational parameters. AIS data is crucial for tracking the vessel's movement and operational status."
            },
            {
              title: 'Global Weather Data',
              text: 'Esgian incorporates environmental data, including wind speed, wave height, and ocean currents, which impact vessel performance and fuel consumption. Weather conditions are vital for simulating realistic vessel operations.'
            }
          ]
        },
        {
          subtitle: 'Virtual Twin and Simulation',
          content:
            'Esgian creates a virtual representation, or "digital twin," of each vessel\'s operations using the collected data. This digital twin operates at an hourly frequency, simulating the vessel\'s real-time conditions and movements. This simulation enables accurate modeling of how the vessel interacts with its environment and operates under various conditions.'
        },
        {
          subtitle: 'Power Consumption Estimation',
          content: [
            {
              title: 'Propulsion Power',
              text: 'The model uses industry-standard methodologies, akin to those employed by naval architects during vessel design, to estimate the power required for propulsion. These methodologies take into account vessel speed, resistance due to hull shape and size, and environmental factors like wind and currents. The methodology also accounts for marine fouling and vessel age.'
            },
            {
              title: 'Auxiliary and Boiler Power',
              text: "Power consumption for auxiliary systems (e.g., lighting, HVAC, machinery) and boilers (used for heating and other functions) is estimated based on the vessel's size and operational status (e.g., at sea, in port, etc.)."
            }
          ]
        },
        {
          subtitle: 'Fuel Consumption and GHG Emission Estimation',
          content: [
            {
              title: 'Fuel Consumption',
              text: 'Using the estimated power consumption, the model calculates fuel consumption. This calculation is based on specific fuel consumption rates for different engines and operational scenarios.'
            },
            {
              title: 'Emission Calculation',
              text: 'Once fuel consumption is estimated, Esgian converts this data into greenhouse gas (GHG) emissions using standard conversion factors compliant with ISO 14083.'
            },
            {
              title: 'Dual Fuel Vessels',
              text: 'For vessels that can use more than one type of fuel, the model defaults to the primary fuel type for its calculations.'
            }
          ]
        },
        {
          subtitle: 'Emission for voyages',
          content:
            'Hourly emissions for vessels are stored in the Esgian database. Aggregating the emisison for the period of the voyage aggregates the voyage emissions. This includes the emission during vessel transit and at port. Hub operation emission for scope 3 emissions reporting is based on standard default factors recommended by GLECC framework. The emissions due to Ballast voyages are also considered when considering the total emissions. For RoRo vessels, since most of the voyages run with cargo, the emission due to ballast voyages is assumed as 0 Tonnes. For MPP vessels, based on data analyzed from MPP vessel voyages for 2 years, a ballast emission factor is defined by Esgian. This factor considers 92% of the average emission of a voyage leg in the entire voyage as emissions from ballast voyage.'
        }
      ]
    },
    MARGIN
  );
  doc.addPage();
  addSfcAccreditation(doc, yPos);

  doc.save(REPORT_CONFIG.fileName);
};
