import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Paper, Stack, Typography } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import moment from 'moment';
import { getDirection } from '@helpers';

const getDefaultOperatorSetWeek = (startDate, endDate) => {
  let startDateClone = startDate.clone();
  let endDateClone = endDate.clone();
  let res = {};
  while (
    startDateClone &&
    endDateClone &&
    startDateClone.isSameOrBefore(endDateClone.endOf('isoWeek'))
  ) {
    let weekNumber = startDateClone.isoWeek();
    let yearNumber = startDateClone.isoWeekYear();
    if (startDateClone.isoWeekYear() < startDateClone.year()) {
      startDateClone.add(1, 'isoWeek');
      continue;
    }
    let key = `${yearNumber}_${weekNumber}`;
    res[key] = {
      year: yearNumber,
      week: weekNumber,
      value: 0
    };

    startDateClone.add(1, 'isoWeek');
  }
  return res;
};

const getDefaultOperatorSetMonth = (startDate, endDate) => {
  let startDateClone = startDate.clone();
  let endDateClone = endDate.clone();
  let res = {};
  while (startDateClone && endDateClone && startDateClone.isSameOrBefore(endDateClone)) {
    let monthNumber = startDateClone.month();
    let yearNumber = startDateClone.year();
    let key = `${yearNumber}_${monthNumber}`;
    res[key] = {
      year: yearNumber,
      month: monthNumber,
      value: 0
    };

    startDateClone.add(1, 'month');
  }
  return res;
};

const getOptions = (categories, theme, user, fileName, timeFrame, isStacked, yearGroups) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, sevenColorsIndication }
    }
  } = theme;

  return {
    chart: {
      animations: {
        enabled: false
      },
      type: 'bar',
      height: 480,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        offsetY: -30,
        tools: {
          download: checkUserHasAccess(user, true),
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: isStacked,
      zoom: {
        enabled: true
      },
      id: `-${timeFrame}-oprDir-canal-transits-chart`
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    colors: sevenColorsIndication,
    theme: {
      mode: mode
    },
    xaxis: {
      tickPlacement: 'between',
      categories: categories,
      group: {
        groups: yearGroups.map(({ mentionedYear, yearCount }) => {
          return {
            title: mentionedYear,
            cols: yearCount
          };
        })
      }
    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      offsetX: -35,
      horizontalAlign: 'left'
    },
    yaxis: {
      max: (max) => max * 1.2,
      title: {
        text: 'No. of Transits'
      },
      decimalsInFloat: 0
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        columnWidth: '70%'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      dashArray: [0, 0]
    },
    markers: {
      size: 0,
      hover: {
        size: 5
      }
    }
  };
};

function CanalTransitsChartOpDir({
  loading = false,
  fileName = '',
  canalTransits = null,
  timeFrame = 'weekly',
  startDate = null,
  endDate = null
}) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const weeklyChartData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let dataSet = {};

    canalTransits?.forEach(({ canalArrival, operatorId, operatorShortName, headingDirection }) => {
      if (headingDirection !== 0) {
        if (!dataSet[operatorId]) {
          dataSet[operatorId] = {
            operatorName: operatorShortName
          };
        }
        if (!dataSet[operatorId][headingDirection]) {
          dataSet[operatorId][headingDirection] = {
            directionName: getDirection(headingDirection),
            data: getDefaultOperatorSetWeek(startDate, endDate)
          };
        }
        let arrival = moment(canalArrival);
        let weekNumber = arrival.clone().isoWeek();
        let yearNumber = arrival.clone().isoWeekYear();
        if (
          !Object.hasOwn(dataSet[operatorId][headingDirection].data, `${yearNumber}_${weekNumber}`)
        ) {
          if (Object.hasOwn(dataSet[operatorId][headingDirection].data, `${yearNumber + 1}_${1}`)) {
            yearNumber++;
            weekNumber = 1;
          } else {
            yearNumber--;
            weekNumber = 52;
          }
        }
        let key = `${yearNumber}_${weekNumber}`;

        dataSet[operatorId][headingDirection].data[key].value += 1;
      }
    });

    return dataSet;
  }, [canalTransits]);

  const monthlyChartData = useMemo(() => {
    if (loading || !canalTransits?.length) {
      return [];
    }
    let dataSet = {};

    canalTransits?.forEach(({ canalArrival, operatorId, operatorShortName, headingDirection }) => {
      if (headingDirection !== 0) {
        if (!dataSet[operatorId]) {
          dataSet[operatorId] = {
            operatorName: operatorShortName
          };
        }
        if (!dataSet[operatorId][headingDirection]) {
          dataSet[operatorId][headingDirection] = {
            directionName: getDirection(headingDirection),
            data: getDefaultOperatorSetMonth(startDate, endDate)
          };
        }
        let arrival = moment.utc(canalArrival);
        let weekNumber = moment.utc(arrival.clone()).month();
        let yearNumber = arrival.clone().year();
        let key = `${yearNumber}_${weekNumber}`;

        dataSet[operatorId][headingDirection].data[key].value += 1;
      }
    });

    return dataSet;
  }, [canalTransits]);

  let yearGroups = [];
  let timeColumns = {};
  let opNames = {};
  if (timeFrame === 'weekly') {
    Object.keys(weeklyChartData).forEach((operatorId) => {
      const opData = weeklyChartData[operatorId];

      opNames[operatorId] = {
        operatorName: weeklyChartData[operatorId].operatorName
      };
      Object.keys(opData).forEach((directionId) => {
        if (directionId !== 'operatorName') {
          const dirData = opData[directionId].data;
          opNames[operatorId][directionId] = {
            directionName: weeklyChartData[operatorId][directionId].directionName,
            data: {}
          };
          Object.keys(dirData).forEach((weeklyData) => {
            const weekData = dirData[weeklyData];
            if (!yearGroups.find(({ mentionedYear }) => mentionedYear === weekData.year)) {
              yearGroups.push({
                mentionedYear: weekData.year,
                yearCount: 0
              });
              Object.keys(dirData).forEach((opRow) => {
                if (
                  yearGroups.find(
                    ({ mentionedYear }) =>
                      mentionedYear === dirData[opRow].year && mentionedYear === weekData.year
                  )
                ) {
                  yearGroups.find(
                    ({ mentionedYear }) =>
                      mentionedYear === dirData[opRow].year && mentionedYear === weekData.year
                  ).yearCount++;
                }
              });
            }
            if (!timeColumns[`${weekData.year}_${weekData.week}`]) {
              timeColumns[`${weekData.year}_${weekData.week}`] = weekData.week;
            }

            opNames[operatorId][directionId].data[`${weekData.year}_${weekData.week}`] =
              weekData.value;
          });
        }
      });
    });
  } else {
    Object.keys(monthlyChartData).forEach((operatorId) => {
      const opData = monthlyChartData[operatorId];

      opNames[operatorId] = {
        operatorName: monthlyChartData[operatorId].operatorName
      };
      Object.keys(opData).forEach((directionId) => {
        if (directionId !== 'operatorName') {
          const dirData = opData[directionId].data;
          opNames[operatorId][directionId] = {
            directionName: monthlyChartData[operatorId][directionId].directionName,
            data: {}
          };
          Object.keys(dirData).forEach((monthlyData) => {
            const monthData = dirData[monthlyData];
            if (!yearGroups.find(({ mentionedYear }) => mentionedYear === monthData.year)) {
              yearGroups.push({
                mentionedYear: monthData.year,
                yearCount: 0
              });
              Object.keys(dirData).forEach((opRow) => {
                if (
                  yearGroups.find(
                    ({ mentionedYear }) =>
                      mentionedYear === dirData[opRow].year && mentionedYear === monthData.year
                  )
                ) {
                  yearGroups.find(
                    ({ mentionedYear }) =>
                      mentionedYear === dirData[opRow].year && mentionedYear === monthData.year
                  ).yearCount++;
                }
              });
            }
            if (!timeColumns[`${monthData.year}_${monthData.month}`]) {
              timeColumns[`${monthData.year}_${monthData.month}`] =
                moment().month(monthData.month).format('MMM') + ' ' + monthData.year;
            }
            opNames[operatorId][directionId].data[`${monthData.year}_${monthData.month}`] =
              monthData.value;
          });
        }
      });
    });
  }

  const { series } = useMemo(() => {
    let max = 0;

    let tempSeries = [];
    Object.keys(opNames).forEach((operatorId) => {
      const { operatorName } = opNames[operatorId];
      const opData = opNames[operatorId];
      Object.keys(opData).map((directionId) => {
        if (directionId !== 'operatorName') {
          const { data, directionName } = opNames[operatorId][directionId];

          const operatorData = [];
          Object.keys(data).map((key) => {
            operatorData.push(data[key]);
          });

          tempSeries.push({
            name: operatorName + ' ' + directionName,
            data: operatorData
          });
        }
      });
    });

    return { series: tempSeries, maxValue: max };
  }, [weeklyChartData, monthlyChartData]);

  const { categories } = useMemo(() => {
    if (!canalTransits?.length) {
      return { categories: [] };
    }

    let tempCategories = [];
    Object.keys(timeColumns).map((index) => {
      tempCategories.push(timeColumns[index]);
    });

    return { categories: tempCategories };
  }, [canalTransits, timeFrame]);

  const options = useMemo(() => {
    if (!categories?.length) return {};
    const isStacked = false;
    return getOptions(categories, theme, user, fileName, timeFrame, isStacked, yearGroups);
  }, [theme, categories, user, fileName, timeFrame]);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant={'h6'}>
          {timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)} canal transits
        </Typography>
        <ApexChart
          loading={loading || !options}
          height={480}
          options={options || {}}
          type={'bar'}
          data={series}
        />
      </Stack>
    </Paper>
  );
}

CanalTransitsChartOpDir.propTypes = {
  loading: PropTypes.bool,
  fileName: PropTypes.string,
  timeFrame: PropTypes.string,
  canalTransits: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default CanalTransitsChartOpDir;
