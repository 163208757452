import moment from 'moment';
import { OPERATOR_TYPE, COMMERCIAL_CATEGORY_TYPE } from '@constants';

export const createVesselFilterFunctions = (activeFilters, initialValues, isRoRo) => ({
  vesselFilter: (vessel) =>
    vessel?.imo && activeFilters?.selectedVessels?.length
      ? activeFilters?.selectedVessels?.some((item) => item.id === vessel.imo)
      : true,

  ownerFilter: (vessel) =>
    vessel?.ownerShortName && activeFilters?.selectedOwners?.length
      ? activeFilters?.selectedOwners?.some((item) => item.ownerShortName === vessel.ownerShortName)
      : true,

  operatorTypeFilter: (vessel) => {
    if (
      !isRoRo ||
      !activeFilters?.operatorTypeId ||
      activeFilters?.operatorTypeId === OPERATOR_TYPE.ALL
    ) {
      return true;
    }
    return vessel?.operatorTypeId === activeFilters?.operatorTypeId;
  },

  commercialCategoryFilter: (vessel) => {
    if (
      isRoRo ||
      !activeFilters?.commercialCategoryId ||
      activeFilters?.commercialCategoryId === COMMERCIAL_CATEGORY_TYPE.ALL
    ) {
      return true;
    }
    return vessel?.commercialCategoryId === activeFilters?.commercialCategoryId;
  },

  capacityFilter: (vessel) => {
    if (isRoRo) {
      const carCapacity = vessel?.carCapacity;
      const isActive =
        activeFilters?.minCarCapacity !== initialValues?.minCarCapacity ||
        activeFilters?.maxCarCapacity !== initialValues?.maxCarCapacity;
      return (
        !isActive ||
        (typeof carCapacity === 'number' &&
          carCapacity >= activeFilters?.minCarCapacity &&
          carCapacity <= activeFilters?.maxCarCapacity)
      );
    } else {
      const liftingCapacity = vessel?.liftingCapacity;
      const isActive =
        activeFilters?.minLiftingCapacity !== initialValues?.minLiftingCapacity ||
        activeFilters?.maxLiftingCapacity !== initialValues?.maxLiftingCapacity;
      return (
        !isActive ||
        (typeof liftingCapacity === 'number' &&
          liftingCapacity >= activeFilters?.minLiftingCapacity &&
          liftingCapacity <= activeFilters?.maxLiftingCapacity)
      );
    }
  },

  buildYearFilter: (vessel) => {
    const buildYear = vessel?.buildYear;
    const isActive =
      activeFilters?.minBuildYear !== initialValues?.minBuildYear ||
      activeFilters?.maxBuildYear !== initialValues?.maxBuildYear;
    return (
      !isActive ||
      (typeof buildYear === 'number' &&
        buildYear >= activeFilters?.minBuildYear &&
        buildYear <= activeFilters?.maxBuildYear)
    );
  },

  deadweightFilter: (vessel) => {
    const deadweight = vessel?.deadweight;
    const isActive =
      activeFilters?.minDeadweight !== initialValues?.minDeadweight ||
      activeFilters?.maxDeadweight !== initialValues?.maxDeadweight;
    return (
      !isActive ||
      (typeof deadweight === 'number' &&
        deadweight >= activeFilters?.minDeadweight &&
        deadweight <= activeFilters?.maxDeadweight)
    );
  },

  grossTonnageFilter: (vessel) => {
    const grossTonnage = vessel?.grossTonnage;
    const isActive =
      activeFilters?.minGrossTonnage !== initialValues?.minGrossTonnage ||
      activeFilters?.maxGrossTonnage !== initialValues?.maxGrossTonnage;
    return (
      !isActive ||
      (typeof grossTonnage === 'number' &&
        grossTonnage >= activeFilters?.minGrossTonnage &&
        grossTonnage <= activeFilters?.maxGrossTonnage)
    );
  },

  draughtFilter: (vessel) => {
    const vesselMaxDraught = vessel?.vesselDraught;
    const isActive =
      activeFilters?.minDraught !== initialValues?.minDraught ||
      activeFilters?.maxDraught !== initialValues?.maxDraught;
    return (
      !isActive ||
      (typeof vesselMaxDraught === 'number' &&
        vesselMaxDraught >= activeFilters?.minDraught &&
        vesselMaxDraught <= activeFilters?.maxDraught)
    );
  },

  draughtPercentageFilter: (vessel) => {
    const vesselMaxDraughtPercentage = vessel?.percentDraught;
    const isActive =
      activeFilters?.minDraughtPercentage !== initialValues?.minDraughtPercentage ||
      activeFilters?.maxDraughtPercentage !== initialValues?.maxDraughtPercentage;
    return (
      !isActive ||
      (typeof vesselMaxDraughtPercentage === 'number' &&
        vesselMaxDraughtPercentage >= activeFilters?.minDraughtPercentage &&
        vesselMaxDraughtPercentage <= activeFilters?.maxDraughtPercentage)
    );
  },

  cranesFilter: (vessel) => {
    const vesselNoOfCranes = vessel?.numCranes;
    const isActive =
      activeFilters?.minNoOfCranes !== initialValues?.minNoOfCranes ||
      activeFilters?.maxNoOfCranes !== initialValues?.maxNoOfCranes;
    return (
      !isActive ||
      (typeof vesselNoOfCranes === 'number' &&
        vesselNoOfCranes >= activeFilters?.minNoOfCranes &&
        vesselNoOfCranes <= activeFilters?.maxNoOfCranes)
    );
  },

  beamFilter: (vessel) => {
    const vesselBeam = vessel?.mouldedBreadth;
    const isActive =
      activeFilters?.minBeam !== initialValues?.minBeam ||
      activeFilters?.maxBeam !== initialValues?.maxBeam;
    return (
      !isActive ||
      (typeof vesselBeam === 'number' &&
        vesselBeam >= activeFilters?.minBeam &&
        vesselBeam <= activeFilters?.maxBeam)
    );
  },

  lengthFilter: (vessel) => {
    const vesselLength = vessel?.loa;
    const isActive =
      activeFilters?.minLength !== initialValues?.minLength ||
      activeFilters?.maxLength !== initialValues?.maxLength;
    return (
      !isActive ||
      (typeof vesselLength === 'number' &&
        vesselLength >= activeFilters?.minLength &&
        vesselLength <= activeFilters?.maxLength)
    );
  },

  etaFilter: (vessel) => {
    if (!activeFilters?.selectedDate?.startDate || !activeFilters?.selectedDate?.endDate) {
      return true;
    }
    const vesselEta = moment.utc(vessel.eta);
    const startDate = moment.utc(activeFilters?.selectedDate?.startDate);
    const endDate = moment.utc(activeFilters?.selectedDate?.endDate);
    return vesselEta.isBetween(startDate, endDate, 'day', '[]');
  }
});
