import { useSegment } from '../../../../../hooks';
import { getDashboardActivityMapFilterInitialValues } from '../../../../Maps/DashboardActivityMap/DashboardActivityMapActionWindow/DashboardActivityMapFilter';
import { SEGMENT } from '@constants';
import { FILTER_TYPES, INBOUND_VESSELS_COLOR_PALETTE } from '../constants';
import { QUICK_SEARCH_ITEM_TYPE } from '../DashboardActivityMapUtils';
import { createVesselFilterFunctions } from '../utils/filter';
import { useMapState } from './useMapState';
import { useActivityMapUiState } from './useActivityMapUiState';

const ZOOM_LEVELS = {
  SINGLE_LOCATION: 6,
  COUNTRY: 3,
  DEFAULT: 1
};

export const useFilteredVessels = (vessels = [], ports = [], activeFilters, selectedFleets) => {
  const { segment } = useSegment();
  const { filterType, quickSearchItemType, quickSearchItemId } = useMapState();
  const { isInboundFilterApplicable, isAdvancedFilterApplicable, isFleetFilterApplicable } =
    useActivityMapUiState(activeFilters, selectedFleets);

  console.log('rendering useFilteredVessels');

  const isRoRo = segment?.id === SEGMENT.RoRo.id;

  if (filterType === FILTER_TYPES.QUICK_SEARCH) {
    if (!quickSearchItemType || !quickSearchItemId) {
      return {
        filteredVessels: vessels,
        filteredPorts: ports,
        zoomLocation: null
      };
    }

    const searchId = +quickSearchItemId;
    let zoomLocation = null;

    const vesselFilters = {
      [QUICK_SEARCH_ITEM_TYPE.VESSEL]: (vessel) => vessel.imo === searchId,
      [QUICK_SEARCH_ITEM_TYPE.OPERATOR]: (vessel) => vessel.operatorId === searchId,
      [QUICK_SEARCH_ITEM_TYPE.OWNER]: (vessel) => vessel.ownerId === searchId
    };

    const portFilters = {
      [QUICK_SEARCH_ITEM_TYPE.COUNTRY]: (port) => port.country?.id === searchId,
      [QUICK_SEARCH_ITEM_TYPE.PORT]: (port) => port.id === searchId
    };

    let filteredVessels = vessels;
    let filteredPorts = ports;

    // Handle vessel search
    if (quickSearchItemType === QUICK_SEARCH_ITEM_TYPE.VESSEL) {
      filteredVessels = vessels.filter(vesselFilters[quickSearchItemType]);
      const targetVessel = filteredVessels[0];
      if (targetVessel) {
        zoomLocation = null;
      }
    }
    // Handle port search
    else if (quickSearchItemType === QUICK_SEARCH_ITEM_TYPE.PORT) {
      filteredPorts = ports.filter(portFilters[quickSearchItemType]);
      const targetPort = filteredPorts[0];
      if (targetPort) {
        zoomLocation = {
          lat: targetPort.cpointlat,
          lng: targetPort.cpointlon,
          zoom: ZOOM_LEVELS.SINGLE_LOCATION
        };
      }
      filteredVessels = vessels; // Show all vessels
    }
    // Handle country search
    else if (quickSearchItemType === QUICK_SEARCH_ITEM_TYPE.COUNTRY) {
      filteredPorts = ports.filter(portFilters[quickSearchItemType]);
      if (filteredPorts.length) {
        // Calculate country bounds from ports
        const lats = filteredPorts.map((p) => p.cpointlat);
        const lngs = filteredPorts.map((p) => p.cpointlon);
        zoomLocation = {
          lat: (Math.min(...lats) + Math.max(...lats)) / 2,
          lng: (Math.min(...lngs) + Math.max(...lngs)) / 2,
          zoom: ZOOM_LEVELS.COUNTRY
        };
      }
      filteredVessels = vessels; // Show all vessels
    }
    // Handle operator/owner search
    else if (vesselFilters[quickSearchItemType]) {
      filteredVessels = vessels.filter(vesselFilters[quickSearchItemType]);
      zoomLocation = null;
    }

    return {
      filteredVessels,
      filteredPorts,
      zoomLocation
    };
  }

  if (!isInboundFilterApplicable && !isAdvancedFilterApplicable && !isFleetFilterApplicable) {
    return {
      filteredVessels: vessels,
      filteredPorts: ports,
      zoomLocation: null
    };
  }

  const dashboardActivityMapFilterInitialValues =
    getDashboardActivityMapFilterInitialValues(isRoRo);
  const locations = (activeFilters?.selectedLocations || []).filter(Boolean);

  // Memoize the filter parameters with safe defaults
  const memoizedParams = {
    activeFilters: activeFilters || {},
    isRoRo: isRoRo || false,
    initialValues: dashboardActivityMapFilterInitialValues || {},
    selectedFleets: selectedFleets || new Set(),
    filterType: filterType || FILTER_TYPES.ALL
  };

  // Create filter functions with null check
  const filterFunctions = createVesselFilterFunctions(
    memoizedParams.activeFilters,
    memoizedParams.initialValues,
    memoizedParams.isRoRo
  );

  // Main filtering logic with null checks
  let filteredVessels = (() => {
    if (!vessels || !filterFunctions || !Object.keys(filterFunctions).length) {
      return vessels || [];
    }

    let filtered = vessels;

    // Apply fleet filtering if there are selected fleets
    if (isFleetFilterApplicable) {
      const fleetVesselMap = new Map();

      // Create a map of IMO numbers to fleet colors and names
      Array.from(selectedFleets).forEach((fleet) => {
        if (fleet?.vessels) {
          fleet.vessels.forEach((vessel) => {
            // Store both vesselImo and imo to handle both formats
            const vesselId = vessel.vesselImo || vessel.imo;
            fleetVesselMap.set(vesselId, {
              color: fleet.color,
              fleetName: fleet.name
            });
          });
        }
      });

      // Add fleet colors and names to vessels that are in selected fleets
      filtered = filtered.map((vessel) => {
        // Check both imo and vesselImo
        const vesselId = vessel.vesselImo || vessel.imo;
        const fleetInfo = fleetVesselMap.get(vesselId);
        return {
          ...vessel,
          color: fleetInfo?.color || vessel.color,
          fleetName: fleetInfo?.fleetName
        };
      });

      // Only show vessels that are in selected fleets
      filtered = filtered.filter((vessel) => {
        // Check both imo and vesselImo
        const vesselId = vessel.vesselImo || vessel.imo;
        return fleetVesselMap.has(vesselId);
      });

      // Add debug logging to help diagnose issues
      console.debug('Fleet filtering debug:', {
        totalVessels: vessels.length,
        filteredCount: filtered.length,
        fleetMapSize: fleetVesselMap.size,
        sampleVessel: filtered[0],
        sampleFleetMapEntry: Array.from(fleetVesselMap.entries())[0]
      });
    }

    // Apply other filters
    if (isAdvancedFilterApplicable || isInboundFilterApplicable) {
      filtered = filtered.filter(
        (vessel) =>
          filterFunctions.vesselFilter?.(vessel) &&
          filterFunctions.ownerFilter?.(vessel) &&
          filterFunctions.operatorTypeFilter?.(vessel) &&
          filterFunctions.commercialCategoryFilter?.(vessel) &&
          filterFunctions.capacityFilter?.(vessel) &&
          filterFunctions.buildYearFilter?.(vessel) &&
          filterFunctions.deadweightFilter?.(vessel) &&
          filterFunctions.grossTonnageFilter?.(vessel) &&
          filterFunctions.draughtFilter?.(vessel) &&
          filterFunctions.draughtPercentageFilter?.(vessel) &&
          filterFunctions.cranesFilter?.(vessel) &&
          filterFunctions.beamFilter?.(vessel) &&
          filterFunctions.lengthFilter?.(vessel) &&
          (filterType === FILTER_TYPES.INBOUND ? filterFunctions.etaFilter?.(vessel) : true)
      );
    }

    return filtered;
  })();

  let filteredPorts = ports;

  // Handle inbound vessels filtering
  if (isInboundFilterApplicable) {
    // Create a map of colors for each destination
    const destinationColors = new Map();

    locations.forEach((location, index) => {
      // Use color from palette, cycling through if needed
      const color = INBOUND_VESSELS_COLOR_PALETTE[index % INBOUND_VESSELS_COLOR_PALETTE.length];
      destinationColors.set(location.id, color);
    });

    filteredPorts = ports.filter((port) => {
      const matchingLocation = locations.find((location) => {
        if (location.type === 'Country') {
          return port.country.id === location.id;
        }
        return port.id === location.id;
      });

      if (matchingLocation) {
        // Assign color from palette to matching ports
        port.color = destinationColors.get(matchingLocation.id);
      }

      const hasInboundVessel = filteredVessels.some(
        (vessel) => vessel.destinationPortId === port.id
      );

      return matchingLocation && hasInboundVessel;
    });

    const filteredPortIds = new Set(filteredPorts.map((port) => port.id));

    // Assign same colors to vessels based on their destination ports
    filteredVessels = filteredVessels.filter((vessel) => {
      if (filteredPortIds.has(vessel.destinationPortId)) {
        const destinationPort = filteredPorts.find((port) => port.id === vessel.destinationPortId);
        vessel.color = destinationPort?.color;
        return true;
      }
      return false;
    });
  }

  let zoomLocation = null;

  return {
    filteredVessels,
    filteredPorts,
    zoomLocation
  };
};
