import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Paper,
  Typography,
  NotFound,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack
} from '@esgian/esgianui';
import PortOperatorStatsTable from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortOperatorStatisticsSection/PortOperatorStatsTable';
import PortCallShareChart from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortOperatorStatisticsSection/PortCallShareChart';
import moment from 'moment/moment';
import { useQuery } from '@tanstack/react-query';
import { GEOGRAPHICAL_TYPES } from '@constants';
import { getOperatorYTDStats } from '@api';
import { useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useParams } from 'react-router-dom';

const getChartData = (key, operatorStats) => {
  const sortedOperators = operatorStats.sort((a, b) => b[key] - a[key]);

  const top10Shares = sortedOperators.slice(0, 10).map((operator) => operator[key]);

  let otherShares = 0;
  if (sortedOperators.length > 10) {
    otherShares = sortedOperators.slice(10).reduce((sum, operator) => sum + operator[key], 0);
  }

  const top10Operators = sortedOperators.slice(0, 10).map((operator) => operator.operatorShortName);

  if (sortedOperators.length > 10) {
    top10Operators.push('Others');
    top10Shares.push(otherShares);
  }
  return { top10Operators, top10Shares };
};

function PortOperatorStatisticsSection({ loading, portName }) {
  const [ytdChartData, setYtdChartData] = useState({ series: [], top10: [] });
  const [prevYtdChartData, setPrevYtdChartData] = useState({ series: [], top10: [] });
  const [noData, setNoData] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const endDate = moment();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const params = useParams();
  let startDate = moment('2022-01-01');
  const { segment } = useSegment();

  const handleSelectYear = useCallback(({ target }) => {
    setSelectedYear(target.value);
  }, []);
  const years = useMemo(() => {
    let res = [];
    for (let i = startDate.year(); i <= endDate.year(); i++) {
      res.push(i);
    }
    return res;
  }, []);
  const portOperatorsQuery = useQuery({
    queryKey: [
      'port',
      'port-operator-statistics',
      segment.id,
      operatorType,
      commercialCategoryType,
      params,
      selectedYear
    ],
    enabled: !!startDate && !!endDate,
    placeholderData: null,
    queryFn: ({ queryKey, signal }) => {
      const body = {
        FromType: GEOGRAPHICAL_TYPES.PORT,
        FromId: [params.portId],
        Year: selectedYear,
        SegmentTypeId: queryKey[2],
        OperatorTypeId: queryKey[3],
        commercialCategoryId: queryKey[4]
      };
      return getOperatorYTDStats(body, signal)
        .then((results) => results)
        .catch(() => []);
    }
  });
  const operatorStats = portOperatorsQuery?.data;
  useEffect(() => {
    if (!operatorStats) {
      setYtdChartData({ series: [], top10: [] });
      setPrevYtdChartData({ series: [], top10: [] });
      setNoData(true);
      return;
    }

    let { top10Operators: ytdOperators, top10Shares: ytdShares } = getChartData(
      'ytdPortCallsShare',
      operatorStats
    );
    let { top10Operators: prevYtdOperators, top10Shares: prevYtdShares } = getChartData(
      'prevYearYTDPortCallsShare',
      operatorStats
    );
    setYtdChartData({ series: ytdShares, top10: ytdOperators });
    setPrevYtdChartData({ series: prevYtdShares, top10: prevYtdOperators });
    setNoData(false);
  }, [operatorStats, selectedYear]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, height: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'h6'}>Top Operators and Share of Port Calls</Typography>
                <FormControl sx={{ minWidth: 120 }} size="small">
                  <InputLabel id="top-port-year-label">Year</InputLabel>
                  <Select
                    id="top-port-year-select"
                    value={selectedYear}
                    label="Year"
                    onChange={handleSelectYear}>
                    {years?.map((year) => (
                      <MenuItem key={`select-${year}`} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {noData ? (
                  <NotFound
                    header={'No Data'}
                    message={'No data available for the selected year'}
                    show={true}
                  />
                ) : (
                  <PortCallShareChart
                    categories={prevYtdChartData.top10}
                    seriesData={prevYtdChartData.series}
                    selectedYear={selectedYear - 1}
                    loading={loading}
                    fileName={`operator-stats-${portName?.toLowerCase().split(' ').join('-')}`}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                {noData ? (
                  <NotFound
                    header={'No Data'}
                    message={'No data available for the selected year'}
                    show={true}
                  />
                ) : (
                  <PortCallShareChart
                    categories={ytdChartData.top10}
                    seriesData={ytdChartData.series}
                    selectedYear={selectedYear}
                    loading={loading}
                    fileName={`operator-stats-${portName?.toLowerCase().split(' ').join('-')}`}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{ height: '100%' }}>
        <PortOperatorStatsTable
          selectedYear={selectedYear}
          operatorStats={operatorStats}
          loading={loading}
          fileName={`operator-stats-${portName?.toLowerCase().split(' ').join('-')}`}
        />
      </Grid>
    </Grid>
  );
}

PortOperatorStatisticsSection.propTypes = {
  loading: PropTypes.bool,
  portName: PropTypes.string,
  operatorStats: PropTypes.arrayOf(PropTypes.object),
  selectedYear: PropTypes.number.isRequired
};

PortOperatorStatisticsSection.defaultProps = {
  loading: false,
  portName: '',
  operatorStats: null
};

export default PortOperatorStatisticsSection;
