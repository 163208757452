import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DashboardActivityMap } from '../../../Maps';
import { useDynamicLookupQueries } from '@hooks';
import { useFilteredVessels } from './hooks/useFilteredVessels';
import { useVesselsLatestPositionByTypes } from '../../../../hooks';

function DashboardActivityMapSection({ isCompactView, onClickFullscreen }) {
  const [activeFilters, setActiveFilters] = useState(null);
  const [selectedFleets, setSelectedFleets] = useState(new Set());
  const {
    lookupPortsQuery: { data: lookupPorts }
  } = useDynamicLookupQueries();

  const { data: vesselsLatestPosition, isPending: isLoadingVesselsLatestPosition } =
    useVesselsLatestPositionByTypes();

  const { filteredVessels, filteredPorts, zoomLocation } = useFilteredVessels(
    vesselsLatestPosition,
    lookupPorts,
    activeFilters,
    selectedFleets
  );

  const vesselsToFilter = vesselsLatestPosition?.map((vessel) => ({
    vesselName: vessel.vesselName,
    imo: vessel.imo,
    operator: vessel.operatorShortName
  }));

  const handleSetSelectedFleets = useCallback((newFleets) => {
    setSelectedFleets(newFleets);
  }, []);

  return (
    <DashboardActivityMap
      vesselsLatestPosition={vesselsLatestPosition}
      isCompactView={isCompactView}
      zoomLocation={zoomLocation}
      onClickFullscreen={onClickFullscreen}
      isDisableMapActions={isLoadingVesselsLatestPosition}
      filteredVessels={filteredVessels}
      filteredPorts={filteredPorts}
      selectedFleets={selectedFleets}
      setSelectedFleets={handleSetSelectedFleets}
      activeFilters={activeFilters}
      setActiveFilters={setActiveFilters}
      vesselsToFilter={vesselsToFilter}
    />
  );
}

DashboardActivityMapSection.propTypes = {
  isCompactView: PropTypes.bool,
  onClickFullscreen: PropTypes.func
};

export default DashboardActivityMapSection;
