import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import {
  ACTIVITY_MAP_SEARCH_PARAM_KEYS,
  SIDE_PANEL_TYPES,
  SIDE_PANEL_STATES,
  FILTER_TYPES
} from '../constants';
import {
  QUICK_SEARCH_ITEM_TYPE,
  searchItemTypeToSidePanelType
} from '../DashboardActivityMapUtils';

export const useMapState = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const quickSearchItemType = searchParams.get(
    ACTIVITY_MAP_SEARCH_PARAM_KEYS.QUICK_SEARCH_ITEM_TYPE
  );
  const quickSearchItemId = searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.QUICK_SEARCH_ITEM_ID);

  const filterType =
    searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.FILTER_TYPE) || FILTER_TYPES.ALL;

  const setQuickSearch = (id, type) => {
    setSearchParams((prev) => {
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.FILTER_TYPE, FILTER_TYPES.QUICK_SEARCH);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.QUICK_SEARCH_ITEM_TYPE, type);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.QUICK_SEARCH_ITEM_ID, id);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE, searchItemTypeToSidePanelType(type));
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID, id);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE, SIDE_PANEL_STATES.OPEN);

      return prev;
    });
  };

  const resetQuickSearch = () => {
    setSearchParams((prev) => {
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.FILTER_TYPE);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.QUICK_SEARCH_ITEM_TYPE);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.QUICK_SEARCH_ITEM_ID);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE);
      return prev;
    });
  };

  const setAdvancedFilter = () => {
    setSearchParams((prev) => {
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.FILTER_TYPE, FILTER_TYPES.ADVANCED);
      return prev;
    });
  };

  const setInboundSearch = () => {
    setSearchParams((prev) => {
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.FILTER_TYPE, FILTER_TYPES.INBOUND);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE, SIDE_PANEL_TYPES.INSIGHT);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE, SIDE_PANEL_STATES.OPEN);
      // prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS, 'true');
      return prev;
    });
  };

  const resetInboundSearch = () => {
    setSearchParams((prev) => {
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.FILTER_TYPE);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS);
      return prev;
    });
  };

  const setFleetSearch = () => {
    setSearchParams((prev) => {
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.FILTER_TYPE, FILTER_TYPES.FLEET);
      return prev;
    });
  };

  const resetSearch = () => {
    setSearchParams((prev) => {
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.FILTER_TYPE, FILTER_TYPES.ALL);
      return prev;
    });
  };

  const handleVesselSelect = (vesselImo) => {
    setSearchParams((prev) => {
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID, vesselImo);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE, SIDE_PANEL_TYPES.VESSEL_DETAILS);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE, SIDE_PANEL_STATES.OPEN);
      return prev;
    });
  };

  const handlePortSelect = (port) => {
    setSearchParams((prev) => {
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID, port.id);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE, SIDE_PANEL_TYPES.PORT_DETAILS);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE, SIDE_PANEL_STATES.OPEN);
      return prev;
    });
  };

  const handleInsightClick = () => {
    setSearchParams((prev) => {
      prev.set(
        ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE,
        searchItemTypeToSidePanelType(quickSearchItemType)
      );
      if (quickSearchItemId) {
        prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID, quickSearchItemId);
      }
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE, SIDE_PANEL_STATES.OPEN);
      return prev;
    });
  };

  const handleSidePanelClose = () => {
    setSearchParams((prev) => {
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE);
      return prev;
    });
  };

  const handleActionClick = (action) => {
    setSearchParams((prev) => {
      if (!action) {
        prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_MAP_ACTION);
        return prev;
      }
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_MAP_ACTION, action);
      return prev;
    });
  };

  const handleActionClose = () => {
    setSearchParams((prev) => {
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_MAP_ACTION);
      return prev;
    });
  };

  const handleActionSettingsChange = (action, value) => {
    setSearchParams((prev) => {
      prev.set(action, value);
      return prev;
    });
  };

  const { sidePanelState, sidePanelType, sidePanelItemId, mapSettings, selectedMapAction } =
    useMemo(
      () => ({
        sidePanelState: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE),
        sidePanelType: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE),
        sidePanelItemId: +searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID),

        isSidePanelOpen: sidePanelState === SIDE_PANEL_STATES.OPEN,

        mapSettings: {
          showPorts:
            searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS) === 'true' ||
            searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.FILTER_TYPE) === FILTER_TYPES.INBOUND ||
            (searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.FILTER_TYPE) ===
              FILTER_TYPES.QUICK_SEARCH &&
              (searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.QUICK_SEARCH_ITEM_TYPE) ===
                QUICK_SEARCH_ITEM_TYPE.PORT ||
                searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.QUICK_SEARCH_ITEM_TYPE) ===
                  QUICK_SEARCH_ITEM_TYPE.COUNTRY)),
          showVesselsNames:
            searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_NAMES) ===
            'true',

          showVessels:
            searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS) !== 'false',
          showVesselsStatus:
            searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_STATUS) !==
            'false',
          showPortNames: true
        },
        selectedMapAction: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_MAP_ACTION)
      }),
      [searchParams]
    );

  return {
    sidePanelState,
    sidePanelType,
    sidePanelItemId,
    mapSettings,
    selectedMapAction,
    filterType,
    setQuickSearch,
    resetQuickSearch,
    setAdvancedFilter,
    setInboundSearch,
    resetInboundSearch,
    setFleetSearch,
    resetSearch,
    handleVesselSelect,
    handlePortSelect,
    handleInsightClick,
    handleSidePanelClose,
    handleActionClick,
    handleActionClose,
    handleActionSettingsChange,
    quickSearchItemType,
    quickSearchItemId
  };
};
