import React from 'react';
import { VesselIcon } from '@components/Icons';

import { formatToDecimalPlaces } from '@helpers';
import {
  VESSEL_DEFAULT_ICON_COLOR,
  LOW_SPEED_VESSEL_ICON_COLOR,
  MEDIUM_SPEED_VESSEL_ICON_COLOR,
  HIGH_SPEED_VESSEL_ICON_COLOR,
  FLEET_COLOR_PALETTE,
  FILTER_TYPES,
  INBOUND_VESSELS_COLOR_PALETTE
} from '../constants';

const getPopupBackground = (themeMode) => {
  return themeMode ? 'background: rgba(255, 255, 255, 0.8)' : 'background: rgba(11,26,46,0.7)';
};

export const createVesselMarkerElement = (vessel) => {
  const { speed, heading, course, showVesselsStatus, destinationPortId } = vessel;

  const markerColor = getVesselColor({
    speed,
    showVesselsStatus,
    destinationPortId
  });

  return (
    <div
      style={{
        transform: `rotateZ(${heading || course}deg)`,
        cursor: 'pointer'
      }}>
      <VesselIcon color={markerColor} size={12} />
    </div>
  );
};

export const createPopupContent = (vessel, themeMode, getTimeZoneDisplay) => {
  const {
    vesselName,
    timestamp,
    operatorShortName,
    destinationPortName,
    destinationPortUnlocode,
    percentDraught,
    draught,
    vesselDraught,
    eta,
    fleetName
  } = vessel;

  const background = getPopupBackground(themeMode);
  const textColor = background.includes('rgba(255, 255, 255') ? '#000' : '#fff';

  const createRow = (label, value) => (
    <div
      key={label}
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        flexWrap: 'nowrap',
        flexShrink: 0,
        position: 'relative'
      }}>
      <span
        style={{
          flexShrink: 0,
          flexBasis: 'auto',
          position: 'relative',
          height: '16px',
          color: textColor,
          fontFamily: 'Noto Sans, var(--default-font-family)',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          letterSpacing: '0.4px'
        }}>
        {label}
      </span>
      <span
        style={{
          flexShrink: 0,
          flexBasis: 'auto',
          position: 'relative',
          height: '16px',
          color: textColor,
          fontFamily: 'Noto Sans, var(--default-font-family)',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          letterSpacing: '0.4px'
        }}>
        {value}
      </span>
    </div>
  );

  const rows = [
    ...(fleetName
      ? [
          {
            label: 'Fleet',
            value: fleetName
          }
        ]
      : []),
    {
      label: 'ETA',
      value: eta ? getTimeZoneDisplay(eta) : '-'
    },
    {
      label: 'Destination',
      value:
        destinationPortName && destinationPortUnlocode
          ? `${destinationPortName} (${destinationPortUnlocode})`
          : '-'
    },
    {
      label: 'Position Received',
      value: timestamp ? getTimeZoneDisplay(timestamp) : '-'
    },
    {
      label: 'Operator',
      value: operatorShortName ?? '-'
    },
    {
      label: 'Draught',
      value:
        draught && vesselDraught && percentDraught
          ? `${formatToDecimalPlaces(draught, 1)} / ${formatToDecimalPlaces(
              vesselDraught,
              1
            )} (${formatToDecimalPlaces(percentDraught, 1)}%)`
          : '-'
    }
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap',
        gap: '16px',
        position: 'relative',
        width: '300px',
        margin: '0 auto',
        padding: '4px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          flexWrap: 'nowrap',
          flexShrink: 0,
          gap: '4px',
          position: 'relative'
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            flexWrap: 'nowrap',
            flexShrink: 0,
            gap: '8px',
            position: 'relative',
            zIndex: 1
          }}>
          <span
            style={{
              flexShrink: 0,
              flexBasis: 'auto',
              position: 'relative',
              height: '24px',
              color: textColor,
              fontFamily: 'Noto Sans, var(--default-font-family)',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '24px',
              textAlign: 'left',
              whiteSpace: 'nowrap',
              letterSpacing: '0.1px'
            }}>
            {vesselName}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            flexWrap: 'nowrap',
            flexShrink: 0,
            gap: '8px',
            position: 'relative',
            overflow: 'hidden'
          }}>
          {rows.map((row) => createRow(row.label, row.value))}
        </div>
      </div>
    </div>
  );
};

export const createNamePopupContent = (name) => {
  return (
    <span
      style={{
        padding: '0 6px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '2px',
        color: '#000',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
      {name}
    </span>
  );
};

export const createVesselIcons = (map) => {
  const createVesselIcon = (color) => {
    const size = 32;
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');
    if (!ctx) return null;

    // Enable crisp edges
    ctx.imageSmoothingEnabled = false;

    // Draw vessel shape
    ctx.beginPath();
    ctx.moveTo(6, 24); // Bottom-left point
    ctx.lineTo(12, 4.17188); // Top point
    ctx.lineTo(18, 24); // Bottom-right point
    ctx.lineTo(12, 18); // Inner bottom point
    ctx.closePath();

    // Fill the shape with the specified color
    ctx.fillStyle = color;
    ctx.fill();

    // Add a white stroke
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.lineJoin = 'round';
    ctx.stroke();

    // Return the image data instead of the canvas
    return ctx.getImageData(0, 0, size, size);
  };

  // Add vessel icons for different speeds/states
  const defaultIcon = createVesselIcon(VESSEL_DEFAULT_ICON_COLOR);
  const slowIcon = createVesselIcon(LOW_SPEED_VESSEL_ICON_COLOR);
  const mediumIcon = createVesselIcon(MEDIUM_SPEED_VESSEL_ICON_COLOR);
  const fastIcon = createVesselIcon(HIGH_SPEED_VESSEL_ICON_COLOR);

  if (defaultIcon) map.addImage('vessel-default', defaultIcon);
  if (slowIcon) map.addImage('vessel-slow', slowIcon);
  if (mediumIcon) map.addImage('vessel-medium', mediumIcon);
  if (fastIcon) map.addImage('vessel-fast', fastIcon);

  for (const item of FLEET_COLOR_PALETTE) {
    const icon = createVesselIcon(item);
    if (icon) map.addImage(`vessel-${item}`, icon);
  }
};

export const getVesselColor = ({ vessel, showVesselsStatus, inboundVesselsLocations }) => {
  const { speed, destinationPortId, lookupPorts, fleetColor } = vessel;
  const hasInboundVesselsLocations =
    inboundVesselsLocations && inboundVesselsLocations?.some(Boolean);
  if (hasInboundVesselsLocations) {
    const matchedPortIndex = inboundVesselsLocations.findIndex(
      (location) => location.type === 'Port' && location.id === destinationPortId
    );
    if (matchedPortIndex !== -1) {
      return INBOUND_VESSELS_COLOR_PALETTE[matchedPortIndex];
    }
    const matchedCountryIndex = inboundVesselsLocations.findIndex((location) =>
      lookupPorts?.some((port) => port.id === destinationPortId && location.id === port.country.id)
    );
    if (matchedCountryIndex !== -1) {
      return INBOUND_VESSELS_COLOR_PALETTE[matchedCountryIndex];
    }
    return INBOUND_VESSELS_COLOR_PALETTE[0];
  }
  if (fleetColor) return fleetColor;
  if (!showVesselsStatus) return VESSEL_DEFAULT_ICON_COLOR;
  if (speed >= 0 && speed < 1) return LOW_SPEED_VESSEL_ICON_COLOR;
  if (speed > 1 && speed <= 3) return MEDIUM_SPEED_VESSEL_ICON_COLOR;
  return HIGH_SPEED_VESSEL_ICON_COLOR;
};

export const createVesselGeoJson = (vessels, isFilterApplied, shouldShowVesselColor) => {
  return {
    type: 'FeatureCollection',
    features: vessels.map((vessel) => {
      const color = vessel.color || VESSEL_DEFAULT_ICON_COLOR;

      let iconImage = 'vessel-default';
      if (isFilterApplied) {
        iconImage = `vessel-${color}`;
      } else if (shouldShowVesselColor) {
        if (vessel.speed < 1) iconImage = 'vessel-slow';
        else if (vessel.speed <= 3) iconImage = 'vessel-medium';
        else iconImage = 'vessel-fast';
      }

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [vessel.long, vessel.lat]
        },
        properties: {
          ...vessel,
          iconImage,
          rotation: vessel.heading || vessel.course || 0,
          color
        }
      };
    })
  };
};

export const createPortIcons = (map) => {
  const createPortIcon = (color) => {
    const size = 24; // Smaller overall size
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');
    if (!ctx) return null;

    ctx.imageSmoothingEnabled = true;

    // Scale to match new size
    const scale = size / 24;
    ctx.scale(scale, scale);

    // Draw the teardrop/pin shape
    ctx.beginPath();
    ctx.moveTo(12, 22); // Bottom point
    // Left side curve
    ctx.bezierCurveTo(11, 22, 4, 15, 4, 9);
    // Top arc
    ctx.arc(12, 9, 8, Math.PI, 0, false);
    // Right side curve
    ctx.bezierCurveTo(20, 9, 13, 22, 12, 22);
    ctx.closePath();

    // Fill and stroke the pin shape
    ctx.fillStyle = color;
    ctx.fill();
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.stroke();

    // Draw the inner circle
    ctx.beginPath();
    ctx.arc(12, 9, 3, 0, Math.PI * 2);
    ctx.fillStyle = '#FFFFFF'; // White center
    ctx.fill();

    return ctx.getImageData(0, 0, size, size);
  };
  const portIcon = createPortIcon('#4A90E2');
  if (portIcon) map.addImage('harbor-default', portIcon);

  for (const color of INBOUND_VESSELS_COLOR_PALETTE) {
    const icon = createPortIcon(color);
    if (icon) map.addImage(`harbor-${color}`, icon);
  }
};

export const createPortGeoJson = (ports, filterType) => {
  return {
    type: 'FeatureCollection',
    features: ports.map((port) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [port.cpointlon, port.cpointlat]
      },
      properties: {
        ...port,
        iconImage: `harbor-${filterType === FILTER_TYPES.INBOUND ? port.color : 'default'}`,
        color: filterType === FILTER_TYPES.INBOUND ? port.color : 'default'
      }
    }))
  };
};
