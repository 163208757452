import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@esgian/esgianui';
import DashboardActivityMapMySave from './DashboardActivityMapMySave';
import DashboardActivityMapFilter from './DashboardActivityMapFilter';
import DashboardActivityMapMapSettings from './DashboardActivityMapSettings';
import { ACTIVITY_MAP_ACTIONS } from '../DashboardActivityMapFloatingComponents/DashboardActivityMapActions';
import { FLOATING_WINDOW_WIDTH } from '../DashboardActivityMap';
import DashboardActivityMapMyFleet from './DashboardActivityMapMyFleet';

const DashboardActivityMapActionWindow = ({
  selectedAction,
  onClose,
  filterProps,
  mapSettingsProps,
  inboundVesselsFilterProps,
  vesselsLatestPosition = [],
  selectedFleets,
  setSelectedFleets
}) => {
  const renderDrawerContent = () => {
    switch (selectedAction) {
      case ACTIVITY_MAP_ACTIONS.MY_SAVE:
        return <DashboardActivityMapMySave onClose={onClose} />;
      case ACTIVITY_MAP_ACTIONS.ADVANCED_FILTER:
        return <DashboardActivityMapFilter onClose={onClose} {...filterProps} />;
      case ACTIVITY_MAP_ACTIONS.MAP_SETTINGS:
        return <DashboardActivityMapMapSettings onClose={onClose} {...mapSettingsProps} />;
      case ACTIVITY_MAP_ACTIONS.INBOUND_VESSELS_FILTER:
        return <DashboardActivityMapFilter onClose={onClose} {...inboundVesselsFilterProps} />;
      case ACTIVITY_MAP_ACTIONS.MY_FLEET:
        return (
          <DashboardActivityMapMyFleet
            onClose={onClose}
            vessels={vesselsLatestPosition}
            selectedFleets={selectedFleets}
            setSelectedFleets={setSelectedFleets}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box height="100%" width={FLOATING_WINDOW_WIDTH}>
      {renderDrawerContent()}
    </Box>
  );
};

DashboardActivityMapActionWindow.propTypes = {
  selectedAction: PropTypes.string,
  onClose: PropTypes.func,
  filterProps: PropTypes.object,
  mapSettingsProps: PropTypes.object,
  inboundVesselsFilterProps: PropTypes.object,
  vesselsLatestPosition: PropTypes.array,
  selectedFleets: PropTypes.instanceOf(Set),
  setSelectedFleets: PropTypes.func
};

export default DashboardActivityMapActionWindow;
