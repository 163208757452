import { BaseApiRequest } from '@api/BaseApi';

export const SAVED_FLEETS_KEY = 'savedFleets';

export const getSavedFleets = (email, signal) => {
  return BaseApiRequest(
    signal,
    `/UserData/${email}/${SAVED_FLEETS_KEY}?api-version=1.0`,
    'users',
    'GET'
  );
};

export const updateFleet = (email, updatedData, signal) => {
  const body = {
    userEmail: email,
    dataKey: SAVED_FLEETS_KEY,
    userInformation: JSON.stringify(updatedData)
  };
  return BaseApiRequest(signal, `/UserData?api-version=1.0`, 'users', 'PUT', body, {
    'Content-type': 'application/json'
  });
};
