export const SIDE_PANEL_STATES = {
  OPEN: 'open',
  CLOSED: 'closed'
};

export const SIDE_PANEL_TYPES = {
  VESSEL_DETAILS: 'vesselDetails',
  INSIGHT: 'insight',
  OPERATOR_DETAILS: 'operatorDetails',
  OWNER_DETAILS: 'ownerDetails',
  PORT_DETAILS: 'portDetails',
  COUNTRY_DETAILS: 'countryDetails'
};

export const FILTER_TYPES = {
  QUICK_SEARCH: 'quickSearch',
  ADVANCED: 'advanced',
  INBOUND: 'inbound',
  FLEET: 'fleet',
  ALL: 'all'
};

export const ACTIVITY_MAP_SEARCH_PARAM_KEYS = {
  FILTER_TYPE: 'filterType',
  SIDE_PANEL_STATE: 'sidePanelState',
  SIDE_PANEL_TYPE: 'sidePanelType',
  SIDE_PANEL_ITEM_ID: 'sidePanelItemId',
  QUICK_SEARCH_ITEM_TYPE: 'quickSearchItemType',
  QUICK_SEARCH_ITEM_ID: 'quickSearchItemId',
  MAP_SETTINGS: {
    SHOW_PORTS: 'showPorts',
    SHOW_VESSELS_NAMES: 'showVesselsNames',
    SHOW_VESSELS_STATUS: 'showVesselsStatus',
    SHOW_VESSELS: 'showVessels',
    SHOW_PORT_NAMES: 'showPortNames'
  },
  SELECTED_MAP_ACTION: 'selectedMapAction'
};

export const MAX_VESSELS_TO_SHOW_NAME = 150;

export const INBOUND_VESSELS_COLOR_PALETTE = ['#1480FF', '#B965CE', '#90D8CB', '#EF9FB2'];

export const VESSEL_DEFAULT_ICON_COLOR = '#0E9EFF';
export const LOW_SPEED_VESSEL_ICON_COLOR = '#FF0000';
export const MEDIUM_SPEED_VESSEL_ICON_COLOR = '#FFA500';
export const HIGH_SPEED_VESSEL_ICON_COLOR = '#32CD32';

export const FLEET_COLOR_PALETTE = [
  // Blues
  '#1480FF', // Original blue
  '#4169E1', // Royal Blue
  '#00CED1', // Dark Turquoise
  '#20B2AA', // Light Sea Green
  '#87CEEB', // Sky Blue

  // Purples
  '#B965CE', // Original purple
  '#9370DB', // Medium Purple
  '#8A2BE2', // Blue Violet
  '#9932CC', // Dark Orchid
  '#BA55D3', // Medium Orchid

  // Greens
  '#90D8CB', // Original green
  '#32CD32', // Lime Green
  '#3CB371', // Medium Sea Green
  '#2E8B57', // Sea Green
  '#66CDAA', // Medium Aquamarine

  // Reds/Pinks
  '#EF9FB2', // Original pink
  '#FF69B4', // Hot Pink
  '#FF1493', // Deep Pink
  '#DC143C', // Crimson
  '#FF4500', // Orange Red

  // Oranges/Yellows
  '#FFA500', // Orange
  '#FFD700', // Gold
  '#DAA520', // Goldenrod
  '#F4A460', // Sandy Brown
  '#FF8C00' // Dark Orange
];
