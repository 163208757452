import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, TextField, Typography } from '@esgian/esgianui';
import { FLEET_COLOR_PALETTE } from '@helpers/dashboardActivityMapHelpers';
import { CircularProgress } from '@esgian/esgianui';

const CreateFleetModal = ({
  open,
  onClose,
  onSubmit,
  onDelete,
  isSubmitting,
  editFleet = null
}) => {
  const [name, setName] = useState(editFleet?.name || '');
  const [error, setError] = useState('');
  const [selectedColor, setSelectedColor] = useState(editFleet?.color || FLEET_COLOR_PALETTE[0]);
  const MAX_NAME_LENGTH = 30;

  useEffect(() => {
    if (editFleet) {
      setName(editFleet.name);
      setSelectedColor(editFleet.color);
    } else {
      setName('');
      setSelectedColor(FLEET_COLOR_PALETTE[0]);
    }
  }, [editFleet]);

  const handleSubmit = () => {
    if (!name.trim()) {
      setError('Fleet name is required');
      return;
    }
    onSubmit({ name: name.trim(), color: selectedColor });
    setName('');
    setError('');
    setSelectedColor(FLEET_COLOR_PALETTE[0]);
  };

  const handleDelete = () => {
    onDelete(editFleet.id);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" mb={2}>
          {editFleet ? 'Edit Fleet' : 'Create New Fleet'}
        </Typography>
        <TextField
          fullWidth
          label="Fleet Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value.slice(0, MAX_NAME_LENGTH));
            setError('');
          }}
          error={!!error}
          helperText={error}
          sx={{ mb: 2 }}
          placeholder="Enter fleet name..."
        />

        <Typography variant="subtitle2" mb={1}>
          Fleet Color
        </Typography>
        <Box display="flex" gap={1} flexWrap="wrap" mb={2}>
          {FLEET_COLOR_PALETTE.map((color) => (
            <Box
              key={color}
              onClick={() => setSelectedColor(color)}
              sx={{
                width: 24,
                height: 24,
                borderRadius: '50%',
                backgroundColor: color,
                cursor: 'pointer',
                position: 'relative',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                outline: selectedColor === color ? '2px solid white' : 'none',
                boxShadow: selectedColor === color ? '0 0 0 4px rgba(0, 0, 0, 0.2)' : 'none',
                '&:hover': {
                  transform: 'scale(1.1)',
                  transition: 'transform 0.2s'
                }
              }}
            />
          ))}
        </Box>

        <Box display="flex" justifyContent="space-between" gap={1}>
          {editFleet ? (
            <Button onClick={handleDelete} disabled={isSubmitting} color="error" variant="outlined">
              Delete Fleet
            </Button>
          ) : (
            <Box />
          )}
          <Box display="flex" gap={1}>
            <Button onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={isSubmitting || !name.trim() || (editFleet && name === editFleet.name)}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}>
              {editFleet ? 'Save' : 'Create'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

CreateFleetModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isSubmitting: PropTypes.bool,
  editFleet: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  })
};

export default CreateFleetModal;
