import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import PropTypes from 'prop-types';
import mapboxgl, { NavigationControl } from 'mapbox-gl';
import AnimatedPopup from 'mapbox-gl-animated-popup';
import { CircularProgress, Stack, Typography } from '@esgian/esgianui';
import AdvancedTooltip from '@components/Maps/VesselsMap/AdvancedTooltip';
import { DATE_TIME_FORMAT_DAY_OF, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN } from '@constants';
import { drawRegionPolygon } from '@helpers/mapHelpers';
import { useSelector } from 'react-redux';
import { getSegment, getThemeMode } from '@store/features';
import { useMapFullscreen } from '@hooks';
import MapFullscreenButton, {
  mapFullscreenButtonParentStyle
} from '@components/Common/MapFullscreenButton';
import { useTimezone } from '@hooks/useTimezone';
import {
  getVesselColor,
  HIGH_SPEED_VESSEL_ICON_COLOR,
  LOW_SPEED_VESSEL_ICON_COLOR,
  MEDIUM_SPEED_VESSEL_ICON_COLOR
} from '@helpers';
import { VesselIcon } from '@components';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

const styles = `
  .vessel-popup .mapboxgl-popup-content {
    padding: 0;
    width: 300px;
  }
`;

const styleSheet = document.createElement('style');
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

const createMarkers = (
  map,
  vesselMarkers,
  getTimeZoneDisplay,
  vessels,
  setSelectedVessel,
  setVesselMarkers,
  themeMode
) => {
  if (!map) {
    return;
  }
  vesselMarkers?.map((marker) => marker.remove());

  let markers = [];
  vessels?.forEach(
    ({
      lat,
      long: lon,
      vesselName,
      vessel_name,
      speed,
      imo,
      mmsi,
      timestamp,
      heading,
      course,
      operatorShortName,
      operatorId,
      vessel_id,
      eta,
      destination
    }) => {
      let el = document.createElement('div');
      let markerSpan = document.createElement('span');
      let rotate = heading ? heading : course;
      const vesselColor = getVesselColor({
        speed,
        showVesselsStatus: true
      });
      const root = ReactDOM.createRoot(markerSpan);
      root.render(<VesselIcon color={vesselColor} size={12} />);

      markerSpan.style.cssText = `
          height: ${15}px;
          width: ${15}px;
          cursor: pointer;
          font-size: 15px;   
          position: absolute;
          background-repeat: no-repeat;
          background-size: contain;
          transform: rotateZ(${rotate}deg);
          `;
      document.body.appendChild(markerSpan);
      el.className = 'ship-marker';
      el.appendChild(markerSpan);
      el.addEventListener('click', () => {
        map.flyTo({ center: [lon, lat], zoom: 10, duration: 5000 });
        setSelectedVessel({
          lat: lat,
          long: lon,
          vesselName: vesselName,
          vessel_name: vessel_name,
          speed: speed,
          imo: imo,
          mmsi: mmsi,
          timestamp: timestamp,
          heading: heading,
          operator_short_name: operatorShortName,
          operator_id: operatorId,
          vessel_id: vessel_id,
          eta: eta,
          destination: destination
        });
      });

      const options = {
        closeButton: false,
        closeOnClick: false,
        maxWidth: '300px',
        className: 'vessel-popup',
        openingAnimation: {
          duration: 500,
          easing: 'easeOutElastic',
          transform: 'scale'
        },
        closingAnimation: {
          duration: 0,
          easing: 'easeInBack',
          transform: 'scale'
        }
      };
      const timeZoneDisplay = getTimeZoneDisplay(eta, DATE_TIME_FORMAT_DAY_OF);
      const textColor = themeMode === 'dark' ? '#fff' : '#000';
      const popup = new AnimatedPopup(options);
      el.addEventListener('mouseenter', () => {
        map.getCanvas().style.cursor = 'pointer';
        const coordinates = [lon, lat];

        const description = `
          <div style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            gap: 16px;
            position: relative;
            width: 100%;
            margin: 0;
            padding: 8px;
            background: rgba(255, 255, 255, 0.5);>
            <div style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
              flex-wrap: nowrap;
              gap: 4px;
              position: relative">
              <div style="
                display: flex;
                align-items: flex-start;
                align-self: stretch;
                flex-wrap: nowrap;
                flex-shrink: 0;
                gap: 8px;
                position: relative;
                z-index: 1">
                <span style="
                  flex-shrink: 0;
                  flex-basis: auto;
                  position: relative;
                  height: 24px;
                  color: ${textColor};
                  font-family: 'Noto Sans', var(--default-font-family);
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: left;
                  white-space: nowrap;
                  letter-spacing: 0.1px">
                  ${vesselName || vessel_name}
                </span>
              </div>
              <div style="
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                flex-wrap: nowrap;
                flex-shrink: 0;
                gap: 8px;
                position: relative;
                overflow: hidden">
                ${[
                  { label: 'Speed', value: `${speed} Knots` },
                  { label: 'ETA', value: timeZoneDisplay },
                  { label: 'Destination', value: destination || '-' }
                ]
                  .map(
                    (row) => `
                  <div style="
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    align-self: stretch;
                    flex-wrap: nowrap;
                    flex-shrink: 0;
                    position: relative">
                    <span style="
                      flex-shrink: 0;
                      flex-basis: auto;
                      position: relative;
                      height: 16px;
                      color: ${textColor};
                      font-family: 'Noto Sans', var(--default-font-family);
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 16px;
                      text-align: left;
                      white-space: nowrap;
                      letter-spacing: 0.4px">
                      ${row.label}
                    </span>
                    <span style="
                      flex-shrink: 0;
                      flex-basis: auto;
                      position: relative;
                      height: 16px;
                      color: ${textColor};
                      font-family: 'Noto Sans', var(--default-font-family);
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 16px;
                      text-align: left;
                      white-space: nowrap;
                      letter-spacing: 0.4px">
                      ${row.value}
                    </span>
                  </div>
                `
                  )
                  .join('')}
              </div>
            </div>
          </div>`;

        while (Math.abs(lon - coordinates[0]) > 180) {
          coordinates[0] += lon > coordinates[0] ? 360 : -360;
        }
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });
      el.addEventListener('mouseleave', () => {
        popup.remove();
      });
      markers.push(new mapboxgl.Marker(el).setLngLat([lon, lat]).setPopup(popup).addTo(map));
    }
  );
  setVesselMarkers(markers);
};

function VesselsMap({
  vessels,
  regionPolygon,
  projection,
  height,
  zoom,
  center,
  mapStyle,
  showVesselList,
  showSpeedLegend,
  isCanal,
  loading
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [selectedVessel, setSelectedVessel] = useState(null);
  const [vesselMarkers, setVesselMarkers] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const themeMode = useSelector(getThemeMode);
  const segment = useSelector(getSegment);
  const { isFullscreen, toggleFullscreen } = useMapFullscreen();
  const { getTimeZoneDisplay } = useTimezone();

  useEffect(() => {
    setMapLoaded(false);
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle,
      center: center,
      height: height,
      zoom: zoom,
      projection: projection
    });
    map.current.addControl(new NavigationControl());

    map.current.on('load', () => {
      if (projection === 'globe') {
        map.current.setFog({
          //Background and glare around globe
          'horizon-blend': 0.01,
          color: 'rgba(255,255,255,0.29)'
        });
      }
      createMarkers(
        map.current,
        vesselMarkers,
        getTimeZoneDisplay,
        vessels,
        setSelectedVessel,
        setVesselMarkers,
        themeMode,
        segment
      );
      if (regionPolygon) {
        drawRegionPolygon(map.current, regionPolygon);
      }
      map.current.flyTo({ center: center, zoom: zoom, duration: 0 });
      setMapLoaded(true);
    });
  }, [vessels, regionPolygon, themeMode, getTimeZoneDisplay, isFullscreen]);

  return (
    <div
      ref={mapContainer}
      style={
        isFullscreen
          ? {
              height: '100vh',
              width: '100vw'
            }
          : {
              height: height,
              width: '100%'
            }
      }
      className="vessel-map-container">
      {(!mapLoaded || loading || !vesselMarkers) && (
        <div
          style={{
            height: '100%',
            width: '100%',
            background: 'rgb(255 255 255 / 50%)',
            zIndex: 2,
            position: 'absolute'
          }}>
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)'
            }}
            size={100}
          />
        </div>
      )}
      {showSpeedLegend && (
        <div
          id="vessels-map-legend"
          className="legend-vessels-map"
          style={{
            backgroundColor: themeMode ? 'rgba(11,26,46,0.8)' : 'rgba(255, 255, 255, 0.8)',
            position: 'absolute'
          }}>
          <Stack sx={{ pl: 2, pr: 4, pb: 2, pt: 2 }} spacing={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <VesselIcon color={LOW_SPEED_VESSEL_ICON_COLOR} size={12} />
              <Typography variant={'body2'}>{` < 1 kn`}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <VesselIcon color={MEDIUM_SPEED_VESSEL_ICON_COLOR} size={12} />

              <Typography variant={'body2'}>{`1-3 kn`}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <VesselIcon color={HIGH_SPEED_VESSEL_ICON_COLOR} size={12} />
              <Typography variant={'body2'}>{`> 3 kn`}</Typography>
            </Stack>
          </Stack>
        </div>
      )}
      <div style={mapFullscreenButtonParentStyle}>
        <MapFullscreenButton
          onClick={() => toggleFullscreen(mapContainer.current)}
          isFullScreen={isFullscreen}
        />
      </div>
      {!loading && (
        <AdvancedTooltip
          loading={loading}
          map={map.current}
          setSelectedVessel={setSelectedVessel}
          mapHeight={height}
          vessels={vessels}
          showVesselList={showVesselList}
          selectedVessel={selectedVessel}
          handleZoomReset={() => map.current.flyTo({ center: center, zoom: zoom, duration: 500 })}
          isCanal={isCanal}
        />
      )}
    </div>
  );
}

VesselsMap.propTypes = {
  vessels: PropTypes.arrayOf(PropTypes.object),
  regionPolygon: PropTypes.string,
  projection: PropTypes.oneOf([
    'globe',
    'equalEarth',
    'naturalEarth',
    'winkelTripel',
    'albers',
    'lambertConformalConic',
    'equirectangular',
    'mercator'
  ]),
  height: PropTypes.string,
  mapStyle: PropTypes.string,
  zoom: PropTypes.number,
  center: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  showSpeedLegend: PropTypes.bool,
  showVesselList: PropTypes.bool,
  isCanal: PropTypes.bool
};

VesselsMap.defaultProps = {
  vessels: [],
  regionPolygon: null,
  projection: 'globe',
  height: '40vh',
  zoom: 1,
  showVesselList: false,
  showSpeedLegend: false,
  loading: false,
  center: [0, 0],
  mapStyle: MAP_STYLE_LIGHT_MODE
};

export default VesselsMap;
