import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Stack,
  ArrowDropUpIcon,
  Typography,
  ArrowDropDownIcon,
  Grid,
  Paper
} from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { ProfileLink } from '@components/Links';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  getCommercialCategoryType,
  getDateFormat,
  getOperatorType,
  getUser
} from '@store/features';
import { useSegment, useTheme } from '@hooks';
import UnderlyingPortStatsModal from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortOperatorStatisticsSection/UnderlyingPortStatsModal/UnderlyingPortStatsModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GEOGRAPHICAL_TYPES, MAX_NUM_PORT_CALLS } from '@constants';
import { getLatestPortCalls } from '@api';
import { useParams } from 'react-router-dom';

const getCustomTableStyle = () => {
  return {
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          display: 'flex',
          padding: '0px',
          minHeight: 'auto'
        },
        actions: { width: '100%' }
      }
    },
    MUIDataTableFilterList: { display: 'none' }
  };
};

function PortOperatorStatsTable({ loading, operatorStats, selectedYear, fileName }) {
  const dateFormat = useSelector(getDateFormat);
  const user = useSelector(getUser);
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  const [currentOperatorId, setCurrentOperatorId] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'year').startOf('month').format(dateFormat)
  );
  const [endDate, setEndDate] = useState(moment().format(dateFormat));
  const [sortOrder, setSortOrder] = useState({ column: 'ytdPortCalls', direction: 'desc' });
  const { theme } = useTheme();
  const { segment } = useSegment();
  const params = useParams();
  const queryClient = useQueryClient();

  const columns = useMemo(
    () => [
      {
        name: 'operatorShortName',
        label: 'Operator',
        options: {
          sort: true,
          filterType: 'textField',
          sortThirdClickReset: true,
          setCellHeaderProps: () => ({
            style: { minWidth: '200px', maxWidth: '200px', width: '200px' }
          }),
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink name={dataIndex} profile={'operator'} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: {
          display: false,
          viewColumns: false,
          filter: false,
          download: false
        }
      },
      {
        name: 'prevYearYTDPortCalls',
        label: `YTD ${selectedYear - 1}`,
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '250px', maxWidth: '250px', width: '250px' }
          }),
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const rowData = tableMeta.rowData;
            return (
              <Typography
                variant={'caption'}
                onClick={() => {
                  const operatorId = rowData[1];

                  if (!operatorId) {
                    return;
                  }
                  setStartDate(moment().clone().subtract(1, 'year').startOf('year'));
                  setEndDate(moment().clone().subtract(1, 'year').startOf('day'));
                  setCurrentOperatorId(operatorId);
                }}
                style={{ cursor: 'pointer' }}>
                {value || '--'}
              </Typography>
            );
          }
        }
      },
      {
        name: 'ytdPortCalls',
        label: `YTD ${selectedYear}`,
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '250px', maxWidth: '250px', width: '250px' }
          }),
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const rowData = tableMeta.rowData;
            return (
              <Typography
                variant={'caption'}
                onClick={() => {
                  const operatorId = rowData[1];
                  if (!operatorId) {
                    return;
                  }
                  setStartDate(moment().clone().startOf('year'));
                  setEndDate(moment().clone().startOf('day'));
                  setCurrentOperatorId(operatorId);
                }}
                style={{ cursor: 'pointer' }}>
                {value || '--'}
              </Typography>
            );
          }
        }
      },
      {
        name: 'increase',
        label: 'Increase %',
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '250px', maxWidth: '250px', width: '250px' }
          }),
          customBodyRender: (dataIndex) => {
            if (parseFloat(dataIndex)) {
              if (dataIndex >= 0) {
                return (
                  <Stack direction={'row'}>
                    <ArrowDropUpIcon color={'success'} />
                    <Typography variant={'caption'} color={'text.success'}>
                      {parseFloat(dataIndex?.toFixed(1))}%
                    </Typography>
                  </Stack>
                );
              } else {
                return (
                  <Stack direction={'row'}>
                    <ArrowDropDownIcon color={'error'} />
                    <Typography variant={'caption'} color={'text.error'}>
                      {parseFloat(dataIndex?.toFixed(1))}%
                    </Typography>
                  </Stack>
                );
              }
            }
            return '-';
          },
          filter: true,
          sort: true
        }
      },
      {
        name: 'prevYearYTDPortCallsShare',
        label: `YTD ${selectedYear - 1}`,
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '8vw', maxWidth: '8vw' }
          }),
          customBodyRender: (dataIndex) => {
            return parseFloat(dataIndex) ? `${parseFloat(dataIndex?.toFixed(1))}%` : '0%';
          },
          filter: true,
          sort: true
        }
      },
      {
        name: 'ytdPortCallsShare',
        label: `YTD ${selectedYear}`,
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '8vw', maxWidth: '8vw' }
          }),
          customBodyRender: (dataIndex) => {
            return parseFloat(dataIndex) ? `${parseFloat(dataIndex?.toFixed(1))}%` : '0%';
          },
          filter: true,
          sort: true
        }
      },
      {
        name: 'increaseShare',
        label: 'Increase %',
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '8vw', maxWidth: '8vw' }
          }),
          customBodyRender: (dataIndex) => {
            if (parseFloat(dataIndex)) {
              if (dataIndex >= 0) {
                return (
                  <Stack direction={'row'}>
                    <ArrowDropUpIcon color={'success'} />
                    <Typography variant={'caption'} color={'text.success'}>
                      {parseFloat(dataIndex?.toFixed(1))}%
                    </Typography>
                  </Stack>
                );
              } else {
                return (
                  <Stack direction={'row'}>
                    <ArrowDropDownIcon color={'error'} />
                    <Typography variant={'caption'} color={'text.error'}>
                      {parseFloat(dataIndex?.toFixed(1))}%
                    </Typography>
                  </Stack>
                );
              }
            }
            return '-';
          },
          filter: true,
          sort: true
        }
      }
    ],
    [selectedYear]
  );
  const modalQuery = useQuery({
    queryKey: ['operatorStats', currentOperatorId, startDate, endDate],
    enabled: !!currentOperatorId,
    placeholderData: null,
    queryFn: async ({ queryKey, signal }) => {
      const operatorId = queryKey[1];

      if (!operatorId) {
        return [];
      }

      const body = {
        startDate: startDate,
        endDate: endDate,
        operatorTypeId: operatorType,
        segmentTypeId: segment.id,
        commercialCategoryId: commercialCategoryType,
        geoIds: [params.portId],
        geographicalType: GEOGRAPHICAL_TYPES.PORT,
        numberOfPortCalls: MAX_NUM_PORT_CALLS,
        operators: [operatorId]
      };

      return getLatestPortCalls(body, signal)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          throw error;
        });
    }
  });

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: false,
      print: false,
      tableBodyMaxHeight: '90vh',
      download: checkUserHasAccess(user, true),
      downloadOptions: {
        filename: fileName
      },
      customToolbar: () => {
        return (
          <Grid
            container
            sx={{
              backgroundColor: () => (theme.mode === 'dark' ? '#171717' : '#fffff')
            }}>
            <Grid
              item
              sx={{
                padding: 0,
                minWidth: '200px',
                maxWidth: '200px'
              }}
            />
            <Grid
              item
              sx={{
                padding: 0,
                minWidth: '751px',
                maxWidth: '751px'
              }}
              textAlign={'center'}>
              <Typography variant={'body1'}>No. of Port Calls</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: 0
              }}
              xs
              textAlign={'center'}>
              <Typography variant={'body1'}>Share of Port Calls</Typography>
            </Grid>
          </Grid>
        );
      },
      viewColumns: false,
      pagination: false,
      elevation: 0,
      toolbar: true,
      sortOrder: sortOrder
    };
  }, [user, theme, fileName]);
  return (
    <>
      <Paper>
        <Table
          customStyle={getCustomTableStyle()}
          loading={loading}
          mode={theme.mode}
          title={''}
          data={operatorStats?.length ? operatorStats : []}
          columns={columns}
          options={options}
        />
      </Paper>
      {!!currentOperatorId && (
        <UnderlyingPortStatsModal
          isLoading={modalQuery.isFetching}
          modalOpen
          modalData={modalQuery.data}
          setModalOpen={() => {
            setCurrentOperatorId(null);
          }}
        />
      )}
    </>
  );
}

PortOperatorStatsTable.propTypes = {
  loading: PropTypes.bool,
  operatorStats: PropTypes.arrayOf(PropTypes.object),
  selectedYear: PropTypes.number,
  fileName: PropTypes.string.isRequired
};

PortOperatorStatsTable.defaultProps = {
  loading: false,
  operatorStats: [],
  selectedYear: moment().year()
};

export default PortOperatorStatsTable;
