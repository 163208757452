import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgress, Paper, Stack, Typography } from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import mapboxgl, { Map } from '!mapbox-gl';
import {
  MAP_STYLE_LIGHT_MODE,
  MAPBOX_API_TOKEN,
  DATE_TIME_FORMAT_DAY_OF,
  MAP_STYLE_DEEP_DARK_MODE
} from '@constants';
import { useSelector } from 'react-redux';
import { useSegment, useStaticLookupQueries, useTheme } from '@hooks';
import { getSegment } from '@store/features';
import { convertPolygonToCoordinates } from '@helpers/mapHelpers';
import CanalListDisplay from '@components/Maps/CanalsOverviewMap/CanalListDisplay';
import AnimatedPopup from 'mapbox-gl-animated-popup';
import PropTypes from 'prop-types';
import { useTimezone } from '@hooks/useTimezone';
import AdvancedTooltip from '@components/Maps/VesselsMap/AdvancedTooltip';
import { getVesselColor } from '@helpers';
import ReactDOM from 'react-dom/client';
import { VesselIcon } from '@components';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

const getShipIcon = (speed) => {
  if (speed >= 0 && speed < 1) {
    return 'icon-ship-10';
  }
  if (speed > 1 && speed <= 3) {
    return 'icon-ship-0';
  }
  if (speed > 3) {
    return 'icon-ship-port';
  }
  return 'icon-ship-port';
};

const createMarkers = (
  map,
  vesselMarkers,
  vessels,
  setVesselMarkers,
  getTimeZoneDisplay,
  setSelectedVessel,
  themeMode
) => {
  if (!map) {
    return;
  }
  vesselMarkers?.map((marker) => marker.remove());
  let markers = [];
  vessels?.map(
    ({
      lat,
      long,
      vesselName,
      vessel_name,
      speed,
      heading,
      eta,
      destination,
      imo,
      mmsi,
      operatorShortName,
      positionReceived
    }) => {
      let el = document.createElement('div');
      let markerSpan = document.createElement('span');
      let rotate = heading ? heading : 0;
      const vesselColor = getVesselColor({
        speed,
        showVesselsStatus: true
      });
      const root = ReactDOM.createRoot(markerSpan);
      root.render(<VesselIcon color={vesselColor} size={12} />);

      markerSpan.style.cssText = `
          height: ${15}px;
          width: ${15}px;
          cursor: pointer;
          font-size: 15px;   
          position: absolute;
          background-repeat: no-repeat;
          background-size: contain;
          transform: rotateZ(${rotate}deg);
          `;
      markerSpan.className = getShipIcon(speed);
      el.className = 'ship-marker';
      el.appendChild(markerSpan);
      el.addEventListener('click', () => {
        map.flyTo({ center: [long, lat], zoom: 10, duration: 5000 });
        setSelectedVessel({
          lat: lat,
          long: long,
          vesselName: vesselName,
          vessel_name: vessel_name,
          speed: speed,
          imo: imo,
          mmsi: mmsi,
          heading: heading,
          operator_short_name: operatorShortName,
          eta: eta,
          destination: destination,
          timestamp: positionReceived
        });
      });

      const options = {
        closeButton: false,
        closeOnClick: false,
        maxWidth: '300px',
        className: 'vessel-popup',
        openingAnimation: {
          duration: 500,
          easing: 'easeOutElastic',
          transform: 'scale'
        },
        closingAnimation: {
          duration: 0,
          easing: 'easeInBack',
          transform: 'scale'
        }
      };
      const timeZoneDisplay = getTimeZoneDisplay(eta, DATE_TIME_FORMAT_DAY_OF);
      const textColor = themeMode === 'dark' ? '#fff' : '#000';
      const description = `
        <div style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          gap: 16px;
          position: relative;
          width: 100%;
          margin: 0;
          padding: 8px;
          background: rgba(255, 255, 255, 0.5);">
          <div style="
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            flex-wrap: nowrap;
            gap: 4px;
            position: relative">
            <div style="
              display: flex;
              align-items: flex-start;
              align-self: stretch;
              flex-wrap: nowrap;
              flex-shrink: 0;
              gap: 8px;
              position: relative;
              z-index: 1">
              <span style="
                flex-shrink: 0;
                flex-basis: auto;
                position: relative;
                height: 24px;
                color: ${textColor};
                font-family: 'Noto Sans', var(--default-font-family);
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
                white-space: nowrap;
                letter-spacing: 0.1px">
                ${vesselName || vessel_name}
              </span>
            </div>
            <div style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              align-self: stretch;
              flex-wrap: nowrap;
              flex-shrink: 0;
              gap: 8px;
              position: relative;
              overflow: hidden">
              ${[
                { label: 'Speed', value: `${speed} Knots` },
                { label: 'ETA', value: timeZoneDisplay },
                { label: 'Destination', value: destination || '-' }
              ]
                .map(
                  (row) => `
                <div style="
                  display: flex;
                  align-items: flex-start;
                  justify-content: space-between;
                  align-self: stretch;
                  flex-wrap: nowrap;
                  flex-shrink: 0;
                  position: relative">
                  <span style="
                    flex-shrink: 0;
                    flex-basis: auto;
                    position: relative;
                    height: 16px;
                    color: ${textColor};
                    font-family: 'Noto Sans', var(--default-font-family);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                    white-space: nowrap;
                    letter-spacing: 0.4px">
                    ${row.label}
                  </span>
                  <span style="
                    flex-shrink: 0;
                    flex-basis: auto;
                    position: relative;
                    height: 16px;
                    color: ${textColor};
                    font-family: 'Noto Sans', var(--default-font-family);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                    white-space: nowrap;
                    letter-spacing: 0.4px">
                    ${row.value}
                  </span>
                </div>
              `
                )
                .join('')}
            </div>
          </div>
        </div>`;

      const popup = new AnimatedPopup(options);
      let popupVisible = false;
      el.addEventListener('mouseenter', () => {
        map.getCanvas().style.cursor = 'pointer';
        const coordinates = [long, lat];

        while (Math.abs(long - coordinates[0]) > 180) {
          coordinates[0] += long > coordinates[0] ? 360 : -360;
        }

        popup.setLngLat(coordinates).setHTML(description).addTo(map);
        popupVisible = true;
      });

      el.addEventListener('mouseleave', () => {
        popup.remove();
        popupVisible = false;
      });

      popup.on('mouseenter', () => {
        map.getCanvas().style.cursor = 'pointer';
      });
      markers.push(new mapboxgl.Marker(el).setLngLat([long, lat]).setPopup(popup).addTo(map));
    }
  );
  setVesselMarkers(markers);
};

const createCanalMarkers = (map, markersData, setHoverCanal, handleCanalClick) => {
  markersData.forEach(({ lat, long, name, regionId }) => {
    if (lat !== null && long !== null) {
      const markerEl = document.createElement('div');
      let markerSpan = document.createElement('span');
      canalPolygon(map, markersData);

      const markerStyle = `
          height: 3.5em;
          width: 1.5em;
          background-image: url('/assets/images/blue-pin.svg');
          background-repeat: no-repeat;
          background-size: 100%;`;
      markerEl.className = 'pin-marker';
      markerSpan.innerHTML = `<div class="marker-number" id="canal-marker"/>`;
      markerEl.style.cssText = markerStyle;
      markerEl.appendChild(markerSpan);
      markerEl.addEventListener('mouseenter', () => {
        setHoverCanal(name);
      });
      markerEl.addEventListener('mouseleave', () => {
        setHoverCanal(null);
      });
      markerEl.addEventListener('click', () => {
        handleCanalClick(regionId);
      });
      return new mapboxgl.Marker(markerEl).setLngLat([long, lat]).addTo(map);
    }
  });
};

const canalPolygon = (map, lookupCanals) => {
  const polygons = lookupCanals.map((canal) => ({
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [convertPolygonToCoordinates(canal.polygon)] // Use the convertPolygonToCoordinates function
    },
    properties: {
      canalId: canal.regionId,
      name: canal.name
    }
  }));
  const sourceData = {
    type: 'FeatureCollection',
    features: polygons
  };

  // Add the GeoJSON source to the map
  if (!map.getSource('canals')) {
    map.addSource('canals', {
      type: 'geojson',
      data: sourceData
    });
  } else {
    // Update the source data if it already exists
    map.getSource('canals').setData(sourceData);
  }

  // Add the polygons as a fill layer
  if (!map.getLayer('canal-fills')) {
    map.addLayer({
      id: 'canal-fills',
      type: 'fill',
      source: 'canals',
      layout: {},
      paint: {
        'fill-color': 'rgba(128,223,235,0.43)',
        'fill-opacity': 0.4
      }
    });
  }

  // Add the polygons' borders as a line layer
  if (!map.getLayer('canal-borders')) {
    map.addLayer({
      id: 'canal-borders',
      type: 'line',
      source: 'canals',
      layout: {},
      paint: {
        'line-color': '#4DD1E3',
        'line-width': 1
      }
    });
  }
};

function CanalsOverviewMap({
  hideCanalList = false,
  canalInTransitVesselsData = null,
  canalArrivalVesselsData = null,
  center
}) {
  const mapContainer = useRef(null);
  const [styleReady, setStyleReady] = useState(false);
  const [hoverCanal, setHoverCanal] = useState(null);
  const [loadReady, setLoadReady] = useState(false);
  const [layersAdded, setLayersAdded] = useState(false);
  const navigate = useNavigate();
  const map = useRef(null);
  const { themeMode } = useTheme();
  const {
    lookupCanalsQuery: { data: lookupCanals }
  } = useStaticLookupQueries();
  const { uriExt } = useSegment();
  const segment = useSelector(getSegment);
  const [vesselMarkers, setVesselMarkers] = useState([]);
  const { getTimeZoneDisplay } = useTimezone();
  const [selectedVessel, setSelectedVessel] = useState(null);

  const handleCanalClick = useCallback(
    (canalId) => {
      navigate(`${uriExt}/commercial-analytics/canal/${canalId}`);
    },
    [uriExt]
  );

  useEffect(() => {
    setStyleReady(false);
    setLoadReady(false);
    if (map.current || !lookupCanals) return;
    map.current = new Map({
      container: mapContainer.current,
      renderWorldCopies: false,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      projection: { name: 'mercator' },
      center: [50, 50],
      zoom: 0
    });
    map.current.flyTo({ center: [0, 20] });

    map.current?.on('style.load', () => {
      setStyleReady(true);
      if (map.current) {
        createCanalMarkers(map.current, lookupCanals, setHoverCanal, handleCanalClick);
        createMarkers(
          map.current,
          vesselMarkers,
          canalInTransitVesselsData,
          setVesselMarkers,
          getTimeZoneDisplay,
          setSelectedVessel,
          themeMode,
          segment,
          lookupCanals
        );
        createMarkers(
          map.current,
          vesselMarkers,
          canalArrivalVesselsData,
          setVesselMarkers,
          getTimeZoneDisplay,
          setSelectedVessel,
          themeMode,
          segment,
          lookupCanals
        );
      }
    });
    map.current?.on('load', () => {
      setLoadReady(true);
    });
    return () => {
      map.current = null;
    };
  }, [
    canalArrivalVesselsData,
    canalInTransitVesselsData,
    themeMode,
    getTimeZoneDisplay,
    lookupCanals
  ]);

  useEffect(() => {
    if (!lookupCanals?.length || !styleReady || !loadReady) return;
    setLayersAdded(true);
  }, [styleReady, loadReady, lookupCanals, hideCanalList]);
  return (
    <Paper>
      <Stack direction={'row'}>
        {!hideCanalList && (
          <div
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.25) 2px 4px 4px 0px',
              clipPath: 'inset(0px -15px 0px 0px)',
              width: '15vw',
              borderTopLeftRadius: '4px',
              height: `80vh`,
              display: 'flex',
              maxHeight: `80vh`
            }}>
            <CanalListDisplay
              setHoverCanal={setHoverCanal}
              map={map}
              loading={false}
              lookupCanals={lookupCanals}
            />
          </div>
        )}
        <div
          ref={mapContainer}
          style={{
            width: !hideCanalList ? '82%' : '100%',
            height: !hideCanalList ? '80vh' : '40vh'
          }}
          className="dashboard-map">
          {hoverCanal && (
            <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 1 }}>
              <Paper variant={'outlined'} sx={{ p: 2 }}>
                <Typography>{hoverCanal}</Typography>
              </Paper>
            </div>
          )}
          {(!styleReady ||
            !loadReady ||
            !layersAdded ||
            !(canalArrivalVesselsData || canalInTransitVesselsData)) && (
            <div
              style={{
                height: '100%',
                width: '100%',
                background: 'rgb(255 255 255 / 50%)',
                zIndex: 2,
                position: 'absolute'
              }}>
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: 'calc(50% - 50px)',
                  left: 'calc(50% - 50px)'
                }}
                size={100}
              />
            </div>
          )}
          {canalArrivalVesselsData && (
            <AdvancedTooltip
              selectedVessel={selectedVessel}
              setSelectedVessel={setSelectedVessel}
              handleZoomReset={() => map.current.flyTo({ center: center, duration: 500 })}
              vessels={canalArrivalVesselsData || null}
              mapHeight={'50vh'}
              isCanal={true}
              map={map.current}
              loading={false}
            />
          )}
        </div>
      </Stack>
    </Paper>
  );
}

CanalsOverviewMap.propTypes = {
  hideCanalList: PropTypes.bool,
  canalInTransitVesselsData: PropTypes.arrayOf(PropTypes.object),
  canalArrivalVesselsData: PropTypes.arrayOf(PropTypes.object),
  center: PropTypes.object
};

export default CanalsOverviewMap;
