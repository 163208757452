import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Paper,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ExpandLessIcon,
  ExpandMoreIcon,
  Button,
  PlusIcon,
  EditIcon,
  IconButton,
  ToolTip,
  CircularProgress,
  Checkbox,
  InsertCommentOutlinedIcon,
  Badge
} from '@esgian/esgianui';
import { getTransparentBackground, getNextFleetColor } from '@helpers';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import ContentPanel from '../Common/ContentPanel';
import { useFleets } from '@hooks/useFleets';
import CreateFleetModal from './CreateFleetModal';
import AddVesselsToFleetModal from './AddVesselsToFleetModal';
import { VesselIcon } from '@components/Icons';
import { useSegment } from '@hooks';
import NoteModal from './NoteModal';
import { useMapState } from '../../../Sections/ShipAnalytics/DashboardActivityMapSection/hooks/useMapState';
import { useTheme } from '../../../../hooks';

const ColorCircle = ({ color }) => (
  <Box
    sx={{
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: color,
      display: 'inline-block',
      marginRight: 1,
      border: '1px solid rgba(0, 0, 0, 0.1)'
    }}
  />
);

ColorCircle.propTypes = {
  color: PropTypes.string.isRequired
};

const DashboardActivityMapMyFleet = ({
  onClose,
  vessels = [],
  selectedFleets,
  setSelectedFleets
}) => {
  const themeMode = useSelector(getThemeMode);
  const { segment } = useSegment();
  const { customScrollbar } = useTheme();

  const { setFleetSearch, handleVesselSelect } = useMapState();

  const [expandedGroups, setExpandedGroups] = useState({});
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editingFleet, setEditingFleet] = useState(null);

  const [editingFleetDetails, setEditingFleetDetails] = useState(null);
  const [editingNote, setEditingNote] = useState(null);
  const {
    savedFleets,
    isLoadingFleets,
    createFleet,
    isCreatingFleet,
    updateFleetVessels,
    isUpdatingFleetVessels,
    deleteFleet,
    editFleet,
    isEditingFleet,
    updateVesselNote,
    isUpdatingNote,
    removeVesselFromFleet
  } = useFleets();

  // Filter fleets based on segment
  const segmentFilteredFleets = useMemo(() => {
    return savedFleets.filter((fleet) => fleet.segmentId === segment.id);
  }, [savedFleets, segment]);

  useEffect(() => {
    const selectedFleetsList = segmentFilteredFleets.filter((fleet) =>
      Array.from(selectedFleets).some(
        (selectedFleet) =>
          // Compare by ID since selectedFleets might contain full objects
          selectedFleet.id === fleet.id || selectedFleet === fleet.id
      )
    );

    // Only update if the lists are different
    const currentSelectedIds = Array.from(selectedFleets).map((f) => f.id || f);
    const newSelectedIds = selectedFleetsList.map((fleet) => fleet.id);

    if (JSON.stringify(currentSelectedIds.sort()) !== JSON.stringify(newSelectedIds.sort())) {
      setSelectedFleets(new Set(selectedFleetsList));
    }
    setFleetSearch();
  }, [segmentFilteredFleets]);

  const memoizedSelectedFleets = useMemo(() => {
    return new Set(selectedFleets);
  }, [selectedFleets]);

  const handleFleetSelect = useCallback(
    (fleetId) => {
      setSelectedFleets((prev) => {
        const prevSet = prev instanceof Set ? prev : new Set(prev);
        const next = new Set(prevSet);

        const existingFleet = Array.from(next).find((f) => f.id === fleetId);

        if (existingFleet) {
          next.delete(existingFleet);
        } else {
          const fleet = segmentFilteredFleets.find((f) => f.id === fleetId);
          if (fleet) {
            next.add(fleet);
          }
        }

        return next;
      });
    },
    [segmentFilteredFleets]
  );

  const handleToggleGroup = (groupId) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const handleCreateFleet = useCallback(
    async (values) => {
      try {
        const fleetData = {
          ...values,
          color: getNextFleetColor(segmentFilteredFleets),
          segmentId: segment?.id
        };
        await createFleet(fleetData);
        setShowCreateModal(false);
      } catch (error) {
        console.error('Error creating fleet:', error);
      }
    },
    [createFleet, segment?.id, segmentFilteredFleets]
  );

  const handleUpdateFleetVessels = async ({ added, removed }) => {
    if (!editingFleet) return;

    try {
      await updateFleetVessels({
        fleetId: editingFleet.id,
        added,
        removed
      });
      setEditingFleet(null);
    } catch (error) {
      console.error('Error updating fleet vessels:', error);
    }
  };

  const handleDeleteFleet = async (fleetId) => {
    try {
      await deleteFleet(fleetId);
    } catch (error) {
      console.error('Error deleting fleet:', error);
    }
  };

  const handleEditFleet = useCallback(
    async (values) => {
      if (!editingFleetDetails) return;

      try {
        await editFleet({
          fleetId: editingFleetDetails.id,
          name: values.name,
          color: values.color
        });
        setEditingFleetDetails(null);
      } catch (error) {
        console.error('Error updating fleet:', error);
      }
    },
    [editFleet, editingFleetDetails]
  );

  const handleUpdateNote = async (note) => {
    if (!editingNote) return;

    try {
      await updateVesselNote({
        fleetId: editingNote.fleetId,
        vesselImo: editingNote.vesselImo,
        note
      });
      setEditingNote(null);
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const handleRemoveVessel = async (fleetId, vesselImo) => {
    try {
      await removeVesselFromFleet({ fleetId, vesselImo });
    } catch (error) {
      console.error('Error removing vessel:', error);
    }
  };

  const renderVesselList = useCallback(
    (fleet) => (
      <List
        dense
        sx={{
          overflow: 'auto',
          maxHeight: '200px',
          ...customScrollbar
        }}>
        {fleet.vessels.map((vessel) => (
          <ListItem
            key={vessel.vesselImo}
            secondaryAction={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <ToolTip title={`Zoom in on vessel's position`}>
                  <IconButton
                    size="small"
                    sx={{ px: 1 }}
                    onClick={() => handleVesselSelect(vessel.vesselImo)}>
                    <VesselIcon color={fleet.color} size={14} />
                  </IconButton>
                </ToolTip>

                <ToolTip title={vessel.note || 'Add note'}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setEditingNote({
                        fleetId: fleet.id,
                        vesselImo: vessel.vesselImo,
                        vesselName: vessel.vesselName,
                        note: vessel.note
                      })
                    }>
                    <Badge
                      variant="dot"
                      color="primary"
                      invisible={!vessel.note}
                      sx={{
                        '& .MuiBadge-dot': {
                          width: 6,
                          height: 6,
                          minWidth: 6,
                          right: 2,
                          top: 2
                        }
                      }}>
                      <InsertCommentOutlinedIcon fontSize="small" sx={{ color: '#fff' }} />
                    </Badge>
                  </IconButton>
                </ToolTip>
              </Box>
            }>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ListItemText
                primary={
                  <Box component="span">
                    {vessel.vesselName}
                    <Typography component="span" color="text.secondary" sx={{ ml: 1 }}>
                      ({vessel.operator || 'No operator'})
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </ListItem>
        ))}
      </List>
    ),
    [handleRemoveVessel, handleVesselSelect, customScrollbar]
  );

  return (
    <Paper
      sx={{
        height: '100%',
        backgroundColor: getTransparentBackground(themeMode),
        overflow: 'auto',
        ...customScrollbar
      }}>
      <ContentPanel
        title={`My Fleet`}
        onClose={onClose}
        footerActions={
          <Button
            startIcon={<PlusIcon />}
            onClick={() => setShowCreateModal(true)}
            fullWidth
            variant="contained">
            Create New Fleet
          </Button>
        }>
        <Box>
          {isLoadingFleets ? (
            <Box display="flex" justifyContent="center" p={3}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            segmentFilteredFleets.map((fleet) => (
              <Box key={fleet.id}>
                <Box
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 1
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flex: 1,
                      mr: 1
                    }}>
                    <Checkbox
                      checked={Array.from(memoizedSelectedFleets).some((f) => f.id === fleet.id)}
                      onChange={() => handleFleetSelect(fleet.id)}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <Box
                      onClick={() => handleToggleGroup(fleet.id)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}>
                      <Typography>{fleet.name}</Typography>
                      <ToolTip title="Edit fleet name and color">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingFleetDetails(fleet);
                          }}
                          sx={{ ml: 1 }}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </ToolTip>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <Typography
                      variant="caption"
                      cursor="default"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleGroup(fleet.id);
                      }}>
                      ({fleet.vessels.length} vessels)
                    </Typography>
                    <ToolTip title="Add/Remove vessels">
                      <IconButton size="small" onClick={() => setEditingFleet(fleet)}>
                        <PlusIcon fontSize="small" />
                      </IconButton>
                    </ToolTip>
                    <ToolTip title={'Expand/Collapse Fleet'}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleToggleGroup(fleet.id);
                        }}>
                        {expandedGroups[fleet.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </ToolTip>
                  </Box>
                </Box>
                <Collapse in={expandedGroups[fleet.id]}>{renderVesselList(fleet)}</Collapse>
              </Box>
            ))
          )}
        </Box>
      </ContentPanel>

      <CreateFleetModal
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onSubmit={handleCreateFleet}
        isSubmitting={isCreatingFleet}
      />

      {editingFleet && (
        <AddVesselsToFleetModal
          open={true}
          onClose={() => setEditingFleet(null)}
          onSubmit={handleUpdateFleetVessels}
          isSubmitting={isUpdatingFleetVessels}
          fleetName={editingFleet.name}
          vessels={vessels}
          existingFleet={editingFleet}
        />
      )}

      {editingFleetDetails && (
        <CreateFleetModal
          open={true}
          onClose={() => setEditingFleetDetails(null)}
          onSubmit={handleEditFleet}
          onDelete={handleDeleteFleet}
          isSubmitting={isEditingFleet}
          editFleet={editingFleetDetails}
        />
      )}

      {editingNote && (
        <NoteModal
          open={true}
          onClose={() => setEditingNote(null)}
          onSubmit={handleUpdateNote}
          isSubmitting={isUpdatingNote}
          initialNote={editingNote.note}
          vesselName={editingNote.vesselName}
        />
      )}
    </Paper>
  );
};

DashboardActivityMapMyFleet.propTypes = {
  onClose: PropTypes.func.isRequired,
  vessels: PropTypes.array,
  selectedFleets: PropTypes.instanceOf(Set),
  setSelectedFleets: PropTypes.func
};

export default DashboardActivityMapMyFleet;
