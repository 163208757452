import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, TextField, Typography, CircularProgress } from '@esgian/esgianui';

const NoteModal = ({ open, onClose, onSubmit, isSubmitting, initialNote = '', vesselName }) => {
  const [note, setNote] = useState(initialNote || '');
  const MAX_NOTE_LENGTH = 500;

  useEffect(() => {
    setNote(initialNote);
  }, [initialNote]);

  const handleSubmit = () => {
    onSubmit(note);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={
        {
          // ... existing styles
        }
      }>
      <Box>
        <Box sx={{ p: 3, pb: 2 }}>
          <Typography variant="h6">Add Note to {vesselName}</Typography>
        </Box>

        <Box sx={{ px: 3, pb: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Note"
            value={note}
            onChange={(e) => setNote(e.target.value.slice(0, MAX_NOTE_LENGTH))}
            placeholder="Add your note here..."
            helperText={`${note.length}/${MAX_NOTE_LENGTH} characters`}
            error={note.length >= MAX_NOTE_LENGTH}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 3,
            pt: 2,
            borderTop: 1,
            borderColor: 'divider',
            gap: 1
          }}>
          <Button onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={isSubmitting || note === initialNote}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

NoteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  initialNote: PropTypes.string,
  vesselName: PropTypes.string.isRequired
};

export default NoteModal;
